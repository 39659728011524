<template>
  <TransitionRoot
    appear
    :show="isOpen"
    as="template"
    @after-leave="$emit('after-leave')"
  >
    <Dialog as="div" @close="$emit('close')">
      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="min-h-screen px-4 text-center">
          <headless-overlay />

          <span class="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="inline-block w-full p-6 my-8 text-left align-middle transition-all transform bg-primary shadow-xl rounded-xl"
              :class="[widthClass]"
            >
              <DialogTitle v-if="heading">{{ heading }}</DialogTitle>
              <DialogDescription v-if="description">{{
                description
              }}</DialogDescription>
              <slot />
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
  import {
    Dialog,
    DialogDescription,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
  } from '@headlessui/vue';
  import HeadlessOverlay from '@console/components/app/modals/HeadlessOverlay.vue';
  import {computed} from 'vue';

  defineEmits(['close', 'after-leave']);
  const props = withDefaults(
    defineProps<{
      width?: 'md' | 'lg' | 'xl' | '2xl' | string;
      isOpen?: boolean;
      heading?: string;
      description?: string;
    }>(),
    {
      width: 'lg',
      isOpen: false,
      heading: '',
      description: '',
    }
  );

  const widthClass = computed(() => {
    if (props.width.startsWith('max-w-')) {
      return props.width;
    }

    return `max-w-${props.width}`;
  });
</script>
