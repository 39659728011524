import accountApi from '@console/api/account';

/**
 * State
 */
const state = {
  billingAddress: null,
  user: null,
};

/**
 * Getters
 */
const getters = {
  userIsInGroup(state) {
    return (handle) => {
      return !!state.user.groups.find((g) => g.handle === handle);
    };
  },

  cartSnippets(state) {
    if (!state.user) {
      return [];
    }

    return state.user.carts;
  },

  cartSnippet(state) {
    return (orgId) => {
      if (!state.user) {
        return null;
      }

      return state.user.carts.find((c) => c.orgId === orgId);
    };
  },

  stripeId(state) {
    return state.user.stripeId;
  },
};

/**
 * Actions
 */
const actions = {
  deleteUserPhoto({commit}) {
    return new Promise((resolve, reject) => {
      accountApi
        .deleteUserPhoto()
        .then((response) => {
          commit('deleteUserPhoto', {response});
          resolve(response);
        })
        .catch((response) => {
          reject(response);
        });
    });
  },

  async saveUser({commit}, user) {
    const response = await accountApi.saveUser(user);

    if (!response.data.errors) {
      commit('saveUser', {user, response});
    }
    return response;
  },

  uploadUserPhoto({commit}, data) {
    return new Promise((resolve, reject) => {
      accountApi
        .uploadUserPhoto(data)
        .then((response) => {
          commit('uploadUserPhoto', {response});
          resolve(response);
        })
        .catch((response) => {
          reject(response);
        });
    });
  },

  getAccount({commit}) {
    return new Promise((resolve, reject) => {
      accountApi
        .getAccount()
        .then((response) => {
          commit('updateBillingAddress', {
            billingAddress: response.data.billingAddress,
          });
          commit('updateCurrentUser', {user: response.data.user});
          commit(
            'paymentMethods/updateCard',
            {card: response.data.card},
            {root: true}
          );
          commit(
            'paymentMethods/updateCardToken',
            {cardToken: response.data.cardToken},
            {root: true}
          );

          resolve({
            response,
          });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  deactivateUser() {
    return new Promise((resolve, reject) => {
      accountApi
        .deactivateUser()
        .then((response) => {
          resolve(response);
        })
        .catch((response) => {
          reject(response);
        });
    });
  },

  dismissCraftIdMessage({commit, dispatch}) {
    return new Promise((resolve, reject) => {
      accountApi
        .dismissCraftIdMessage()
        .then((dismissCraftIdMessageResponse) => {
          dispatch('getAccount')
            .then((getAccountResponse) => {
              commit('dismissCraftIdMessage', {
                response: dismissCraftIdMessageResponse,
              });
              resolve({
                dismissCraftIdMessageResponse,
                getAccountResponse,
              });
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  generateApiToken({dispatch}) {
    return new Promise((resolve, reject) => {
      accountApi
        .generateApiToken()
        .then((generateApiTokenResponse) => {
          if (
            generateApiTokenResponse.data &&
            !generateApiTokenResponse.data.error
          ) {
            dispatch('getAccount')
              .then((getAccountResponse) => {
                resolve({
                  generateApiTokenResponse,
                  getAccountResponse,
                });
              })
              .catch((error) => {
                reject(error);
              });
          } else {
            reject(generateApiTokenResponse);
          }
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
};

/**
 * Mutations
 */
const mutations = {
  deleteUserPhoto(state, {response}) {
    state.user.photoId = response.data.photoId;
    state.user.photoUrl = response.data.photoUrl;
  },

  updateBillingAddress(state, {billingAddress}) {
    state.billingAddress = billingAddress;
  },

  updateCurrentUser(state, {user}) {
    state.user = user;
  },

  uploadUserPhoto(state, {response}) {
    state.user.photoId = response.data.photoId;
    state.user.photoUrl = response.data.photoUrl;
  },

  saveUser(state, {user}) {
    for (const attribute in user) {
      if (attribute === 'id' || attribute === 'email') {
        continue;
      }

      state.user[attribute] = user[attribute];

      if (user.enablePluginDeveloperFeatures) {
        const groupExists = state.user.groups.find(
          (g) => g.handle === 'developers'
        );

        if (!groupExists) {
          state.user.groups.push({
            id: 1,
            name: 'Developers',
            handle: 'developers',
          });
        }
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
