import axios from './axios';
import {getCraftApiEndpointUrl} from '@console/helpers/url.js';

export default {
  /**
   * Returns the axios instance for calls to the Craft API.
   */
  axios() {
    axios().defaults.baseURL = getCraftApiEndpointUrl() + '/';
    return axios();
  },

  /**
   * Get cart.
   */
  getCart(orderNumber) {
    return this.axios().get('carts/' + orderNumber);
  },

  clearCart({orgId}) {
    return this.axios().post(
      'carts/clear',
      {orgId},
      {
        withCredentials: true,
      }
    );
  },

  /**
   * Create cart.
   */
  createCart(data) {
    return this.axios().post('carts', data, {
      withCredentials: true,
    });
  },

  /**
   * Update cart.
   */
  updateCart(orderNumber, data) {
    return this.axios().post('carts/' + orderNumber, data, {
      withCredentials: true,
    });
  },

  /**
   * Delete cart.
   */
  deleteCart(orderNumber) {
    return this.axios().delete('carts/' + orderNumber);
  },

  /**
   * Checkout.
   */
  checkout(data) {
    return this.axios().post('payments', data, {
      withCredentials: true,
    });
  },

  /**
   * Save order number
   */
  setOrderNumber({orgId, orderNumber}) {
    return this.updateCart(orderNumber, {orgId});
  },

  /**
   * Get order number.
   */
  getOrderNumber(userOrgKey) {
    let orderNumbers = localStorage.getItem('orderNumbers');

    if (orderNumbers) {
      orderNumbers = JSON.parse(orderNumbers);
    } else {
      orderNumbers = {};
    }

    return orderNumbers[userOrgKey];
  },

  getLocalStorageOrderNumber() {
    return localStorage.getItem('orderNumber');
  },

  setLocalStorageOrderNumber(orderNumber) {
    localStorage.setItem('orderNumber', orderNumber);
  },

  deleteLocalStorageOrderNumber() {
    localStorage.removeItem('orderNumber');
  },
};
