<template>
  <input
    :id="id"
    type="radio"
    class="form-radio inline-block h-4 w-4 shadow-sm border-input bg-white dark:bg-gray-700 text-blue-600"
    :class="{'opacity-50': disabled}"
    :name="name"
    :value="value"
    :disabled="disabled"
    :checked="modelValue === value"
    @input="$emit('update:modelValue', $event.target.value)"
  />
</template>

<script>
  export default {
    props: {
      disabled: {
        type: Boolean,
        default: false,
      },
      id: {
        type: String,
        default: '',
      },
      modelValue: {
        type: String,
        default: '',
      },
      value: {
        type: String,
        default: '',
      },
      name: {
        type: String,
        default: '',
      },
    },

    emits: ['update:modelValue'],
  };
</script>
