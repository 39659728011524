import {computed, ref} from 'vue';

const fieldRegistry = ref<{
  [key: string]: {
    id: string;
    inputId: string;
    fieldId: string;
    labelId: string;
    errorId: string;
    instructionsId: string;
  };
}>({});

const generateHash = () => {
  return Math.random().toString(36).substring(2, 9);
};

export const useField = (desiredId?: string) => {
  if (desiredId && fieldRegistry.value[desiredId]) {
    return fieldRegistry.value[desiredId];
  }

  const id = computed(() => (desiredId ? desiredId : generateHash()));

  fieldRegistry.value[id.value] = {
    id: id.value,
    errorId: `${id.value}-error`,
    inputId: `${id.value}-input`,
    fieldId: `${id.value}-field`,
    labelId: `${id.value}-label`,
    instructionsId: `${id.value}-instructions`,
  };

  return fieldRegistry.value[id.value];
};
