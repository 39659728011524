<template>
  <div
    :class="[
      {
        'w-5 h-5': size === 'xs',
        'w-7 h-7': size === 'sm',
        'w-10 h-10': size === 'md',
        'w-12 h-12': size === 'lg',
        'w-20 h-20': size === 'xl',
        'w-28 h-28': size === '2xl',
        rounded: size === 'sm' && shape === 'rounded',
        'rounded-md': size === 'md' && shape === 'rounded',
        'rounded-lg': (size === 'lg' || size === 'xl') && shape === 'rounded',
        'rounded-full': shape === 'circle',
        'overflow-hidden flex items-center justify-center': true,
        'bg-gray-500/10 dark:bg-white/20': !photoUrl,
        'ring ring-2': color,
      },
    ]"
    :style="
      color
        ? 'box-shadow: var(--tw-ring-offset-shadow), ' +
          'var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) ' +
          color +
          ', ' +
          'var(--tw-shadow, 0 0 #0000);'
        : ''
    "
  >
    <template v-if="photoUrl">
      <img :src="photoUrl" alt="" />
    </template>
    <template v-else>
      <icon
        :icon="fallback === 'org' ? 'building' : 'user'"
        set="solid"
        :class="{
          'w-2.5 h-2.5': size === 'xs',
          'w-3 h-3': size === 'sm',
          'w-4 h-4': size === 'md',
          'w-5 h-5': size === 'lg',
          'w-10 h-10': size === 'xl',
        }"
        class="text-gray-500 dark:text-white/50"
      />
    </template>
  </div>
</template>

<script>
  export default {
    props: {
      size: {
        type: String,
        default: 'sm',
      },
      photoUrl: {
        type: String,
        default: null,
      },
      fallback: {
        type: String,
        default: 'user',
      },
      shape: {
        type: String,
        default: 'rounded',
      },
      color: {
        type: String,
        default: null,
      },
    },
  };
</script>
