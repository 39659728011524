<template>
  <div
    class="c-spinner text-current text-opacity-50"
    :class="{
      'inline-block': true,
      [size]: true,
    }"
  >
    <div class="animation" />
  </div>
</template>

<script>
  export default {
    props: {
      /**
       * 'base' or 'lg'
       */
      size: {
        type: String,
        default: 'base',
      },
    },
  };
</script>

<style lang="pcss">
  .c-spinner {
    & > .animation {
      animation: rotator .7s linear infinite;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border-width: 2px;
      border-style: solid;
      border-color: currentColor;
      border-top-color: transparent !important;
      border-left-color: transparent !important;
    }

    &.xs {
      & > .animation {
        width: 12px;
        height: 12px;
      }
    }

    &.sm {
      & > .animation {
        width: 16px;
        height: 16px;
      }
    }

    &.lg {
      & > .animation {
        width: 32px;
        height: 32px;
        border-width: 3px;
      }
    }
  }

  @keyframes rotator {
    0% {
      transform: rotate(0)
    }

    to {
      transform: rotate(1turn)
    }
  }
</style>
