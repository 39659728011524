<template>
  <div
    class="grid field-row"
    :class="{
      'grid-flow-row': true,
      'gap-1': layout === 'vertical',
      'sm:grid-flow-col gap-6': layout === 'horizontal',
      'sm:grid-cols-3': layout === 'horizontal',
      'sm:items-baseline': layout === 'horizontal' && alignItems === 'baseline',
      'sm:items-start': layout === 'horizontal' && alignItems === 'start',
      'sm:items-end': layout === 'horizontal' && alignItems === 'end',
      'sm:items-center': layout === 'horizontal' && alignItems === 'center',
    }"
  >
    <div :class="{'max-w-sm': layout === 'horizontal'}">
      <slot name="label"></slot>
    </div>

    <div
      :class="{
        'sm:col-span-2': layout === 'horizontal',
      }"
    >
      <slot name="body">
        <div
          :class="{
            [bodyClass]: layout === 'horizontal',
          }"
        >
          <slot />
        </div>
      </slot>
    </div>
  </div>
</template>

<script setup lang="ts">
  import {computed} from 'vue';

  const props = withDefaults(
    defineProps<{
      alignItems?: 'center' | 'start' | 'end' | 'baseline';
      layout?: 'horizontal' | 'vertical';
      bodyWidth?: 'sm' | 'md' | 'lg' | 'xl' | '2xl' | string;
    }>(),
    {
      alignItems: 'baseline',
      layout: 'horizontal',
      bodyWidth: 'lg',
    }
  );

  const bodyClass = computed(() => {
    return `max-w-${props.bodyWidth}`;
  });
</script>

<style scoped></style>
