import appsApi from '@console/api/apps';

/**
 * State
 */
const state = {
  apps: {},
  appsLoading: false,
};

/**
 * Getters
 */
const getters = {
  getApp(state) {
    return (handle) => {
      if (!state.apps[handle]) {
        return null;
      }

      return state.apps[handle];
    };
  },
  isAppConnected(state, getters) {
    return (handle) => {
      return !!getters.getApp(handle);
    };
  },
};

/**
 * Actions
 */
const actions = {
  getApps({commit, state, dispatch}, orgId) {
    return new Promise((resolve, reject) => {
      if (state.appsLoading) {
        reject();
        return;
      }

      commit('updateAppsLoading', true);

      appsApi
        .getApps(orgId)
        .then((response) => {
          commit('updateApps', {apps: response.data});

          dispatch('stripe/getStripeAccount', orgId, {root: true})
            .then(() => {
              commit('updateAppsLoading', false);
              resolve();
            })
            .catch((error) => {
              commit('updateAppsLoading', false);
              // TODO: Stripe error shouldn’t be silent
              // reject(error.response)
              resolve();
              throw error;
            });
        })
        .catch((error) => {
          commit('updateAppsLoading', false);
          reject(error.response);
        });
    });
  },

  connectAppCallback({commit}, apps) {
    commit('updateApps', {apps});
  },

  disconnectApp({commit}, {orgId, appHandle}) {
    return new Promise((resolve, reject) => {
      appsApi
        .disconnect({orgId, appHandle})
        .then((response) => {
          commit('disconnectApp', {appHandle});
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
};

/**
 * Mutations
 */
const mutations = {
  updateApps(state, {apps}) {
    state.apps = apps;
  },

  updateAppsLoading(state, loading) {
    state.appsLoading = loading;
  },

  disconnectApp(state, {appHandle}) {
    delete state.apps[appHandle];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
