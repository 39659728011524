import paymentMethodsApi from '@console/api/payment-methods';

/**
 * State
 */
const state = {
  // Saved credit card for developer support subscriptions
  card: null,

  // Saved credit card for payment
  cardToken: null,

  paymentMethods: [],
  paymentMethodsCheckout: [],

  paymentMethod: null,
};

/**
 * Getters
 */
const getters = {
  primaryPaymentMethod(state) {
    return state.paymentMethods.find((method) => method.isPrimary);
  },

  paymentMethods(state) {
    return [...state.paymentMethods].sort((a, b) => b.isPrimary - a.isPrimary);
  },
};

/**
 * Actions
 */
const actions = {
  savePaymentMethod(context, data) {
    return new Promise((resolve, reject) => {
      paymentMethodsApi
        .savePaymentMethod(data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },

  removePaymentMethod({dispatch}, {paymentMethodId} = {orgId: null}) {
    return new Promise((resolve, reject) => {
      paymentMethodsApi
        .removePaymentMethod(paymentMethodId)
        .then((removePaymentMethodResponse) => {
          dispatch('getPaymentMethods').then((getPaymentMethodsResponse) => {
            resolve({
              removePaymentMethodResponse,
              getPaymentMethodsResponse,
            });
          });
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },

  getPaymentMethodById({commit}, paymentMethodId) {
    return new Promise((resolve, reject) => {
      paymentMethodsApi
        .getPaymentMethodById(paymentMethodId)
        .then((response) => {
          commit('updatePaymentMethod', {
            paymentMethod: response.data.paymentMethod,
          });
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getPaymentMethods({commit, rootGetters}) {
    const context = rootGetters['context/active'];

    let orgId = null;
    if (context?.type === 'org') {
      orgId = context.id;
    }

    return paymentMethodsApi
      .getPaymentMethods({orgId})
      .then((response) => {
        commit('updatePaymentMethods', {
          paymentMethods: response.data.paymentMethods,
        });
      })
      .catch((error) => {
        console.error(error);
        commit('updatePaymentMethods', {
          paymentMethods: [],
        });
      });
  },

  getPaymentMethodsCheckout({commit}, {org}) {
    return new Promise((resolve, reject) => {
      paymentMethodsApi
        .getPaymentMethods({orgId: org?.id})
        .then((response) => {
          commit('updatePaymentMethodsCheckout', {
            paymentMethodsCheckout: response.data.paymentMethods,
          });
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

/**
 * Mutations
 */
const mutations = {
  updatePaymentMethods(state, {paymentMethods}) {
    state.paymentMethods = paymentMethods;
  },

  updatePaymentMethodsCheckout(state, {paymentMethodsCheckout}) {
    state.paymentMethodsCheckout = paymentMethodsCheckout;
  },

  updateCard(state, {card}) {
    state.card = card;
  },

  updateCardToken(state, {cardToken}) {
    state.cardToken = cardToken;
  },

  updatePaymentMethod(state, {paymentMethod}) {
    state.paymentMethod = paymentMethod;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
