import type {MutationTree} from 'vuex';

interface CloudState {
  contextId?: string;
}

const initialContextId = localStorage.getItem('contextElementId')
  ? JSON.parse(localStorage.getItem('contextElementId') as string)
  : null;

/**
 * State
 */
const state = {
  contextId: initialContextId,
};

/**
 * Getters
 */
const getters = {};

/**
 * Actions
 */
const actions = {};

/**
 * Mutations
 */
const mutations = <MutationTree<CloudState>>{
  updateContext(state, elementId: string) {
    state.contextId = elementId;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
