import {currency} from '@console/helpers/number';

export function getCartItemsData(cart) {
  if (!cart) {
    return [];
  }

  const lineItems = [];

  for (let i = 0; i < cart.lineItems.length; i++) {
    const lineItem = cart.lineItems[i];

    const licenseKey = lineItem.options.licenseKey;

    switch (lineItem.purchasable.type) {
      case 'plugin-edition': {
        lineItems.push({
          id: lineItem.id,
          type: lineItem.purchasable.type,
          plugin: lineItem.purchasable.plugin.handle,
          edition: lineItem.purchasable.handle,
          autoRenew: lineItem.options.autoRenew,
          expiryDate: lineItem.options.expiryDate,
          ...(licenseKey && licenseKey.substr(0, 3) !== 'new' && {licenseKey}),
        });
        break;
      }

      case 'cms-edition': {
        const item = {
          id: lineItem.id,
          type: lineItem.purchasable.type,
          edition: lineItem.purchasable.handle,
          autoRenew: lineItem.options.autoRenew,
          expiryDate: lineItem.options.expiryDate,
          ...(licenseKey && licenseKey.substr(0, 3) !== 'new' && {licenseKey}),
        };

        lineItems.push(item);

        break;
      }

      case 'cms-renewal': {
        lineItems.push({
          id: lineItem.id,
          type: lineItem.purchasable.type,
          licenseKey,
          expiryDate: lineItem.options.expiryDate,
        });
        break;
      }

      case 'plugin-renewal': {
        lineItems.push({
          id: lineItem.id,
          type: lineItem.purchasable.type,
          licenseKey,
          expiryDate: lineItem.options.expiryDate,
        });
        break;
      }
    }
  }

  return lineItems;
}

export function itemUpdateOptions({cartItems, itemKey, expiryDateOptions}) {
  const item = cartItems[itemKey];
  const renewalPrice = parseFloat(item.lineItem.purchasable.renewalPrice);

  let options = [];
  let selectedOption = 0;

  expiryDateOptions.forEach((option, key) => {
    if (option[0] === item.lineItem.options.expiryDate) {
      selectedOption = key;
    }
  });

  for (let i = 0; i < expiryDateOptions.length; i++) {
    const expiryDateOption = expiryDateOptions[i];
    const value = expiryDateOption[0];
    const price = renewalPrice * (i - selectedOption);
    const nbYears = i + 1;

    let label = `${nbYears} ${nbYears === 1 ? 'year' : 'years'}`;

    if (price !== 0) {
      let sign = '';

      if (price > 0) {
        sign = '+';
      }

      label += ' (' + sign + currency(price) + ')';
    }

    options.push({
      label: label,
      value: value,
    });
  }

  return options;
}
