import {createRouter, createWebHistory} from 'vue-router';
import appRoutes from '@console/router/routes/app';
import siteRoutes from '@console/router/routes/site';
import cloudRoutes from '@console/router/routes/cloud';
import licensesRoutes from '@console/router/routes/licenses';
import pluginStoreRoutes from '@console/router/routes/plugin-store';
import settingsRoutes from '@console/router/routes/settings';
import store from '@console/store';

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: 'active',
  linkExactActiveClass: 'active-exact',

  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
      };
    }

    return savedPosition || {x: 0, y: 0};
  },
  routes: [
    ...appRoutes,
    ...siteRoutes,
    ...cloudRoutes,
    ...licensesRoutes,
    ...pluginStoreRoutes,
    ...settingsRoutes,

    // Not found
    {
      path: '/:pathMatch(.*)*',
      name: 'NotFound',
      component: () => import('@console/pages/not-found.vue'),
    },
  ],
});

router.afterEach(() => {
  store.commit('app/updateShowingSidebar', false);
});

export default router;
