<template>
  <div>
    <auth-manager-root-modal :is-open="showingRootModal">
      <!-- login modal -->
      <auth-manager-login-modal
        ref="loginModal"
        :is-open="showingLoginModal"
        @after-leave="onAfterLeaveLoginModal"
        @check-auth-status="onCheckAuthStatus"
        @submit-login-success="onSubmitLoginSuccess"
      />

      <!-- Logout warning modal -->
      <auth-manager-logout-warning-modal
        :is-open="showingLogoutWarningModal"
        :remaining-session-time="remainingSessionTime"
        @after-leave="onAfterLeaveLogoutWarningModal"
        @renew-session="renewSession"
      />
    </auth-manager-root-modal>
  </div>
</template>

<script>
  import {mapState} from 'vuex';
  import usersApi from '@console/api/users.js';
  import AuthManagerLoginModal from '@console/components/app/auth-manager/AuthManagerLoginModal.vue';
  import AuthManagerLogoutWarningModal from '@console/components/app/auth-manager/AuthManagerLogoutWarningModal.vue';
  import AuthManagerRootModal from '@console/components/app/auth-manager/AuthManagerRootModal.vue';

  export default {
    components: {
      AuthManagerRootModal,
      AuthManagerLogoutWarningModal,
      AuthManagerLoginModal,
    },

    data() {
      return {
        showingLoginModal: false,
        showingLogoutWarningModal: false,
        minSafeSessionTime: 120,
        checkInterval: 60,
        remainingSessionTime: null,
        showingRootModal: false,
        pleaseOpenLoginModal: false,
        pleaseCheckRemainingSessionTime: false,
        submitLoginIfLoggedOut: false,
      };
    },

    computed: {
      ...mapState({
        user: (state) => state.account.user,
      }),
    },

    mounted() {
      // Use `Craft.remainingSessionTime` as the initial value
      this.updateRemainingSessionTime(Craft.remainingSessionTime);
    },

    methods: {
      // -------------------------------
      // SESSION
      // -------------------------------

      /**
       * Sets a timer for the next time to check the auth timeout.
       */
      setCheckRemainingSessionTimer(seconds) {
        if (this.checkRemainingSessionTimer) {
          clearTimeout(this.checkRemainingSessionTimer);
        }

        this.checkRemainingSessionTimer = setTimeout(
          function () {
            this.checkRemainingSessionTime();
          }.bind(this),
          seconds * 1000
        );
      },

      /**
       * Pings the server to see how many seconds are left on the current user session, and handles the response.
       */
      checkRemainingSessionTime(extendSession) {
        let config = {};

        if (!extendSession) {
          config.params = {
            dontExtendSession: 1,
          };
        }

        usersApi
          .getRemainingSessionTime(config)
          .then((response) => {
            if (
              typeof response.data.csrfTokenValue !== 'undefined' &&
              typeof Craft.csrfTokenValue !== 'undefined'
            ) {
              Craft.csrfTokenValue = response.data.csrfTokenValue;
            }

            this.updateRemainingSessionTime(response.data.timeout);
            this.submitLoginIfLoggedOut = false;
          })
          .catch(() => {
            this.updateRemainingSessionTime(-1);
          });
      },

      /**
       * Updates our record of the auth timeout, and handles it.
       */
      updateRemainingSessionTime(remainingSessionTime) {
        if (!this.user) {
          return false;
        }

        this.remainingSessionTime = parseInt(remainingSessionTime);

        // Are we within the warning window?
        if (
          this.remainingSessionTime !== -1 &&
          this.remainingSessionTime < this.minSafeSessionTime
        ) {
          // Is there still time to renew the session?
          if (this.remainingSessionTime) {
            if (!this.showingLogoutWarningModal) {
              // Show the warning modal
              this.showLogoutWarningModal();
            }

            // Will the session expire before the next checkup?
            if (this.remainingSessionTime < this.checkInterval) {
              if (this.showLoginModalTimer) {
                clearTimeout(this.showLoginModalTimer);
              }

              this.showLoginModalTimer = setTimeout(
                function () {
                  this.showLoginModal();
                }.bind(this),
                this.remainingSessionTime * 1000
              );
            }
          } else {
            if (this.showingLoginModal) {
              if (this.submitLoginIfLoggedOut) {
                this.$refs.loginModal.submitLogin();
              }
            } else {
              this.showLoginModal();
            }
          }

          this.setCheckRemainingSessionTimer(this.checkInterval);
        } else {
          // Everything's good!
          this.hideLogoutWarningModal();
          this.hideLoginModal();

          // Will we be within the minSafeSessionTime before the next update?
          if (
            this.remainingSessionTime !== -1 &&
            this.remainingSessionTime <
              this.minSafeSessionTime + this.checkInterval
          ) {
            this.setCheckRemainingSessionTimer(
              this.remainingSessionTime - this.minSafeSessionTime + 1
            );
          } else {
            this.setCheckRemainingSessionTimer(this.checkInterval);
          }
        }
      },

      renewSession() {
        this.hideLogoutWarningModal();
        this.checkRemainingSessionTime(true);
      },

      // -------------------------------
      // LOGIN
      // -------------------------------

      /**
       * Shows the login modal.
       */
      showLoginModal() {
        if (this.showingLogoutWarningModal) {
          this.hideLogoutWarningModal(true);
          this.$nextTick(() => {
            this.showingRootModal = true;
            this.pleaseOpenLoginModal = true;
          });
        } else {
          this.showingRootModal = true;
          this.showingLoginModal = true;
        }
      },

      /**
       * Hides the login modal.
       */
      hideLoginModal(quick) {
        this.$nextTick(() => {
          this.showingLoginModal = false;

          if (!quick) {
            this.showingRootModal = false;
          }
        });
      },

      /**
       * On after leave login modal.
       */
      onAfterLeaveLoginModal() {
        if (this.pleaseCheckRemainingSessionTime) {
          this.$nextTick(() => {
            this.checkRemainingSessionTime();
            this.pleaseCheckRemainingSessionTime = false;
          });
        }
      },

      onSubmitLoginSuccess() {
        this.hideLoginModal();
        this.pleaseCheckRemainingSessionTime = true;
      },

      onCheckAuthStatus() {
        this.submitLoginIfLoggedOut = true;
        this.checkRemainingSessionTime();
      },

      // -------------------------------
      // LOGOUT
      // -------------------------------

      /**
       * Shows the logout warning modal.
       */
      showLogoutWarningModal() {
        if (this.showingLoginModal) {
          this.hideLoginModal(true);
        }

        this.$nextTick(() => {
          this.showingRootModal = true;
          this.showingLogoutWarningModal = true;
        });

        this.decrementLogoutWarningInterval = setInterval(
          function () {
            this.decrementLogoutWarning();
          }.bind(this),
          1000
        );
      },

      /**
       * Hides the logout warning modal.
       */
      hideLogoutWarningModal(quick) {
        this.$nextTick(() => {
          this.showingLogoutWarningModal = false;

          if (!quick) {
            this.showingRootModal = false;
          }
        });

        if (this.decrementLogoutWarningInterval) {
          clearInterval(this.decrementLogoutWarningInterval);
        }
      },

      /**
       * On after leave logout warning modal.
       */
      onAfterLeaveLogoutWarningModal() {
        if (this.pleaseOpenLoginModal) {
          this.$nextTick(() => {
            this.showingLoginModal = true;
            this.pleaseOpenLoginModal = false;
          });
        }
      },

      /**
       * Decrement logout warning.
       */
      decrementLogoutWarning() {
        if (this.remainingSessionTime > 0) {
          this.remainingSessionTime--;
        }

        if (this.remainingSessionTime === 0) {
          clearInterval(this.decrementLogoutWarningInterval);
        }
      },
    },
  };
</script>

<style lang="pcss">
  .auth-manager-modal {
    z-index: 20;

    .modal-body {
      position: relative;
      padding-left: 72px;

      & > .icon {
        position: absolute;
        top: 0;
        left: 0;
        width: 46px;
        height: 46px;
        color: #b9bfc6;
      }
    }
  }
</style>
