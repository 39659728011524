<template>
<svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
    <path class="cls-3" fill="#e24329"
          d="m33.69 17.05-.04-.11-3.86-10.45c-.08-.2-.22-.38-.4-.5a.992.992 0 0 0-1.18.07c-.16.14-.28.33-.34.54l-2.6 8.27H14.73l-2.6-8.27c-.06-.21-.18-.4-.34-.54-.34-.28-.81-.3-1.18-.07-.18.12-.32.29-.4.5L6.35 16.93l-.04.11c-1.14 3.09-.17 6.6 2.38 8.59h.01l.04.04 5.87 4.57 2.91 2.28 1.77 1.39c.43.34 1.01.34 1.44 0l1.77-1.39 2.91-2.28 5.91-4.59h.01c2.54-2.01 3.51-5.5 2.37-8.6Z"/>
    <path class="cls-2" fill="#fc6d26"
          d="m33.69 17.05-.04-.11c-1.88.4-3.65 1.23-5.19 2.42l-8.47 6.65c2.88 2.27 5.39 4.23 5.39 4.23l5.91-4.59h.01c2.54-2.01 3.51-5.51 2.38-8.6Z"/>
    <path class="cls-1" fill="#fca326"
          d="m14.61 30.24 2.91 2.28 1.77 1.39c.43.34 1.01.34 1.44 0l1.77-1.39 2.91-2.28s-2.51-1.97-5.4-4.23l-5.39 4.23Z"/>
    <path class="cls-2" fill="#fc6d26"
          d="M11.53 19.36c-1.53-1.2-3.3-2.02-5.18-2.43l-.04.11c-1.14 3.09-.17 6.6 2.38 8.59h.01l.04.04 5.87 4.57L20 26.01l-8.47-6.64Z"/>
</svg>
</template>
      