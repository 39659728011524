<template>
  <div v-if="user" class="header-left flex -mr-4">
    <div class="inline-block flex-1">
      <Popover class="header-brand">
        <PopoverButton
          class="flex w-full items-center hover:no-underline font-bold text-gray-800 dark:text-gray-200 px-5 py-3"
        >
          <div class="mr-2">
            <div class="relative">
              <profile-photo
                :color="activeContext.color"
                :photo-url="activeContext.icon ? activeContext.icon.url : null"
                :fallback="activeContext.type"
              />
              <template v-if="userInvitations.length">
                <div
                  class="absolute -top-1 -right-1 bg-red-500 w-2 h-2 rounded-full"
                />
              </template>
            </div>
          </div>

          <div v-if="activeContext.title" class="hidden md:inline-block">
            {{ activeContext.title }}
          </div>

          <icon
            icon="chevron-down"
            class="w-4 h-4 ml-1 mt-0.5 text-gray-700 dark:text-gray-400"
          />
        </PopoverButton>

        <PopoverPanel
          class="max-h-[calc(100vh-9rem)] md:max-h-[calc(100vh-5rem)] overflow-y-auto absolute z-30 right-4 w-56 origin-top-right bg-white dark:bg-gray-700 divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-10 dark:ring-white dark:ring-opacity-20 focus:outline-none"
        >
          <div class="px-2 py-1">
            <template v-for="user in users" :key="user.id">
              <AccountSwitcherMenuItem
                :checked="activeContext.id === user.id"
                to="/accounts/me"
              >
                <div class="flex items-center">
                  <div>
                    <profile-photo
                      :photo-url="user.icon ? user.icon.url : null"
                      class="mr-2"
                    />
                  </div>

                  <div class="truncate">
                    {{ user.title }}
                  </div>
                </div>
              </AccountSwitcherMenuItem>
            </template>

            <template v-if="userInvitations.length">
              <div class="truncate">
                <AccountSwitcherMenuItem to="/accounts/me/settings/orgs">
                  <div class="flex items-center gap-3 justify-between">
                    <div>
                      {{ userInvitations.length }} pending
                      {{
                        userInvitations.length === 1
                          ? 'invitation'
                          : 'invitations'
                      }}
                    </div>

                    <div>
                      <div class="bg-red-500 w-1.5 h-1.5 rounded-full" />
                    </div>
                  </div>
                </AccountSwitcherMenuItem>
              </div>
            </template>

            <AccountSwitcherMenuItem @click="logout">
              Sign out
            </AccountSwitcherMenuItem>

            <hr class="my-2 mx-3 border-separator dark:border-gray-600" />

            <!-- Organizations -->
            <template v-if="orgs.length > 0">
              <div
                class="group items-center justify-between gap-2 px-3 py-1 text-xs font-medium text-gray-400"
              >
                <div class="relative">
                  <div class="text-light dark:text-gray-300">Organizations</div>

                  <router-link
                    to="/accounts/me/settings/orgs/new"
                    class="leading-none items-center flex h-6 w-6 justify-center transition duration-150 opacity-0 group-hover:opacity-100 focus:opacity-100 text-light dark:text-gray-300 absolute top-1/2 -translate-y-1/2 right-0"
                  >
                    <icon
                      icon="plus"
                      class="w-4 h-4 block leading-none border border-gray-500 dark:border-gray-300 rounded-full"
                    />
                    <div class="sr-only">Add an organization</div>
                  </router-link>
                </div>
              </div>
            </template>

            <template v-for="org in orgs" :key="org.id">
              <AccountSwitcherMenuItem
                :checked="activeContext.id === org.id"
                :to="`/accounts/${org.slug}`"
              >
                <div class="flex items-center min-w-0">
                  <div>
                    <profile-photo
                      class="mr-2"
                      :photo-url="org.icon ? org.icon.url : null"
                      :color="org.color"
                      fallback="org"
                    />
                  </div>
                  <div class="truncate">
                    {{ org.title }}
                  </div>
                </div>
              </AccountSwitcherMenuItem>
            </template>

            <hr class="my-2 mx-3 border-t dark:border-gray-600" />
            <AccountSwitcherMenuItem to="/accounts/me/settings/orgs/new">
              <div class="flex items-center gap-2">
                <div class="w-7 h-7 inline-flex items-center justify-center">
                  <icon icon="plus" class="w-5 h-5 inline-block" />
                </div>
                <div>Add an organization</div>
              </div>
            </AccountSwitcherMenuItem>
          </div>
        </PopoverPanel>
      </Popover>
    </div>
  </div>
</template>

<script>
  import {Popover, PopoverButton, PopoverPanel} from '@headlessui/vue';
  import {mapGetters, mapState} from 'vuex';
  import AccountSwitcherMenuItem from './AccountSwitcherMenuItem.vue';
  import ProfilePhoto from '../ProfilePhoto.vue';
  import {useQueryClient} from '@tanstack/vue-query';
  import Icon from '@/common/ui/components/Icon.vue';
  import usersApi from '@console/api/users';

  export default {
    components: {
      Icon,
      ProfilePhoto,
      AccountSwitcherMenuItem,
      Popover,
      PopoverButton,
      PopoverPanel,
    },

    computed: {
      ...mapState({
        orgs: (state) => state.orgs.orgs,
        user: (state) => state.account.user,
        userInvitations: (state) => state.orgs.userInvitations,
      }),

      ...mapGetters({
        activeContext: 'context/active',
        users: 'context/users',
        orgs: 'context/orgs',
      }),
    },

    setup() {
      const queryClient = useQueryClient();
      return {queryClient};
    },

    methods: {
      logout() {
        usersApi.logout().then(() => {
          document.location.href = '/login?redirect=' + this.$route.fullPath;
        });
      },
    },
  };
</script>
