<template>
  <div class="flex items-center" v-if="domains.length > 0">
    <template v-if="domains.length > 1">
      <Menu as="div" class="inline-block text-left">
        <Float
          placement="bottom-start"
          :offset="4"
          strategy="fixed"
          :flip="true"
        >
          <HudMenuBtn class="!px-1">
            <div
              class="flex text-blue-600 items-center"
              :class="{
                'gap-2': showLabel,
                'gap-1': !showLabel,
              }"
            >
              <Icon icon="earth-americas" class="w-3 h-3" />
              <span :class="{'sr-only': !showLabel}">{{ label }}</span>
              <Icon icon="chevron-down" class="w-3 h-3" />
            </div>
          </HudMenuBtn>

          <hud-menu-items size="lg">
            <template v-for="(domain, domainKey) in domains" :key="domainKey">
              <MenuItem v-slot="{active}">
                <hud-menu-item
                  :href="domain.href"
                  target="_blank"
                  rel="noopener nofollow"
                  :active="active"
                  class="truncate"
                >
                  {{ domain.label }}
                </hud-menu-item>
              </MenuItem>
            </template>
          </hud-menu-items>
        </Float>
      </Menu>
    </template>
    <template v-else-if="domains.length === 1">
      <btn
        :href="domains[0].href"
        class="!px-1 mr-4"
        variant="text"
        target="_blank"
        rel="noopener nofollow"
      >
        <div
          class="flex text-blue-600 items-center"
          :class="{
            'gap-2': showLabel,
            'gap-1': !showLabel,
          }"
        >
          <Icon icon="earth-americas" class="w-3 h-3" />
          <span :class="{'sr-only': !showLabel}">{{ label }}</span>
        </div>
      </btn>
    </template>
  </div>
</template>

<script setup lang="ts">
  import HudMenuItems from '@console/components/HudMenuItems.vue';
  import HudMenuItem from '@console/components/HudMenuItem.vue';
  import {Float} from '@headlessui-float/vue';
  import HudMenuBtn from '@console/components/HudMenuBtn.vue';
  import {Menu, MenuItem} from '@headlessui/vue';
  import Icon from '@/common/ui/components/Icon.vue';
  import {computed} from 'vue';
  import Btn from '@/common/ui/components/Btn.vue';
  import {useGetProject} from '@console/queries/projects';

  const {project} = useGetProject();
  const props = withDefaults(
    defineProps<{
      label?: string;
      environmentId?: string | null;
      previewDomain?: string | null;
      showLabel?: boolean;
    }>(),
    {
      label: 'View site',
      environmentId: null,
      showLabel: true,
      previewDomain: null,
    }
  );

  interface DomainLIstItem {
    label: string;
    href: string;
  }

  const domains = computed<Array<DomainLIstItem>>(() => {
    const projectDomains = project.value?.domains || [];

    const environmentDomains: Array<DomainLIstItem> = [
      ...projectDomains
        .filter((domain) => domain.environment_id === props.environmentId)
        .map((domain) => ({
          label: domain.hostname,
          href: `https://${domain.hostname}`,
        })),
    ];

    projectDomains.reduce((acc, domain) => {
      (domain.subdomains || [])
        .filter((subdomain) => subdomain.environment_id === props.environmentId)
        .forEach((subdomain) => {
          acc.push({
            label: `${subdomain.subdomain}.${domain.hostname}`,
            href: `https://${subdomain.subdomain}.${domain.hostname}`,
          });
        });

      return acc;
    }, environmentDomains);

    if (props.previewDomain) {
      environmentDomains.push({
        label: props.previewDomain,
        href: `https://${props.previewDomain}`,
      });
    }

    return environmentDomains;
  });
</script>
