import stripeApi from '@console/api/stripe';

/**
 * State
 */
const state = {
  stripeAccount: null,
};

/**
 * Getters
 */
const getters = {};

/**
 * Actions
 */
const actions = {
  disconnectStripeAccount({commit}, orgId) {
    return new Promise((resolve, reject) => {
      stripeApi
        .disconnect(orgId)
        .then((response) => {
          commit('disconnectStripeAccount');
          resolve(response);
        })
        .catch((response) => {
          reject(response);
        });
    });
  },

  getStripeAccount({commit}, orgId) {
    return new Promise((resolve, reject) => {
      stripeApi
        .getAccount(orgId)
        .then((response) => {
          commit('updateStripeAccount', {response});
          resolve(response);
        })
        .catch((response) => {
          reject(response);
        });
    });
  },
};

/**
 * Mutations
 */
const mutations = {
  disconnectStripeAccount(state) {
    state.stripeAccount = null;
  },

  updateStripeAccount(state, {response}) {
    state.stripeAccount = response.data.account;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
