import ordersApi from '@console/api/orders';

/**
 * State
 */
const state = {
  orders: [],
  pendingOrders: [],
};

/**
 * Getters
 */
const getters = {};

/**
 * Actions
 */
const actions = {
  getOrders({commit}, {orgId, params}) {
    return ordersApi.getOrders(orgId, params).then((response) => {
      commit('updateOrders', response.data.data);
    });
  },

  getPendingOrders({commit}, {orgId}) {
    return ordersApi.getPendingOrders(orgId).then((response) => {
      commit('updatePendingOrders', response.data);
    });
  },

  requestOrderApproval(context, {orgId, orderNumber}) {
    return ordersApi.requestOrderApproval({orgId, orderNumber});
  },

  rejectRequest(context, {orgId, orderNumber}) {
    return ordersApi.rejectRequest({orgId, orderNumber});
  },
};

/**
 * Mutations
 */
const mutations = {
  updateOrders(state, orders) {
    state.orders = orders;
  },

  updatePendingOrders(state, pendingOrders) {
    state.pendingOrders = pendingOrders;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
