<template>
  <div class="c-lightswitch">
    <div class="c-lightswitch-input">
      <label :for="id" class="lightswitch" :class="{disabled: disabled}">
        <input
          :id="id"
          type="checkbox"
          :value="value"
          :checked="checked"
          :disabled="disabled"
          @input="onInput"
          role="switch"
          :aria-describedby="computedDescribedBy"
          v-bind="$attrs"
        />
        <div class="slider round" />
      </label>
    </div>
  </div>
</template>

<script>
  export default {
    model: {
      prop: 'checked',
      event: 'input',
    },

    inheritAttrs: false,

    props: {
      checked: {
        type: Boolean,
        default: null,
      },
      describedBy: {
        type: Array,
        default: () => [],
      },
      disabled: {
        type: Boolean,
        default: null,
      },
      id: {
        type: String,
        default: function () {
          return 'c-lightswitch-id-' + Math.random().toString(36).substr(2, 9);
        },
      },
      value: {
        type: String,
        default: null,
      },
    },

    emits: ['update:checked', 'input'],

    computed: {
      computedDescribedBy() {
        if (this.describedBy.length > 0) {
          return this.describedBy.join(' ');
        }

        return null;
      },
    },

    methods: {
      onInput($event) {
        this.$emit('update:checked', $event.target.checked);
        this.$emit('input', $event.target.checked);
      },
    },
  };
</script>

<style lang="pcss">
  .c-lightswitch {
    .c-lightswitch-input {
      label {
        @apply relative block select-none;
        width: 34px;
        height: 22px;

        input {
          @apply absolute opacity-0;
        }

        .slider {
          @apply absolute inset-0 cursor-pointer bg-gray-500;
          -webkit-transition: .4s;
          transition: .4s;
        }

        .slider:before {
          @apply absolute bg-white;
          content: "";
          height: 20px;
          width: 20px;
          left: 1px;
          bottom: 1px;
          -webkit-transition: .1s;
          transition: .1s;

          -webkit-transform: translateX(0px);
          -ms-transform: translateX(0px);
          transform: translateX(0px);
        }

        input:checked + .slider {
          @apply bg-green-600;
        }

        input:focus + .slider {
          @apply ring-2 ring-blue-500 ring-opacity-100;
        }

        input:checked + .slider:before {
          -webkit-transform: translateX(12px);
          -ms-transform: translateX(12px);
          transform: translateX(12px);
        }

        .slider.round {
          border-radius: 34px;
        }

        .slider.round:before {
          border-radius: 50%;
        }

        &.disabled {
          opacity: .4;

          .slider {
            @apply cursor-default;
          }
        }
      }
    }
  }
</style>
