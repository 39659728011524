import orgsApi from '@console/api/orgs';

/**
 * Orgs State
 *
 * @typedef {object} OrgsState
 * @property {array} orgs
 * @property {?string} currentOrgSlug
 * @property {array} members
 * @property {array} invitations
 * @property {array} userInvitations
 * @property {?object} org
 * @property {?object} currentMember
 */

/**
 * State
 * @type {OrgsState}
 */
const state = {
  orgs: [],
  currentOrgSlug: null,
  members: [],
  invitations: [],
  userInvitations: [],
  org: null,
  currentMember: null,
};

/**
 * Getters
 */
const getters = {
  isCurrentMember(state) {
    return (member) => state.currentMember.id === member.id;
  },

  currentOrg(state) {
    if (!state.currentOrgSlug) {
      return null;
    }

    return state.orgs.find((o) => o.slug === state.currentOrgSlug);
  },

  getOrg(state) {
    return (id) => {
      return state.orgs.find((o) => o.id === id);
    };
  },

  getOrgBySlug(state) {
    return (slug) => {
      return state.orgs.find((o) => o.slug === slug);
    };
  },

  canAccessDeveloperPlugins(state) {
    return state.currentMember && state.currentMember.canManagePlugins;
  },
};

/**
 * Actions
 */
const actions = {
  addMember({dispatch}, {orgId, email, role}) {
    return orgsApi.addMember({orgId, email, role}).then(() => {
      dispatch('getOrgMembers', {orgId});
    });
  },

  requestOrderApproval(context, {orgId, orderNumber, fields} = {fields: null}) {
    return orgsApi.requestOrderApproval({orgId, orderNumber, fields});
  },

  rejectRequest(context, {orgId, orderNumber}) {
    return orgsApi.rejectRequest({orgId, orderNumber});
  },

  async getCurrentMember({rootState, getters, commit}) {
    if (!getters['currentOrg']) {
      return commit('updateCurrentMember', null);
    }

    const orgId = getters['currentOrg'].id;
    const userId = rootState.account.user.id;

    try {
      const {data} = await orgsApi.getOrgMember({orgId, userId});
      commit('updateCurrentMember', data.member);
      return data.member;
    } catch (error) {
      commit('updateCurrentMember', null);
      return null;
    }
  },

  getOrgMembers({commit}, {orgId}) {
    return new Promise((resolve, reject) => {
      return orgsApi
        .getOrgMembers({orgId})
        .then((response) => {
          commit('updateMembers', response.data.members);
          resolve(response);
        })
        .catch((response) => {
          reject(response);
        });
    });
  },

  getOrgs({commit}) {
    return new Promise((resolve, reject) => {
      orgsApi
        .getOrgs()
        .then((response) => {
          commit('updateOrgs', response.data.orgs);
          resolve(response);
        })
        .catch((response) => {
          reject(response);
        });
    });
  },

  getOrg({commit}, orgId) {
    return new Promise((resolve, reject) => {
      orgsApi
        .getOrg(orgId)
        .then((response) => {
          commit('updateOrg', response.data);
          resolve(response);
        })
        .catch((response) => {
          reject(response);
        });
    });
  },

  removeMember({dispatch}, {orgId, memberId}) {
    return new Promise((resolve, reject) => {
      return orgsApi
        .removeMember({orgId, memberId})
        .then(() => {
          dispatch('getOrgMembers', {orgId})
            .then(() => {
              resolve();
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  saveOrg({dispatch}, org) {
    return new Promise((resolve, reject) => {
      orgsApi
        .saveOrg(org)
        .then((response) => {
          dispatch('getOrgs').then(() => {
            resolve(response);
          });
        })
        .catch((response) => {
          reject(response);
        });
    });
  },

  getInvitations({commit}, {orgId}) {
    return orgsApi.getInvitations({orgId}).then((response) => {
      commit('updateInvitations', response.data.invitations);
    });
  },

  getUserInvitations({commit}) {
    return orgsApi.getUserInvitations().then((response) => {
      commit('updateUserInvitations', response.data.invitations);
    });
  },

  cancelInvitation(context, {orgId, userId}) {
    return orgsApi.cancelInvitation({orgId, userId});
  },

  acceptInvitation({dispatch}, {orgId}) {
    return new Promise((resolve, reject) => {
      orgsApi
        .acceptInvitation({orgId})
        .then(() => {
          dispatch('getOrgs')
            .then(() => {
              dispatch('getUserInvitations')
                .then(() => {
                  dispatch('account/getAccount', null, {root: true})
                    .then(() => {
                      resolve();
                    })
                    .catch((getAccountError) => {
                      reject(getAccountError);
                    });
                })
                .catch((userInvitationsError) => {
                  reject(userInvitationsError);
                });
            })
            .catch((orgError) => {
              reject(orgError);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  declineInvitation({dispatch}, {orgId}) {
    return new Promise((resolve, reject) => {
      orgsApi
        .declineInvitation({orgId})
        .then(() => {
          dispatch('getUserInvitations')
            .then(() => {
              resolve();
            })
            .catch((userInvitationsError) => {
              reject(userInvitationsError);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  setRole(context, {orgId, userId, role}) {
    return orgsApi.setRole({orgId, userId, role});
  },

  setInvitationRole(context, {orgId, userId, role}) {
    return orgsApi.setInvitationRole({orgId, userId, role});
  },

  saveMember(context, {orgId, userId, data}) {
    return orgsApi.saveMember({orgId, userId}, data);
  },

  deleteOrg({dispatch}, orgId) {
    return new Promise((resolve, reject) => {
      orgsApi
        .deleteOrg(orgId)
        .then(() => {
          dispatch('getOrgs').then(() => {
            resolve();
          });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

/**
 * Mutations
 */
const mutations = {
  updateCurrentOrgSlug(state, orgSlug) {
    state.currentOrgSlug = orgSlug;
  },

  updateOrg(state, org) {
    state.org = org;
  },

  updateOrgs(state, orgs) {
    state.orgs = orgs;
  },

  updateCurrentMember(state, currentMember) {
    state.currentMember = currentMember;
  },

  updateMembers(state, members) {
    state.members = members;
  },

  updateInvitations(state, invitations) {
    state.invitations = invitations;
  },

  updateUserInvitations(state, userInvitations) {
    state.userInvitations = userInvitations;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
