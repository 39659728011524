/**
 * Open a popup window centered in the screen
 *
 * @link http://www.xtf.dk/2011/08/center-new-popup-window-even-on.html
 */
export function openPopup(
  url: string,
  {target = '_blank', w = 800, h = 800} = {}
) {
  const dualScreenLeft = window.screenLeft || 0;
  const dualScreenTop = window.screenTop || 0;

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : screen.width;
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : screen.height;

  const left = width / 2 - w / 2 + dualScreenLeft;
  const top = height / 2 - h / 2 + dualScreenTop;
  return window.open(
    url,
    target,
    `popup,width=${w},height=${h},top=${top},left=${left}`
  );
}
