<template>
  <div
    class="c-dropdown"
    :class="{
      'is-invalid': invalid,
      'w-full': fullwidth,
      disabled,
    }"
  >
    <select
      :id="id"
      :name="name"
      :disabled="disabled"
      :value="modelValue"
      :required="required"
      :autocomplete="autocomplete"
      :class="{
        'form-select bg-white dark:bg-gray-700 text-sm sm:leading-5 pl-3 pr-10 rounded-md': true,
        'w-full': fullwidth,
        'border-error': invalid,
        'border-input': !invalid,
      }"
      :aria-describedby="computedDescribedBy"
      @input="
        $emit(
          'update:modelValue',
          $event.target[$event.target.selectedIndex]?.value
        )
      "
    >
      <option v-for="(option, key) in options" :key="key" :value="option.value">
        {{ option.label }}
      </option>
    </select>
  </div>
</template>

<script>
  export default {
    props: {
      autocomplete: {
        type: [String, null],
        default: null,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      describedBy: {
        type: Array,
        default: () => [],
      },
      required: {
        type: Boolean,
        default: false,
      },
      invalid: {
        type: Boolean,
        default: false,
      },
      fullwidth: {
        type: Boolean,
        default: false,
      },
      name: {
        type: [String, null],
        default: null,
      },
      id: {
        type: [String, null],
        default: function () {
          return 'c-dropdown-id-' + Math.random().toString(36).substr(2, 9);
        },
      },
      options: {
        type: Array,
        default: null,
      },
      modelValue: {
        type: [String, Number],
        default: null,
      },
    },
    computed: {
      computedDescribedBy() {
        if (this.describedBy.length > 0) {
          return this.describedBy.join(' ');
        }

        return null;
      },
    },
    emits: ['update:modelValue'],
  };
</script>

<style lang="pcss">
  .c-dropdown {
    display: inline-block;
    position: relative;

    &.disabled {
      @apply opacity-50;
    }

    select {
      background-position: right 0.5rem center;
    }
  }
</style>
