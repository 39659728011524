import {baseUrlAuth, cloudClient} from '@console/api/cloud';

/**
 * State
 */
const state = {
  providers: [],
  namespaces: [],
  repositories: {},
  all: [],
};

/**
 * Getters
 */
const getters = {
  providers(state) {
    const token = localStorage.getItem('cloudToken');

    return state.providers.map((provider) => {
      return {
        ...provider,
        connectUrl: `${baseUrlAuth}/${provider.handle}?token=${token}&redirect_to=${window.location.href}`,
        isActive: Boolean(provider.integration_id),
      };
    });
  },

  repos:
    (state) =>
    ({namespace, integration}) => {
      if (
        state.repositories[integration] &&
        state.repositories[integration][namespace]
      ) {
        return state.repositories[integration][namespace];
      }

      return [];
    },
};

/**
 * Actions
 */
const actions = {
  async fetchRepos({commit}, {integrationId, namespaceId, query}) {
    let url = `/integrations/${integrationId}/namespaces/${namespaceId}/repositories`;
    if (query) {
      url = `${url}?query=${query}`;
    }
    return cloudClient.get(url).then(({data}) => {
      commit('updateRepos', {
        repos: data,
        integrationId,
        namespace: namespaceId,
      });
      return data;
    });
  },

  async fetchNamespaces({commit}, integrationId) {
    return cloudClient
      .get(`/integrations/${integrationId}/namespaces`)
      .then(({data}) => {
        commit('updateNamespaces', data.data);
        return data.data;
      });
  },
};

/**
 * Mutations
 */
const mutations = {
  updateIntegrations(state, integrations) {
    state.all = [...integrations];
  },

  updateProviders(state, providers) {
    state.providers = providers;
  },

  updateNamespaces(state, namespaces) {
    state.namespaces = namespaces;
  },

  updateRepos(state, {repos, integrationId, namespace}) {
    if (!state.repositories[integrationId]) {
      state.repositories[integrationId] = {};
    }

    // This shouldn't happen, but in case it does
    if (!state.repositories[integrationId][namespace]) {
      state.repositories[integrationId][namespace] = [];
    }

    state.repositories[integrationId][namespace] = repos.data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
