import _axios from 'axios';
import {getCraftApiEndpointUrl} from '@console/helpers/url';

let axiosInstance;

const axios = () => {
  if (!axiosInstance) {
    axiosInstance = _axios.create();
  }

  // Keep the CSRF token fresh
  axiosInstance.defaults.headers['X-CSRF-Token'] = Craft.csrfTokenValue;

  return axiosInstance;
};

export const apiClient = _axios.create({
  baseURL: getCraftApiEndpointUrl(''),
  withCredentials: true,
});

apiClient.defaults.headers['X-CSRF-Token'] = Craft.csrfTokenValue;

export default axios;
