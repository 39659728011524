import addressesApi from '@console/api/addresses';

/**
 * State
 */
const state = {
  addresses: null,
  countries: null,
  info: null,
};

/**
 * Getters
 */
const getters = {};

/**
 * Actions
 */
const actions = {
  getCountries({commit}) {
    return new Promise((resolve, reject) => {
      addressesApi
        .getCountries()
        .then((response) => {
          commit('updateCountries', {countries: response.data.countries});
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getInfo({commit}, {countryCode, administrativeArea, locality}) {
    return new Promise((resolve, reject) => {
      addressesApi
        .getInfo({countryCode, administrativeArea, locality})
        .then((response) => {
          commit('updateInfo', {info: response.data.addressInfo});
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

/**
 * Mutations
 */
const mutations = {
  updateCountries(state, {countries}) {
    state.countries = countries;
  },
  updateInfo(state, {info}) {
    state.info = info;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
