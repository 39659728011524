export function checkLicenseOwnership(
  type,
  {currentOrg, license, getOrg, $router, getPrefixedTo, licenseId, user}
) {
  if (currentOrg) {
    // Current is an org
    if (license.orgId) {
      if (currentOrg.id !== license.orgId) {
        // License’s org doesn’t match current org.
        const licenseOrg = getOrg(license.orgId);

        if (!licenseOrg) {
          // License org not found, redirect to current org’s licenses
          return $router.push(getPrefixedTo('/licenses/' + type));
        }

        // Redirect to the license’s org.
        return $router.push(
          '/accounts/' + licenseOrg.slug + '/licenses/' + type + '/' + licenseId
        );
      } else {
        // License’s org matches current org, do nothing
      }
    } else {
      // License belongs to a user, not an org.
      // Redirect to user's license.
      return $router.push('/licenses/' + type + '/' + licenseId);
    }
  } else {
    // Current is a user
    if (license.userId) {
      if (user.id !== license.userId) {
        // License’s user doesn’t match current user.
        // Redirect to the license’s user.
        return $router.push('/licenses/' + type);
      } else {
        // License’s user matches current user, do nothing
      }
    } else {
      // License belongs to an org, not a user.
      const licenseOrg = getOrg(license.orgId);

      if (!licenseOrg) {
        // License org not found.
        // Redirect to user’s license.
        return $router.push('/licenses/' + type);
      }

      // Redirect to org’s license.
      return $router.push(
        '/accounts/' + licenseOrg.slug + '/licenses/' + type + '/' + licenseId
      );
    }
  }
}

export function formatCmsLicense(value) {
  const normalizedValue = value.replace(/(\r\n|\n|\r)/gm, '');

  const parts = [];

  if (normalizedValue.length > 0) {
    parts.push(normalizedValue.substr(0, 50));
  }

  if (normalizedValue.length > 50) {
    parts.push(normalizedValue.substr(50, 50));
  }

  if (normalizedValue.length > 100) {
    parts.push(normalizedValue.substr(100, 50));
  }

  if (normalizedValue.length > 150) {
    parts.push(normalizedValue.substr(150, 50));
  }

  if (normalizedValue.length > 200) {
    parts.push(normalizedValue.substr(200, 50));
  }

  if (normalizedValue.length > 250) {
    parts.push(normalizedValue.substr(250));
  }

  let formattedValue = '';

  for (let i = 0; i < parts.length; i++) {
    formattedValue += parts[i];

    if (i < parts.length - 1) {
      formattedValue += '\r\n';
    }
  }

  return formattedValue;
}

export function formatPluginLicense(value, format) {
  switch (format) {
    case 'short': {
      return value.substr(0, 4);
    }
    case 'full': {
      const parts = [];

      parts.push(value.substr(0, 4));
      parts.push(value.substr(4, 4));
      parts.push(value.substr(8, 4));
      parts.push(value.substr(12, 4));
      parts.push(value.substr(16, 4));
      parts.push(value.substr(20));

      let formattedValue = '';

      for (let i = 0; i < parts.length; i++) {
        formattedValue += parts[i];

        if (i < parts.length - 1) {
          formattedValue += '-';
        }
      }

      return formattedValue;
    }
    default: {
      if (value) {
        return `${value.substr(0, 4)}-****-****-****-****-****`;
      }

      return '****';
    }
  }
}
