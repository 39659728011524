<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div">
      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="min-h-screen px-4 text-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay class="fixed inset-0 bg-black bg-opacity-75" />
          </TransitionChild>

          <span class="inline-block h-screen align-middle" aria-hidden="true"
            >&#8203;</span
          >

          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <div
              class="space-x-3 absolute top-0 left-0 transform -translate-y-full"
            >
              <!-- Fix focus trap issue -->
              <button>Open Sign Out Warning</button>
            </div>
          </TransitionChild>
        </div>
      </div>

      <slot />
    </Dialog>
  </TransitionRoot>
</template>

<script>
  import {
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
  } from '@headlessui/vue';

  export default {
    components: {
      TransitionRoot,
      TransitionChild,
      Dialog,
      DialogOverlay,
    },
    props: {
      isOpen: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>
