<template>
  <ul class="skip-links">
    <li>
      <a
        href="#main"
        ref="skipLink"
        @click="handleSkipLink"
        class="skip-link bg-white dark:bg-blue-900 dark:text-white"
        >Skip to main content</a
      >
    </li>
  </ul>
  <div>
    <!-- Authentification manager -->
    <auth-manager ref="authManager" />

    <!-- Notifications -->
    <VueAnnouncer class="sr-only" />
    <template v-if="notification">
      <notification :type="notification.type" :message="notification.message" />
    </template>

    <!-- Loading -->
    <template v-if="loading">
      <div class="mt-24 text-center">
        <spinner size="lg" class="mt-8" />
      </div>
    </template>

    <!-- Layout -->
    <template v-else>
      <component ref="layout" :is="layoutComponent" />
    </template>
  </div>

  <DeveloperContext v-if="orgs.find((org) => org.slug === 'pixelandtonic')" />
</template>

<script>
  import {mapActions, mapGetters, mapState} from 'vuex';
  import AuthManager from '@console/components/app/auth-manager/AuthManager.vue';
  import AppLayout from '@console/components/app/layouts/AppLayout.vue';
  import CheckoutLayout from '@console/components/app/layouts/CheckoutLayout.vue';
  import SiteLayout from '@console/components/app/layouts/SiteLayout.vue';
  import BlankLayout from '@console/components/app/layouts/BlankLayout.vue';
  import appApi from '@console/api/app.js';
  import api from '@console/api/cart';
  import Spinner from '@/common/ui/components/Spinner.vue';
  import Notification from '@/common/ui/components/Notification.vue';
  import DeveloperContext from '@console/components/DeveloperContext.vue';
  import TermsModal from '@console/components/app/terms-modal/TermsModal.vue';

  export default {
    components: {
      TermsModal,
      DeveloperContext,
      Spinner,
      AuthManager,
      AppLayout,
      BlankLayout,
      CheckoutLayout,
      SiteLayout,
      Notification,
    },

    data() {
      return {
        accountLoading: false,
        craftSessionLoaded: false,
        userLoaded: false,
      };
    },

    computed: {
      ...mapState({
        notification: (state) => state.app.notification,
        orgs: (state) => state.orgs.orgs,
        loading: (state) => state.app.loading,
        user: (state) => state.account.user,
        currentOrgSlug: (state) => state.orgs.currentOrgSlug,
        pageTitle: (state) => state.app.pageTitle,
      }),

      ...mapGetters({
        activeContext: 'context/active',
        currentOrg: 'orgs/currentOrg',
        getOrgBySlug: 'orgs/getOrgBySlug',
      }),

      layoutComponent() {
        if (!this.user) {
          return 'site-layout';
        }

        switch (this.$route.meta.layout) {
          case 'site':
            return 'site-layout';

          case 'checkout':
            return 'checkout-layout';

          case 'blank':
            return 'blank-layout';

          default:
            return 'app-layout';
        }
      },

      routeOrgSlug() {
        return (route) => {
          return route.params && route.params.orgSlug
            ? route.params.orgSlug
            : null;
        };
      },
    },

    watch: {
      pageTitle() {
        window.document.title = this.pageTitle;
      },

      notification() {
        if (this.notification) {
          this.notify(this.notification.message);
        }
      },

      $route() {
        this.handleRouteChange();
      },
    },

    mounted() {
      // Let's get started!
      this.initApp();
    },

    methods: {
      ...mapActions({
        initContext: 'context/init',
      }),
      handleRouteChange() {
        document.body.focus();
      },
      handleSkipLink(event) {
        event.preventDefault();
        const main = document.querySelector('#main');
        if (main) {
          main.focus();
        }
      },
      /**
       * Initializes the app.
       */
      initApp() {
        // Initialize the router hooks
        this.initRouterHooks();

        // Display any notices or errors from the server
        if (window.sessionNotice) {
          this.$store.dispatch('app/displayNotice', window.sessionNotice);
        }

        if (window.sessionError) {
          this.$store.dispatch('app/displayError', window.sessionError);
        }
      },

      /**
       * Initializes before/after each route hooks.
       */
      initRouterHooks() {
        // Before each route
        this.$router.beforeEach(async (to, from, next) => {
          // Reset breadcrumbs
          this.$store.commit('app/updateBreadcrumbs', []);

          // Redirect to the remembered org if we have a last org slug
          const triggerAfterLogin = appApi.getTriggerAfterLogin();

          if (triggerAfterLogin) {
            appApi.saveTriggerAfterLogin(false);

            const lastOrgSlug = appApi.getLastOrgSlug();
            if (lastOrgSlug) {
              return next({path: '/accounts/' + lastOrgSlug});
            }
          }

          // No $refs? Stop there.
          if (!this.$refs) {
            return;
          }

          // Renew the auth manager’s session
          if (this.$refs.authManager) {
            this.$refs.authManager.renewSession();
          }

          // Initialize the user account if we haven't already
          if (
            !this.activeContext ||
            this.activeContext?.slug !== to.params?.orgSlug
          ) {
            this.$store.commit('app/updateLoading', true);
            await this.initAccount();
            await this.loadCurrentOrg(to);
            await this.initContext();
          }

          this.handleRoute(to, from, next);
        });

        // After each route
        this.$router.afterEach(() => {
          this.$store.commit('app/updateLoading', false);
        });
      },

      /**
       * Initializes the user account.
       */
      async initAccount() {
        if (!this.$store.state.account.user) {
          const userLoggedIn = await this.loadAccount();

          if (userLoggedIn) {
            await this.handleLoggedInUser();
          } else {
            await this.handleGuest();
          }
        }
      },

      /**
       * Loads the user account.
       *
       * @returns {Promise<unknown>}
       */
      async loadAccount() {
        if (this.accountLoading) {
          return !!this.user;
        }

        this.accountLoading = true;

        let shouldLoadAccount = false;

        if (!this.craftSessionLoaded) {
          this.craftSessionLoaded = true;

          if (window.currentUserId) {
            shouldLoadAccount = true;
          } else {
            this.userLoaded = true;
          }
        } else {
          if (!this.userLoaded) {
            shouldLoadAccount = true;
          }
        }

        if (shouldLoadAccount) {
          try {
            await this.$store.dispatch('account/getAccount');

            this.accountLoading = false;
            this.userLoaded = true;
            return true;
          } catch (error) {
            this.accountLoading = false;
            this.userLoaded = true;
            return false;
          }
        } else {
          this.accountLoading = false;
          return !!this.user;
        }
      },

      /**
       * Retrieves the guest’s cart from the local storage’s order number to allow cart item count.
       *
       * @returns {Promise<unknown>}
       */
      handleGuest() {
        const guestOrderNumber = api.getLocalStorageOrderNumber();

        if (guestOrderNumber) {
          return this.$store.dispatch('cart/getCart').catch((error) => {
            if (error === 'Cart Not Found') {
              // if order number is invalid, delete it from local storage
              api.deleteLocalStorageOrderNumber();
            }
          });
        }
      },

      /**
       * Retrieves the logged-in user’s orgs and invitations.
       *
       * @returns {Promise<unknown>}
       */
      async handleLoggedInUser() {
        await this.$store.dispatch('orgs/getOrgs');
        await this.$store.dispatch('orgs/getUserInvitations');
      },

      /**
       * Load the current org.
       *
       * @param to
       */
      async loadCurrentOrg(to) {
        const routeOrgSlug = this.routeOrgSlug(to);

        // Remember last org slug
        if (
          this.user &&
          // Don't remember the last org slug for the root index since we want to use that orgSlug for the redirect
          to.name !== 'org.index' &&
          to.name !== 'user.index' &&
          // Don't remember an org the user is not a member of
          (!routeOrgSlug || this.orgs.find((org) => org.slug === routeOrgSlug))
        ) {
          appApi.saveLastOrgSlug(routeOrgSlug);
        }

        if (this.currentOrgSlug !== routeOrgSlug) {
          await this.$store.commit('orgs/updateCurrentOrgSlug', routeOrgSlug);

          if (!this.currentOrg) {
            await this.$store.commit('orgs/updateCurrentMember', null);
          } else {
            await this.$store.dispatch('orgs/getCurrentMember');
          }
        }
      },

      /**
       * Handle the route.
       *
       * @param to
       * @param from
       * @param next
       */
      handleRoute(to, from, next) {
        if (this.$store.state.account.user) {
          // Logged in user

          // Renew the auth manager’s session if needed
          if (this.$refs.authManager) {
            this.$refs.authManager.renewSession();
          }

          // Redirect to /accounts/me if the route org slug is set but doesn’t match any of the user’s org
          const routeOrgSlug = this.routeOrgSlug(to);
          if (routeOrgSlug && !this.getOrgBySlug(routeOrgSlug)) {
            return next({path: '/accounts/me'});
          }

          if (to.meta.orgOnly) {
            if (this.currentOrg) {
              next();
            } else {
              next({path: '/'});
            }
          } else if (to.meta.userOnly) {
            if (!this.currentOrg) {
              next();
            } else {
              next({path: '/'});
            }
          } else {
            next();
          }
        } else {
          // Guest user
          // Check that the user can access the next route
          if (!to.meta.allowAnonymous) {
            const redirect =
              !to.redirectedFrom && to.path !== '/login' ? to.fullPath : null;

            next({path: '/login', query: redirect ? {redirect} : null});
          } else {
            next();
          }
        }
      },

      /**
       * Connect app callback used by `templates/console/apps/callback.twig`
       *
       * @param apps
       */
      connectAppCallback(apps) {
        this.$store.dispatch('apps/connectAppCallback', apps);

        this.$store.dispatch('app/displayNotice', 'App connected.');
      },

      notify(message) {
        this.$announcer.polite(message);
      },
    },

    expose: ['connectAppCallback'],
  };
</script>
