import axios from './axios';
import qs from 'qs';
import {getAppUrl} from '@console/helpers/url.js';

const objectToFormData = (obj, rootName, ignoreList) => {
  var formData = new FormData();

  function appendFormData(data, root) {
    if (!ignore(root)) {
      root = root || '';
      if (data instanceof File) {
        formData.append(root, data);
      } else if (Array.isArray(data)) {
        for (var i = 0; i < data.length; i++) {
          appendFormData(data[i], root + '[' + i + ']');
        }
      } else if (typeof data === 'object' && data) {
        for (var key in data) {
          if (Object.prototype.hasOwnProperty.call(data, key)) {
            if (root === '') {
              appendFormData(data[key], key);
            } else {
              appendFormData(data[key], root + '[' + key + ']');
            }
          }
        }
      } else {
        if (data !== null && typeof data !== 'undefined') {
          formData.append(root, data);
        }
      }
    }
  }

  function ignore(root) {
    return (
      Array.isArray(ignoreList) &&
      ignoreList.some(function (x) {
        return x === root;
      })
    );
  }

  appendFormData(obj, rootName);

  return formData;
};

export default {
  addMember({orgId, email, role}) {
    const data = {
      email,
      role,
    };
    return axios().post(
      getAppUrl() + '/orgs/' + orgId + '/invitations',
      qs.stringify(data)
    );
  },

  saveOrg(org) {
    // Form data
    const formData = objectToFormData(org);

    // Endpoint URL
    let endpointUrl = getAppUrl() + '/orgs';

    if (org.id) {
      endpointUrl += '/' + org.id;
    }

    return axios().post(endpointUrl, formData);
  },

  getOrders(orgId) {
    const query = qs.stringify({
      orgId: orgId,
    });

    return axios().get(getAppUrl() + '/orders?' + query);
  },

  getPendingOrders(orgId) {
    const query = qs.stringify({
      orgId: orgId,
      approvalPending: 1,
    });

    return axios().get(getAppUrl() + '/orders?' + query);
  },

  requestOrderApproval({orgId, orderNumber, fields} = {fields: null}) {
    return axios().post(
      getAppUrl() + '/orders/' + orderNumber + '/request-approval',
      {
        orgId: orgId,
        fields,
      }
    );
  },

  rejectRequest({orgId, orderNumber}) {
    return axios().post(
      getAppUrl() + '/orders/' + orderNumber + '/reject-request',
      {
        orgId: orgId,
      }
    );
  },

  getOrgMember({orgId, userId}) {
    return axios().get(getAppUrl() + '/orgs/' + orgId + '/members/' + userId);
  },

  getOrgMembers({orgId}) {
    return axios().get(getAppUrl() + '/orgs/' + orgId + '/members');
  },

  getOrg(orgId) {
    return axios().get(getAppUrl() + '/orgs/' + orgId);
  },

  getOrgs() {
    return axios().get(getAppUrl() + '/orgs');
  },

  removeMember({orgId, memberId}) {
    return axios().delete(
      getAppUrl() + '/orgs/' + orgId + '/members/' + memberId
    );
  },

  getInvitations({orgId}) {
    return axios().get(getAppUrl() + '/orgs/' + orgId + '/invitations');
  },

  getUserInvitations() {
    return axios().get(getAppUrl() + '/orgs/invitations');
  },

  cancelInvitation({orgId, userId}) {
    return axios().delete(
      getAppUrl() + '/orgs/' + orgId + '/invitations/' + userId
    );
  },

  acceptInvitation({orgId}, data) {
    return axios().post(getAppUrl() + '/orgs/' + orgId + '/invitation', data);
  },

  declineInvitation({orgId}) {
    return axios().delete(getAppUrl() + '/orgs/' + orgId + '/invitation');
  },

  setRole({orgId, userId, role}) {
    return axios().post(
      getAppUrl() + '/orgs/' + orgId + '/members/' + userId + '/role',
      {
        role,
      }
    );
  },

  setInvitationRole({orgId, userId, role}) {
    return axios().post(
      getAppUrl() + '/orgs/' + orgId + '/invitations/' + userId,
      {
        role,
      }
    );
  },

  saveMember({orgId, userId}, data) {
    return axios().post(
      getAppUrl() + '/orgs/' + orgId + '/members/' + userId,
      data
    );
  },

  deleteOrg(orgId) {
    return axios().delete(getAppUrl() + '/orgs/' + orgId);
  },
};
