<script setup lang="ts">
  import usersApi from '@console/api/users.js';
  import HeadlessDialog from '@console/components/app/modals/HeadlessDialog.vue';
  import Btn from '@/common/ui/components/Btn.vue';
  import ComLink from '@console/components/ComLink.vue';
  import {DialogTitle} from '@headlessui/vue';
  import {useStore} from 'vuex';
  import {ref} from 'vue';
  import {DateTime} from 'luxon';

  const store = useStore();
  const state = ref('idle');
  const isOpen = ref(true);

  async function handleSubmit() {
    state.value = 'loading';

    try {
      await store.dispatch('account/saveUser', {
        termsAcceptedOn: DateTime.now().toISO(),
      });
      state.value = 'success';
      isOpen.value = false;
    } catch (error) {
      await store.dispatch(
        'app/displayError',
        'Failed to accept terms. Please contact support@craftcms.com'
      );
      state.value = 'error';
    }
  }

  async function logout() {
    await usersApi.logout();
    document.location.href = '';
  }
</script>

<template>
  <HeadlessDialog :is-open="isOpen" @close="false">
    <form @submit.prevent="handleSubmit" id="terms-form">
      <DialogTitle class="mb-2"> We've updated our terms </DialogTitle>
      <div>
        We’ve updated our
        <com-link path="terms-of-service">Terms of Service</com-link>
        and
        <com-link path="acceptable-use-policy">Acceptable Use Policy</com-link>.
        By continuing to use our services you agree to these updated terms.
      </div>

      <div class="mt-6 flex gap-2 justify-end">
        <Btn @click="logout">Sign Out</Btn>
        <Btn kind="primary" type="submit" :loading="state === 'loading'"
          >Agree</Btn
        >
      </div>
    </form>
  </HeadlessDialog>
</template>

<style scoped></style>
