<template>
  <TransitionChild
    as="template"
    enter="duration-300 ease-out"
    enter-from="opacity-0 scale-95"
    enter-to="opacity-100 scale-100"
    leave="duration-100 ease-in"
    leave-from="opacity-100 scale-100"
    leave-to="opacity-0 scale-95"
  >
    <form
      class="relative flex flex-col w-full md:max-w-2xl inline-block overflow-hidden text-left align-middle transition-all transform bg-primary md:border md:rounded-xl"
      @submit.prevent="$emit('submitForm')"
    >
      <slot />
    </form>
  </TransitionChild>
</template>
<script>
  import {TransitionChild} from '@headlessui/vue';
  export default {
    components: {TransitionChild},
    emits: ['submitForm'],
  };
</script>
