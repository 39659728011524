import axios from './axios';
import qs from 'qs';
import {getAppUrl} from '@console/helpers/url.js';

export default {
  getOrder(orderNumber, orgId) {
    const query = {};

    if (orgId) {
      query.orgId = orgId;
    }

    return axios().get(
      getAppUrl() + '/orders/' + orderNumber + '?' + qs.stringify(query)
    );
  },

  getOrders(orgId, queryParams) {
    const query = qs.stringify({
      orgId: orgId,
      ...queryParams,
    });

    return axios().get(getAppUrl() + '/orders?' + query);
  },

  getPendingOrders(orgId) {
    const query = qs.stringify({
      orgId: orgId,
      approvalPending: 1,
    });

    return axios().get(getAppUrl() + '/orders?' + query);
  },

  requestOrderApproval({orgId, orderNumber}) {
    return axios().post(
      getAppUrl() + '/orders/' + orderNumber + '/request-approval',
      {
        orgId: orgId,
      }
    );
  },

  rejectRequest({orgId, orderNumber}) {
    return axios().post(
      getAppUrl() + '/orders/' + orderNumber + '/reject-request',
      {
        orgId: orgId,
      }
    );
  },
};
