import {useGet} from '@console/helpers/query';
import {useRoute} from 'vue-router';
import {computed, unref} from 'vue';

interface ItemStatus {
  id: string;
  status: 'pending' | 'running';
}

export interface EnvironmentStatus {
  id: string;
  backups: ItemStatus[];
  deployments: ItemStatus[];
  commands: ItemStatus[];
}

export const useGetProjectActivity = () => {
  const route = useRoute();
  const {projectKey} = route.params;

  const response = useGet<EnvironmentStatus[]>(
    `/projects/${projectKey}/activity`,
    {},
    {
      refetchInterval: 5000,
    }
  );

  const status = computed(() => {
    const environmentId = unref(route.params.environmentId as string);

    return (response.data.value || []).find(
      (e: EnvironmentStatus) => e.id === environmentId
    );
  });

  return {
    ...response,
    status,
  };
};
