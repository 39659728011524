<template>
  <router-link
    :class="{
      'cart-button relative block header-toggle px-3 py-2 rounded-md leading-none': true,
      'hover:bg-white dark:hover:bg-black hover:bg-opacity-30 dark:hover:bg-opacity-30':
        context !== 'app',
      'hover:bg-black dark:hover:bg-white hover:bg-opacity-5 dark:hover:bg-opacity-5':
        context === 'app',
    }"
    :to="!user || orgs.length === 0 ? '/cart' : getPrefixedTo('/cart')"
    aria-label="Cart"
  >
    <icon
      set="solid"
      icon="shopping-cart"
      class="w-5 h-5"
      :class="{
        'text-blue-600 dark:text-blue-400': context !== 'app',
        'text-gray-500': context === 'app',
      }"
      :size="null"
    />

    <div
      class="cart-badge rounded-full leading-3 absolute text-white text-center top-0 right-0 px-1.5 py-0.5 text-xs -mt-0.5"
      :class="{
        invisible: !totalItems,
        'bg-blue-600 dark:bg-blue-400': context !== 'app',
        'bg-gray-500': context === 'app',
      }"
    >
      {{ totalItems }}
    </div>
  </router-link>
</template>

<script>
  import {mapGetters, mapState} from 'vuex';
  import {getPrefixedTo} from '@console/helpers/router';

  export default {
    methods: {getPrefixedTo},
    props: {
      context: {
        type: String,
        default: 'app',
      },
    },
    computed: {
      ...mapState({
        orgs: (state) => state.orgs.orgs,
        user: (state) => state.account.user,
        cart: (state) => state.cart.cart,
      }),
      ...mapGetters({
        currentOrg: 'orgs/currentOrg',
      }),

      totalItems() {
        if (!this.cart) {
          return 0;
        }

        return this.cart ? this.cart.lineItems.length : 0;
      },
    },
  };
</script>

<style lang="pcss">
  .cart-button {
    .cart-badge {
      font-size: 0.625rem;
    }
  }
</style>
