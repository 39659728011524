<script setup lang="ts">
  import {useRoute} from 'vue-router';
  import Copy from '@console/components/Copy.vue';
  import Icon from '@/common/ui/components/Icon.vue';
  import {computed, ref} from 'vue';
  import {useStore} from 'vuex';

  const route = useRoute();
  const store = useStore();
  const state = ref('collapsed');

  interface Item {
    type?: 'separator' | 'heading' | 'item';
    heading: string;
    value: any[];
  }

  const contextContent = ref<HTMLElement | null>(null);
  const currentOrg = computed(() => store.getters['orgs/currentOrg']);

  const user = computed(() => store.state.account.user);
  const primaryPaymentMethod = computed(
    () => store.getters['paymentMethods/primaryPaymentMethod']
  );

  const contextData = computed<Item[]>(() => {
    return [
      {
        type: 'heading',
        heading: 'Global',
      },
      {
        heading: 'User',
        value: [user.value?.id, user.value?.uid],
      },
      {
        heading: 'Org',
        value: [currentOrg.value?.id ?? '—', currentOrg.value?.uid ?? '—'],
      },
      {
        heading: 'Stripe Customer',
        value: [primaryPaymentMethod.value?.stripeCustomerRef],
      },
      {
        type: 'separator',
        heading: 'sep',
      },
      {
        type: 'heading',
        heading: 'Cloud',
      },
      {
        heading: 'Project',
        value: [route.params.projectKey?.toString() ?? '—'],
      },
      {
        heading: 'Environment',
        value: [route.params.environmentId?.toString() ?? '—'],
      },
      {
        heading: 'Deployment',
        value: [route.params.deploymentId?.toString() ?? '—'],
      },
    ];
  });
</script>

<template>
  <div
    class="fixed z-20 bottom-6 right-6 bg-primary shadow-md rounded-md overflow-hidden border border-separator"
  >
    <div v-if="state === 'expanded'" class="font-mono text-xs text-light">
      <div
        class="flex justify-between items-center p-1 bg-gray-50 border-b border-separator"
      >
        <div class="px-2">
          <strong>Debug Context</strong>
        </div>
        <button
          @click="state = 'collapsed'"
          type="button"
          class="p-1 border border-separator rounded-md"
          aria-label="Collapse developer context"
        >
          <Icon icon="minus" class="w-4 h-4" />
        </button>
      </div>
      <div class="p-3">
        <div ref="contextContent" class="space-y-2">
          <div
            v-for="item in contextData"
            :key="item.heading"
            class="debug-item"
          >
            <template v-if="!item.type">
              {{ item.heading }}
              <div class="flex gap-1">
                <div v-for="(value, index) in item.value" :key="index">
                  <Copy v-if="value">{{ value }}</Copy>
                </div>
              </div>
            </template>
            <div
              class="m-0 col-span-2 border-b border-separator"
              v-else-if="item.type === 'separator'"
            />
            <div v-else-if="item.type === 'heading'" class="col-span-2 py-1">
              <strong>{{ item.heading }}</strong>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-else>
      <button
        @click="state = 'expanded'"
        class="py-1 px-2 border border-separator rounded-md"
        aria-label="Expand developer context"
      >
        <icon icon="plus" class="w-4 h-4" />
      </button>
    </template>
  </div>
</template>

<style scoped>
  .debug-item {
    display: grid;
    align-items: baseline;
    grid-template-columns: 160px 1fr;
    grid-gap: 2rem;
  }
</style>
