import {getAppUrl} from '@console/helpers/url.js';

import axios from './axios';

export default {
  getCountries() {
    return axios().get(getAppUrl() + '/addresses/countries');
  },

  getInfo({countryCode, administrativeArea, locality}) {
    return axios().post(
      getAppUrl() + '/addresses/info',
      {
        countryCode,
        administrativeArea,
        locality,
      },
      {}
    );
  },
};
