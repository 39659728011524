<script setup lang="ts">
  import {computed, onMounted, ref} from 'vue';
  import {arrow, offset, useFloating} from '@floating-ui/vue';
  import {useWarmup} from '@console/composables/useWarmup';
  import {TransitionRoot} from '@headlessui/vue';

  const props = withDefaults(
    defineProps<{
      open?: boolean;
    }>(),
    {open: false}
  );

  const {withWarmup, resetTimeouts} = useWarmup();

  const trigger = ref<HTMLElement | null>(null);
  const target = ref<HTMLElement | null>(null);
  const floatingArrow = ref<HTMLElement | null>(null);

  const {floatingStyles, middlewareData} = useFloating(trigger, target, {
    placement: 'right',
    strategy: 'fixed',
    middleware: [offset(8), arrow({element: floatingArrow})],
  });

  function showLabel() {
    withWarmup(() => {
      tooltipVisible.value = true;
    });
  }

  function hideLabel() {
    resetTimeouts();
    tooltipVisible.value = false;
  }

  const isOpen = ref(false);
  const tooltipVisible = computed({
    get() {
      return props.open || isOpen.value;
    },
    set(value) {
      isOpen.value = value;
    },
  });

  onMounted(() => {
    document.addEventListener('keyup', (e) => {
      if (e.key === 'Escape') {
        tooltipVisible.value = false;
      }
    });
  });
</script>

<template>
  <div class="tooltip" @mouseover="showLabel" @mouseleave="hideLabel">
    <div ref="trigger">
      <slot name="trigger"></slot>
    </div>

    <transition-root
      :show="tooltipVisible"
      class="relative z-50"
      enter="transition-opacity duration-70"
      enter-from="opacity-0"
      enter-to="opacity-100"
      leave="transition-opacity duration-50"
      leave-from="opacity-100"
      leave-to="opacity-0"
    >
      <div
        ref="target"
        :style="floatingStyles"
        :class="{
          'py-1 px-2 bg-white dark:bg-gray-700 rounded-md border border-gray-300 dark:border-gray-700 shadow': true,
          'pointer-events-auto': tooltipVisible,
          'pointer-events-none': !tooltipVisible,
        }"
      >
        <div
          ref="floatingArrow"
          class="absolute bg-white dark:bg-gray-700 w-2 h-2 rotate-45 -translate-x-1/2 border-b border-l border-gray-300 dark:border-gray-700"
          :style="{
            left:
              middlewareData.arrow?.x != null
                ? `${middlewareData.arrow.x}px`
                : '0',
            top:
              middlewareData.arrow?.y != null
                ? `${middlewareData.arrow.y}px`
                : '0',
          }"
        />
        <slot />
      </div>
    </transition-root>
  </div>
</template>

<style scoped></style>
