<template>
<svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
    <defs>
        <linearGradient id="a" x1="33.51" y1="93.68" x2="20.72" y2="83.7" gradientTransform="matrix(1 0 0 -1 0 112)"
                        gradientUnits="userSpaceOnUse">
            <stop offset=".18" stop-color="#0052cc"/>
            <stop offset="1" stop-color="#2684ff"/>
        </linearGradient>
    </defs>
    <path d="M7.96 8.43c-.51 0-.9.47-.81.94l3.47 21.26c.09.56.56.94 1.11.94h16.8c.39 0 .73-.3.81-.69l3.51-21.47c.09-.51-.3-.94-.81-.94L7.95 8.43Zm14.75 15.35h-5.36l-1.41-7.59h8.1l-1.33 7.59Z"
          style="fill:#2684ff"/>
    <path d="M31.7 16.19h-7.72l-1.29 7.59h-5.36l-6.3 7.5s.3.26.73.26h16.81c.39 0 .73-.3.81-.69l2.31-14.66Z"
          style="fill:url(#a)"/>
</svg>
</template>
      