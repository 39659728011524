<template>
  <div
    role="banner"
    class="main-header"
    :class="{
      'px-6': true,
      'flex justify-between items-center w-full': true,
      'sticky z-30 top-0': true,
      'bg-primary bg-opacity-90 backdrop-blur': true,
      'border-b border-gray-200 dark:border-black': true,
    }"
  >
    <div class="flex flex-1 items-center min-w-[0] min-h-[0]">
      <div class="flex-1 flex min-w-[0] min-h-[0] items-center gap-4">
        <h2 id="primary-nav-label" class="sr-only">Main</h2>
        <nav aria-labelledby="primary-nav-label" class="md:hidden">
          <sidebar-toggle class="-ml-2" />
        </nav>

        <div class="hidden">
          <search-toggle />
        </div>
      </div>

      <ul class="py-2.5 flex items-center space-x-2">
        <li>
          <router-link
            :to="getPrefixedTo('/settings')"
            :class="{
              'cart-button relative block header-toggle px-3 py-2 rounded-md leading-none': true,
              'hover:bg-black dark:hover:bg-white hover:bg-opacity-5 dark:hover:bg-opacity-5': true,
            }"
            aria-label="Settings"
          >
            <Icon icon="cog" set="solid" class="w-5 h-5 text-gray-500" />
          </router-link>
        </li>
        <li class="block cart-menu">
          <cart-button context="app" />
        </li>
      </ul>

      <AccountSwitcher />
    </div>
  </div>
</template>

<script>
  import {mapGetters, mapState} from 'vuex';
  import SidebarToggle from '@console/components/app/SidebarToggle.vue';
  import SearchToggle from '@console/components/search/SearchToggle.vue';
  import CartButton from '@console/components/app/CartButton.vue';
  import AccountSwitcher from '@console/components/app/AccountSwitcher.vue';
  import {getPrefixedTo} from '@console/helpers/router';
  import Icon from '@/common/ui/components/Icon.vue';

  export default {
    components: {
      Icon,
      AccountSwitcher,
      CartButton,
      SearchToggle,
      SidebarToggle,
    },

    computed: {
      ...mapState({
        user: (state) => state.account.user,
      }),

      ...mapGetters({
        userIsInGroup: 'account/userIsInGroup',
        currentOrg: 'orgs/currentOrg',
        activeContext: 'context/active',
      }),
    },

    methods: {
      getPrefixedTo,
    },
  };
</script>

<style lang="pcss">
  .top-nav {
    ul {
      li {
        a {
          @apply no-underline text-gray-800 dark:text-gray-200 px-3 py-2 rounded-md;

          &.active,
          &:hover {
            @apply bg-gray-200 dark:bg-gray-800 text-gray-800 dark:text-gray-200;
          }
        }
      }
    }
  }

  .main-header {
    .header-right {
      & > ul > li > a {
        @apply text-gray-800 dark:text-gray-200;

        &:hover {
          @apply text-gray-800 dark:text-gray-200;
        }
      }
    }

    /* User menu */

    .user-menu {
      @apply relative;

      &.has-photo {
        @apply ml-2;
      }

      .header-toggle {
        &:hover {
          @apply cursor-pointer;
        }

        img {
          @apply rounded-full w-8 h-8;
        }
      }

      .popover {
        top: 38px;
        right: 0;

        .popover-arrow {
          right: 5px;
        }
      }
    }

    /* Popover */
    .popover {
      @apply bg-primary;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 20;

      .popover-arrow {
        width: 50px;
        height: 16px;
        position: absolute;
        top: -16px;
        right: 5px;
        overflow: hidden;

        &::after {
          @apply bg-primary;

          content: "";
          position: absolute;
          width: 16px;
          height: 16px;
          transform: translateX(-50%) translateY(-50%) rotate(45deg);
          top: 100%;
          left: 50%;
          box-shadow: 1px 1px 5px 0px var(--craftui-shadow-3);
        }
      }
    }
  }
</style>
