import axios from './axios';
import FormDataHelper from '@console/helpers/form-data.js';
import qs from 'qs';
import {getAppUrl} from '@console/helpers/url.js';

export default {
  loadDetails(repositoryUrl) {
    let body = {
      repository: repositoryUrl,
    };

    let query = qs.stringify(body);

    return axios().get(getAppUrl() + '/plugins/details?' + query);
  },

  save({plugin}) {
    const formData = new FormData();

    for (const attribute in plugin) {
      if (plugin[attribute] !== null && plugin[attribute] !== undefined) {
        switch (attribute) {
          case 'iconId':
          case 'categoryIds':
          case 'screenshots':
          case 'screenshotUrls':
          case 'screenshotIds':
            for (let i = 0; i < plugin[attribute].length; i++) {
              FormDataHelper.append(
                formData,
                attribute + '[]',
                plugin[attribute][i]
              );
            }
            break;

          case 'editions':
            for (let i = 0; i < plugin[attribute].length; i++) {
              const edition = plugin[attribute][i];
              const editionKey = edition.id ? edition.id : 'new';

              FormDataHelper.append(
                formData,
                'editions[' + editionKey + '][basePrice]',
                edition.basePrice
              );
              FormDataHelper.append(
                formData,
                'editions[' + editionKey + '][renewalPrice]',
                edition.renewalPrice
              );

              for (let j = 0; j < edition.features.length; j++) {
                const feature = edition.features[j];
                FormDataHelper.append(
                  formData,
                  'editions[' + editionKey + '][features][' + j + '][name]',
                  feature.name
                );
                FormDataHelper.append(
                  formData,
                  'editions[' +
                    editionKey +
                    '][features][' +
                    j +
                    '][description]',
                  feature.description
                );
              }
            }
            break;

          case 'abandoned':
            FormDataHelper.append(
              formData,
              attribute,
              plugin.abandoned ? 1 : 0
            );
            break;

          case 'supportsGql':
            FormDataHelper.append(
              formData,
              attribute,
              plugin.supportsGql ? 1 : 0
            );
            break;

          case 'cloudTested':
            FormDataHelper.append(
              formData,
              attribute,
              plugin.cloudTested ? 1 : 0
            );
            break;

          default:
            FormDataHelper.append(formData, attribute, plugin[attribute]);
        }
      }
    }

    return axios().post(
      getAppUrl() + '/plugins' + (plugin.pluginId ? '/' + plugin.pluginId : ''),
      formData
    );
  },

  submit(pluginId) {
    return axios().post(getAppUrl() + '/plugins/' + pluginId + '/submit');
  },

  getPlugins(params) {
    return axios().get(getAppUrl() + '/plugins', {params});
  },
};
