import {baseUrlAuth, refreshTokenIfNeeded} from '@console/api/cloud';
import {getAppUrl} from '@console/helpers/url';
import {openPopup} from '@console/helpers/openPopup';

const getSettingsRoutes = (type) => {
  return [
    {
      path: `/accounts/${type === 'org' ? ':orgSlug' : 'me'}/settings`,
      name: type + '.settings',
      component: () => import('@console/pages/settings/index.vue'),
      children: [
        // User Settings
        ...(type === 'user'
          ? [
              {
                path: 'orgs',

                children: [
                  {
                    path: '',
                    name: 'user.settings.orgs',
                    component: () =>
                      import('@console/pages/settings/orgs/index.vue'),
                    meta: {userOnly: true, title: 'Organizations'},
                  },
                  {
                    path: 'new',
                    name: 'user.settings.orgs.new',
                    component: () =>
                      import('@console/pages/settings/orgs/new.vue'),
                    meta: {userOnly: true, title: 'New Organization'},
                  },
                ],
              },
              {
                path: 'integrations',
                name: 'user.settings.integrations',
                component: () =>
                  import('@console/pages/settings/integrations/index.vue'),
                meta: {userOnly: true, title: 'Integrations'},
              },
              {
                path: 'developer-support',
                name: 'user.settings.developerSupport',
                component: () =>
                  import('@console/pages/settings/developer-support/index.vue'),
                meta: {userOnly: true, title: 'Developer Support'},
              },
              {
                path: 'notifications',
                name: 'user.settings.notifications',
                component: () =>
                  import('@console/pages/settings/notifications/index.vue'),
                meta: {
                  title: 'Notifications',
                  userOnly: true,
                },
              },
              {
                path: 'orgs/join',
                name: 'user.settings.orgs.join',
                component: () =>
                  import('@console/pages/settings/orgs/join.vue'),
                meta: {
                  title: 'Join Organization',
                  layout: 'site',
                  centerContent: true,
                  mainFull: true,
                  allowAnonymous: true,
                },
              },
            ]
          : []),

        // Org Settings
        ...(type === 'org'
          ? [
              {
                path: 'members',
                name: 'org.settings.members',
                component: () => import('@console/pages/settings/members.vue'),
                meta: {orgOnly: true},
              },
              {
                path: 'plugin-store',
                name: 'org.settings.pluginStore',
                component: () =>
                  import('@console/pages/settings/plugin-store/index.vue'),
                meta: {orgOnly: true, title: 'Plugin Store'},
              },
              {
                path: 'partner',
                name: 'org.settings.partner',
                component: () =>
                  import('@console/pages/settings/partner/index.vue'),
                meta: {orgOnly: true, title: 'Partner Network'},
              },
            ]
          : []),

        // User + Org Settings
        {
          path: 'profile',
          name: type + '.settings.profile',
          component: () => import('@console/pages/settings/profile/index.vue'),
          meta: {title: 'Profile'},
        },
        {
          path: 'orders',
          children: [
            {
              path: '',
              name: type + '.settings.orders',
              component: () =>
                import('@console/pages/settings/orders/index.vue'),
              meta: {title: 'Orders'},
            },
            {
              path: ':number',
              name: type + '.settings.orders.number',
              component: () =>
                import('@console/pages/settings/orders/_number.vue'),
            },
          ],
        },
        {
          path: 'billing',
          name: type + '.settings.billing',
          component: () => import('@console/pages/settings/billing/index.vue'),
          meta: {title: 'Billing'},
        },
      ],
    },
  ];
};

const routes = [
  ...getSettingsRoutes('org'),
  ...getSettingsRoutes('user'),

  {
    path: '/auth/:provider/redirect',
    name: 'auth.provider.redirect',
    beforeEnter: async (to) => {
      const token = await refreshTokenIfNeeded();
      const callback = new URL(`/auth/${to.params.provider}/done`, getAppUrl());

      const url = new URL(to.params.provider, baseUrlAuth);
      url.searchParams.append('token', token);
      url.searchParams.append('redirect_to', callback.href);
      openPopup(url.href);
      return false;
    },
  },
  {
    path: '/auth/:provider/done',
    name: 'auth.provider.done',
    beforeEnter: async () => {
      window.close();
      return false;
    },
  },

  // Handle old routes
  {
    path: '/settings:afterRoute(.*)',
    redirect: (to) => `/accounts/me/settings${to.params.afterRoute}`,
  },
];
export default routes;
