<template>
  <TransitionChild
    as="template"
    enter="duration-300 ease-out"
    enter-from="opacity-0"
    enter-to="opacity-100"
    leave="duration-200 ease-in"
    leave-from="opacity-100"
    leave-to="opacity-0"
  >
    <DialogOverlay
      class="fixed inset-0 bg-gray-200 dark:bg-black bg-opacity-80 dark:bg-opacity-80 backdrop-blur"
    />
  </TransitionChild>
</template>
<script>
  import {DialogOverlay, TransitionChild} from '@headlessui/vue';

  export default {
    components: {DialogOverlay, TransitionChild},
  };
</script>
