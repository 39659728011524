import {type RouteLocationRaw, useRoute} from 'vue-router';
import {getPrefixedTo} from '@console/helpers/router';
import {type Component, computed} from 'vue';
import {useStore} from 'vuex';
import type {Member, Org} from '@/console';
import type {Context} from '@console/store/modules/context';
import {useGetProject} from '@console/queries/projects';
import {Ability, useAbility} from '@console/composables/useAbility';
import {
  type EnvironmentStatus,
  useGetProjectActivity,
} from '@console/queries/project-activity';
import Ping from '@console/components/Ping.vue';
import ViewEnvironment from '@console/components/cloud/project/environments/ViewEnvironment.vue';
import {ProjectStatus} from '@/types/ProjectStatus';
import NewBadge from '@console/components/NewBadge.vue';

interface SubComponent {
  component: string | Component;
  props: {
    [key: string]: any;
  };
}

export type ActionListItemBase = {
  hidden?: boolean | null | (() => boolean | null);
  visible?: boolean | null | (() => boolean | null);
  isActive?: boolean;
  action?: SubComponent | null;
};

export interface ActionListItemSeparator extends ActionListItemBase {
  type: 'separator';
}

export interface ActionListItemLink extends ActionListItemBase {
  type?: 'link';
  label: string;
  icon?: string;
  exact?: boolean;
  iconProps?: {
    [key: string]: any;
  };
  to: RouteLocationRaw;
  isProduction?: boolean;
  isActive?: boolean;
  children?: ActionListItem[];
  collapsible?: boolean;
  prefix?: SubComponent | null;
  suffix?: SubComponent | null;
}

export interface ActionListItemGroup extends ActionListItemBase {
  type: 'group';
  label: string;
  suffix?: SubComponent | null;
  prefix?: SubComponent | null;
  collapsible?: boolean;
  children: ActionListItem[];
}

export type ActionListItem =
  | ActionListItemSeparator
  | ActionListItemLink
  | ActionListItemGroup;

function isVisible(item: ActionListItem) {
  if (Object.prototype.hasOwnProperty.call(item, 'visible')) {
    return item.visible;
  }

  if (Object.prototype.hasOwnProperty.call(item, 'hidden')) {
    return !item.hidden;
  }

  return true;
}

export const useNavigation = () => {
  const store = useStore();

  const currentMember = computed<Member | null>(
    () => store.state.orgs.currentMember
  );
  const activeContext = computed<Context | null>(
    () => store.getters['context/active']
  );
  const currentOrg = computed<Org | null>(
    () => store.getters['orgs/currentOrg']
  );
  const canAccessDeveloperPlugins = computed<Boolean>(
    () => store.getters['orgs/canAccessDeveloperPlugins']
  );

  const items = computed(() => {
    return [
      {
        type: 'group',
        label: 'Craft Cloud',
        suffix: {
          component: NewBadge,
        },
        children: [
          {
            label: 'Projects',
            icon: 'cloud',
            to: getPrefixedTo('/cloud/projects'),
          },
        ],
      },
      {
        type: 'group',
        label: 'Licenses',
        children: [
          {
            label: 'Craft CMS',
            to: getPrefixedTo('/licenses/cms'),
            icon: 'key',
          },
          {
            label: 'Plugins',
            to: getPrefixedTo('/licenses/plugins'),
            icon: 'key',
          },
          {
            label: 'Claim License',
            to: getPrefixedTo('/licenses/claim'),
            icon: 'key',
          },
        ],
      },

      {
        type: 'group',
        label: 'Plugin Store',
        visible: currentOrg.value,
        children: [
          {
            label: 'Sales',
            to: getPrefixedTo('/plugin-store/sales'),
            icon: 'chart-square-bar',
          },
          {
            label: 'Plugins',
            to: getPrefixedTo('/plugin-store/plugins'),
            visible: canAccessDeveloperPlugins.value,
            icon: 'plug',
          },
        ],
      },
      {
        type: 'group',
        label: 'Organization Settings',
        visible: currentOrg.value,
        children: [
          {
            label: 'Profile',
            to: getPrefixedTo('/settings/profile'),
            visible: currentMember.value?.canManageProfile,
            icon: 'identification',
          },
          {
            label: 'Members',
            to: getPrefixedTo('/settings/members'),
            visible: currentMember.value?.canManageMembers,
            icon: 'user-group',
          },
          {
            label: 'Orders',
            to: getPrefixedTo('/settings/orders'),
            icon: 'file-invoice-dollar',
          },
          {
            label: 'Billing',
            to: getPrefixedTo('/settings/billing'),
            icon: 'credit-card',
            visible:
              currentMember.value && currentMember.value.canManageBilling,
          },
          {
            label: 'Partner Network',
            to: getPrefixedTo('/settings/partner'),
            icon: 'check-circle',
            visible:
              currentMember.value &&
              currentMember.value.canManagePartnerProfile &&
              currentOrg.value &&
              currentOrg.value.enablePartnerFields,
          },
          {
            label: 'Plugin Store',
            to: getPrefixedTo('/settings/plugin-store'),
            icon: 'plug',
            visible: currentMember.value?.canManagePluginStore,
          },
        ],
      },
      {
        type: 'group',
        label: 'User Settings',
        visible: !currentOrg.value,
        children: [
          {
            label: 'Profile',
            to: getPrefixedTo('/settings/profile'),
            icon: 'identification',
          },
          {
            label: 'Orders',
            to: getPrefixedTo('/settings/orders'),
            icon: 'file-invoice-dollar',
          },
          {
            label: 'Billing',
            to: getPrefixedTo('/settings/billing'),
            icon: 'credit-card',
          },
          {
            label: 'Organizations',
            to: '/accounts/me/settings/orgs',
            icon: 'buildings',
          },
          {
            label: 'Developer Support',
            to: '/accounts/me/settings/developer-support',
            icon: 'support',
          },
          {
            label: 'Integrations',
            to: '/accounts/me/settings/integrations',
            icon: 'box',
          },
        ],
      },
    ] as ActionListItem[];
  });

  const visibleItems = computed(() =>
    items.value.reduce((acc: ActionListItem[], item) => {
      if (Object.prototype.hasOwnProperty.call(item, 'children')) {
        // @ts-ignore
        item.children = item.children.filter(isVisible);
      }

      if (isVisible(item)) {
        acc.push(item);
      }

      return acc;
    }, [])
  );

  return {items, visibleItems};
};

export const useCloudNavigation = () => {
  const route = useRoute();
  const {environmentId, projectKey} = route.params;
  const {project} = useGetProject();
  const {data: projectActivity} = useGetProjectActivity();
  const {can} = useAbility();

  const items = computed(() => {
    const status = (projectActivity.value || []).find(
      (e: EnvironmentStatus) => e.id === environmentId
    );

    return [
      {
        type: 'group',
        label: 'Environments',
        children: [
          ...((project.value?.environments || [])
            .map((environment) => ({
              label: environment.name,
              icon: environment.is_production ? 'crown' : 'branch',
              iconProps: environment.is_production
                ? {
                    class: 'text-orange-500',
                  }
                : {},
              isProduction: environment.is_production,
              isActive: environmentId === environment.id,
              to: getPrefixedTo(
                `/cloud/projects/${projectKey}/environments/${environment.id}`
              ),
              collapsible: true,
              action: {
                component: ViewEnvironment,
                props: {
                  environmentId: environment.id,
                  previewDomain: environment.preview_domain,
                  showLabel: false,
                },
              },
              children: [
                {
                  label: 'Deployments',
                  to: getPrefixedTo(
                    `/cloud/projects/${route.params.projectKey}/environments/${environment.id}/deployments`
                  ),
                  suffix: status?.deployments.length
                    ? {
                        component: Ping,
                        props: {
                          'aria-label': `Deployments are running`,
                        },
                      }
                    : null,
                },
                {
                  label: 'Logs',
                  to: getPrefixedTo(
                    `/cloud/projects/${route.params.projectKey}/environments/${environment.id}/logs`
                  ),
                },
                {
                  label: 'Backups',
                  to: getPrefixedTo(
                    `/cloud/projects/${route.params.projectKey}/environments/${environment.id}/backups`
                  ),
                  suffix: status?.backups.length
                    ? {
                        component: Ping,
                        props: {
                          'aria-label': `Backups are running`,
                        },
                      }
                    : null,
                },
                {
                  label: 'Variables',
                  to: getPrefixedTo(
                    `/cloud/projects/${route.params.projectKey}/environments/${environment.id}/variables`
                  ),
                },
                {
                  label: 'Commands',
                  to: getPrefixedTo(
                    `/cloud/projects/${route.params.projectKey}/environments/${environment.id}/commands`
                  ),
                  suffix: status?.commands.length
                    ? {
                        component: Ping,
                        props: {
                          'aria-label': `Commands are running`,
                        },
                      }
                    : null,
                },
                {
                  label: 'Access',
                  to: getPrefixedTo(
                    `/cloud/projects/${route.params.projectKey}/environments/${environment.id}/access`
                  ),
                },
                {
                  label: 'Settings',
                  to: getPrefixedTo(
                    `/cloud/projects/${route.params.projectKey}/environments/${environment.id}/settings`
                  ),
                },
              ],
            }))
            .sort((a) => (a.isProduction ? -1 : 1)) || []),
        ],
      },
      {
        label: 'New Environment',
        icon: 'plus',
        visible:
          project.value?.status &&
          [ProjectStatus.Active, ProjectStatus.Trialing].includes(
            project.value?.status.handle
          ) &&
          can(Ability.ManageEnvironments) &&
          project.value?.can.create_environments,
        to: getPrefixedTo(`/cloud/projects/${projectKey}/environments/new`),
      },
      {
        type: 'separator',
      },
      {
        label: 'Domains',
        icon: 'earth-americas',
        to: getPrefixedTo(`/cloud/projects/${projectKey}/domains`),
      },
      {
        label: 'Billing',
        icon: 'credit-card',
        visible: project.value?.plan && can(Ability.ManageProjects),
        to: getPrefixedTo(`/cloud/projects/${projectKey}/billing`),
      },
      {
        label: 'Settings',
        icon: 'cog',
        iconProps: {set: 'solid', class: 'w-5 h-5'},
        to: getPrefixedTo(`/cloud/projects/${projectKey}/settings`),
      },
    ] as ActionListItem[];
  });

  const visibleItems = computed(() =>
    items.value.reduce((acc: ActionListItem[], item) => {
      if (Object.prototype.hasOwnProperty.call(item, 'children')) {
        // @ts-ignore
        item.children = item.children.filter(isVisible);
      }

      if (isVisible(item)) {
        acc.push(item);
      }

      return acc;
    }, [])
  );

  return {items, visibleItems};
};
