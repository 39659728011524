import ArrowLeft from './ArrowLeft.vue'
import Bitbucket from './Bitbucket.vue'
import Building from './Building.vue'
import Cog from './Cog.vue'
import Crown from './Crown.vue'
import EarthAmericas from './EarthAmericas.vue'
import Exclamation from './Exclamation.vue'
import Github from './Github.vue'
import Gitlab from './Gitlab.vue'
import GripDotsVertical from './GripDotsVertical.vue'
import GripVertical from './GripVertical.vue'
import ShoppingCart from './ShoppingCart.vue'
import User from './User.vue'

export default {
    'arrow-left': ArrowLeft,
    'bitbucket': Bitbucket,
    'building': Building,
    'cog': Cog,
    'crown': Crown,
    'earth-americas': EarthAmericas,
    'exclamation': Exclamation,
    'github': Github,
    'gitlab': Gitlab,
    'grip-dots-vertical': GripDotsVertical,
    'grip-vertical': GripVertical,
    'shopping-cart': ShoppingCart,
    'user': User,
}