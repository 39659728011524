import axios from './axios';
import FormDataHelper from '@console/helpers/form-data.js';
import {getAppUrl} from '@console/helpers/url.js';

export default {
  uploadUserPhoto(data) {
    const formData = new FormData();

    for (const attribute in data) {
      FormDataHelper.append(formData, attribute, data[attribute]);
    }

    return axios().post(getAppUrl() + '/users/me/photo', formData);
  },

  deleteUserPhoto() {
    return axios().delete(getAppUrl() + '/users/me/photo');
  },

  getAccount() {
    return axios().get(getAppUrl() + '/users/me');
  },

  dismissCraftIdMessage() {
    return axios().post(getAppUrl() + '/users/me/dismiss-craft-id-message');
  },

  generateApiToken() {
    return axios().post(getAppUrl() + '/users/me/api-token');
  },

  sendPasswordResetEmail(formData) {
    return axios().post(
      getAppUrl() + '/users/me/send-password-reset-email',
      formData
    );
  },

  deactivateUser() {
    return axios().post(getAppUrl() + '/users/me/deactivate');
  },

  saveUser(user) {
    const formData = new FormData();

    for (const attribute in user) {
      switch (attribute) {
        case 'email':
        case 'username':
        case 'firstName':
        case 'lastName':
        case 'fullName':
        case 'password':
        case 'newPassword':
        case 'photo':
          FormDataHelper.append(formData, attribute, user[attribute]);
          break;
        default:
          FormDataHelper.append(
            formData,
            'fields[' + attribute + ']',
            user[attribute]
          );
      }
    }

    return axios().post(getAppUrl() + '/users/me', formData);
  },
};
