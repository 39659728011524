<template>
  <div id="notifications-wrapper" :class="{hide: !message}">
    <div id="notifications">
      <div class="notification" :class="type">
        {{ message }}
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      type: {
        type: String,
        default: 'alert',
      },
      message: {
        type: String,
        default: null,
      },
    },
  };
</script>
