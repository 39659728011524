import {computed} from 'vue';
import {useGetProject} from '@console/queries/projects';
import {useStore} from 'vuex';
import type {Member} from '@/console';

export enum Ability {
  CreateProjects = 'create projects',
  ManageDomains = 'manage domains',
  ManageEnvironments = 'manage environments',
  ManageProjects = 'manage projects',
  DeleteProject = 'delete project',
  CancelProject = 'cancel project',
}

// Everything but deleting a project (project must be cancelled first)
const elevatedActions = [
  Ability.CreateProjects,
  Ability.ManageProjects,
  Ability.CancelProject,
  Ability.DeleteProject,
  Ability.ManageDomains,
  Ability.ManageEnvironments,
];

function getPermissions(currentMember?: Member | null, projectStatus?: string) {
  // If there's no role (user context) or the user is an owner or admin ...
  if (
    !currentMember ||
    (currentMember && currentMember.canManageCloudProjects)
  ) {
    // If the project is past_due, don't allow anything.
    // Resuming the project at this point is a billing issue and
    // falls under the responsibility of `currentMember.canManageBilling`
    if (projectStatus === 'past_due') {
      return [];
    }

    return elevatedActions;
  }

  // Otherwise lock them down
  return [];
}

export const useAbility = () => {
  const store = useStore();
  const {project} = useGetProject();

  const currentMember = computed(() => store.state.orgs.currentMember);
  const projectStatus = computed(() => project.value?.status.handle);
  const permissions = computed(() =>
    getPermissions(currentMember.value, projectStatus.value)
  );

  const can = (permission: Ability) => {
    return permissions.value.includes(permission);
  };

  return {can};
};
