<template>
  <field-row :id="fieldData.fieldId" :layout="layout" :align-items="alignItems">
    <template #label>
      <field-label
        :label="label"
        v-if="label"
        :required="required"
        :id="fieldData.labelId"
        :for="fieldData.inputId"
        :class="{
          'sr-only': hideLabel,
          'text-error': errors && errors.length > 0,
          '!text-red-500': danger,
        }"
      >
        <slot name="label"
          >{{ label }}<span v-if="required" aria-hidden="true">*</span>
          <span v-if="required" class="sr-only">(required)</span>
        </slot>
      </field-label>

      <div
        v-if="instructions && instructionsPosition === 'above'"
        class="instructions text-sm text-light"
        :id="fieldData.instructionsId"
        v-html="instructions"
      ></div>
    </template>

    <template #default>
      <slot
        :field="fieldData"
        :input-props="{
          id: fieldData.inputId,
          name: name,
          required: required,
          invalid: errors && errors.length > 0,
          disabled: disabled,
          'described-by': inputDescribedBy,
        }"
      />
      <div
        v-if="instructions && instructionsPosition === 'below'"
        class="instructions text-sm text-light mt-1"
        :id="fieldData.instructionsId"
        v-html="instructions"
      ></div>
      <error-list
        message="Some kind of message"
        :errors="errors"
        :id="fieldData.errorId"
      />
    </template>
  </field-row>
</template>

<script setup lang="ts">
  import ErrorList from '@/common/ui/components/ErrorList.vue';
  import FieldLabel from '@/common/ui/components/FieldLabel.vue';
  import FieldRow from '@/common/ui/components/FieldRow.vue';
  import {computed} from 'vue';
  import {useField} from '@console/composables/useField';
  import slugify from 'slugify';

  const props = withDefaults(
    defineProps<{
      errors?: string[];
      id?: string;
      name?: string;
      instructions?: string;
      label?: string;
      required?: boolean;
      hideLabel?: boolean;
      layout?: 'horizontal' | 'vertical';
      alignItems?: 'center' | 'start' | 'end' | 'baseline';
      disabled?: boolean;
      danger?: boolean;
      instructionsPosition?: 'above' | 'below';
    }>(),
    {
      name: undefined,
      id: undefined,
      instructions: undefined,
      label: undefined,
      errors: () => [],
      required: false,
      hideLabel: false,
      layout: 'horizontal',
      alignItems: 'baseline',
      disabled: false,
      danger: false,
      instructionsPosition: 'above',
    }
  );

  const id = computed(() => {
    if (props.id) {
      return props.id;
    }

    if (props.name) {
      return slugify(props.name);
    }

    return `${Math.random().toString(36).substring(2, 9)}`;
  });

  const fieldData = useField(id.value);

  const inputDescribedBy = computed(() => {
    const describedBy = [];

    if (props.errors && props.errors.length > 0) {
      describedBy.push(fieldData.errorId);
    }

    if (props.instructions) {
      describedBy.push(fieldData.instructionsId);
    }

    return describedBy;
  });
</script>
