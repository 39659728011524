import pluginStoreApi from '@console/api/plugin-store';

/**
 * State
 */
const state = {
  categories: [],
  plugins: [],
  metaLoaded: false,
  metaLoading: false,
  expiryDateOptions: [],
};

/**
 * Getters
 */
const getters = {};

/**
 * Actions
 */
const actions = {
  getCoreData({commit, state}) {
    if (state.metaLoading || state.metaLoaded) {
      return;
    }

    commit('setLoading', true);
    return pluginStoreApi.getCoreData().then((response) => {
      commit('updatePluginStoreMeta', {response});
      commit('setLoading', false);
    });
  },

  getPlugins({commit, state}, requestedPluginIds) {
    return new Promise((resolve, reject) => {
      const pluginIds = [];

      requestedPluginIds.forEach((pluginId) => {
        const plugin = state.plugins.find((plugin) => plugin.id === pluginId);

        if (!plugin) {
          pluginIds.push(pluginId);
        }
      });

      pluginStoreApi
        .getPlugins(requestedPluginIds)
        .then((response) => {
          commit('updatedPlugins', {response});
          resolve();
        })
        .catch((response) => {
          reject(response);
        });
    });
  },
};

/**
 * Mutations
 */
const mutations = {
  setLoading(state, value) {
    state.metaLoading = value;
  },

  updatePluginStoreMeta(state, {response}) {
    state.categories = response.data.categories;
    state.expiryDateOptions = response.data.expiryDateOptions;
    state.metaLoaded = true;
  },

  updatedPlugins(state, {response}) {
    const responsePlugins = response.data;

    responsePlugins.forEach((responsePlugin) => {
      const alreadyInState = state.plugins.find(
        (plugin) => plugin.id === responsePlugin.id
      );

      if (!alreadyInState) {
        state.plugins.push(responsePlugin);
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
