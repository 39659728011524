import axios from './axios';
import {getAppUrl} from '@console/helpers/url.js';

export default {
  getAccount(orgId) {
    return axios().get(getAppUrl() + '/apps/stripe', {
      params: {
        orgId,
      },
    });
  },

  disconnect(orgId) {
    return axios().delete(getAppUrl() + '/apps/stripe', {
      params: {orgId},
    });
  },
};
