import pluginLicensesApi from '@console/api/plugin-licenses';

/**
 * State
 */
const state = {
  expiringPluginLicensesTotal: 0,
};

/**
 * Getters
 */
const getters = {};

/**
 * Actions
 */
const actions = {
  getExpiringPluginLicensesTotal({commit}) {
    return new Promise((resolve, reject) => {
      pluginLicensesApi
        .getExpiringPluginLicensesTotal()
        .then((response) => {
          if (typeof response.data !== 'undefined' && !response.data.error) {
            commit('updateExpiringPluginLicensesTotal', response.data);
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((response) => {
          reject(response);
        });
    });
  },

  transferPluginLicense(context, {id, newOwnerId}) {
    return pluginLicensesApi.transferPluginLicense({id, newOwnerId});
  },
};

/**
 * Mutations
 */
const mutations = {
  updateExpiringPluginLicensesTotal(state, total) {
    state.expiringPluginLicensesTotal = parseInt(total);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
