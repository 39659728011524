<template>
  <component
    :is="componentType"
    :href="href"
    :class="[
      {
        'block w-full rounded my-1 px-3 py-2 leading-5': true,
        'no-underline text-left text-sm text-gray-800 dark:text-gray-200 hover:text-gray-800 dark:hover:text-gray-200 hover:no-underline': true,
        'border border-transparent hover:border-interactive-nav-active-background': true,
        'bg-gray-100 dark:bg-gray-700 ring-2 ring-blue-500 hover:ring-0':
          active,
      },
    ]"
  >
    <slot />
  </component>
</template>

<script setup lang="ts">
  import {type Component, computed} from 'vue';

  const props = withDefaults(
    defineProps<{
      is?: Component | string;
      active?: boolean;
      href?: string | null;
    }>(),
    {
      is: undefined,
      active: false,
      href: null,
    }
  );

  const componentType = computed(() => {
    if (props.is) {
      return props.is;
    }

    return props.href ? 'a' : 'button';
  });
</script>
