<template>
  <TransitionRoot
    appear
    :show="isOpen"
    as="template"
    @after-leave="$emit('after-leave')"
  >
    <Dialog as="div">
      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="min-h-screen px-4 text-center">
          <DialogOverlay class="fixed inset-0" />

          <span class="inline-block h-screen align-middle" aria-hidden="true"
            >&#8203;</span
          >

          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
            @after-enter="onAfterEnter"
          >
            <div
              class="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-primary shadow-xl rounded-2xl"
            >
              <div>
                <div class="flex items-center">
                  <icon
                    icon="exclamation"
                    class="w-12 h-12 text-gray-400 mr-2"
                  />
                  <div>
                    Your session will expire in
                    {{ humanizedRemainingSessionTime }}.
                  </div>
                </div>

                <div
                  class="mt-6 space-x-reverse space-x-2 flex flex-row-reverse justify-start"
                >
                  <btn
                    ref="renewSessionBtn"
                    kind="primary"
                    @click="$emit('renewSession')"
                  >
                    Keep me logged in
                  </btn>
                  <btn @click="logout"> Sign out now </btn>
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
  import usersApi from '@console/api/users.js';
  import IsMobileBrowser from '@console/components/IsMobileBrowser.js';
  import humanizeDuration from 'humanize-duration';
  import {
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
  } from '@headlessui/vue';

  export default {
    components: {
      TransitionRoot,
      TransitionChild,
      Dialog,
      DialogOverlay,
    },
    mixins: [IsMobileBrowser],

    props: {
      isOpen: {
        type: Boolean,
        default: false,
      },

      remainingSessionTime: {
        type: Number,
        default: 0,
      },
    },
    emits: ['after-leave', 'renewSession'],

    computed: {
      humanizedRemainingSessionTime() {
        return humanizeDuration(this.remainingSessionTime * 1000);
      },
    },

    methods: {
      /**
       * Logout.
       */
      logout() {
        usersApi.logout().then(() => {
          document.location.href = '';
        });
      },

      /**
       * On after enter logout warning modal.
       */
      onAfterEnter() {
        if (!this.isMobileBrowser(true)) {
          this.$refs.renewSessionBtn.$el.focus();
        }
      },
    },
  };
</script>
