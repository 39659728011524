import axios from './axios';
import {getAppUrl} from '@console/helpers/url.js';

export default {
  getRemainingSessionTime(config) {
    return axios().get(getAppUrl() + '/session', config);
  },

  login(formData) {
    return axios().post(getAppUrl() + '/session', formData);
  },

  logout() {
    localStorage.removeItem('cloudToken');
    return axios().delete(getAppUrl() + '/session');
  },

  registerUser(formData) {
    return axios().post(getAppUrl() + '/users', formData);
  },
};
