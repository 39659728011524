<template>
  <div>
    <div>
      <div class="w-full max-w-screen-lg m-auto p-4 md:p-8">
        <template
          v-if="
            $route.name !== 'UserThankYou' &&
            $route.name !== 'OrgThankYou' &&
            $route.name !== 'UserApprovalRequested' &&
            $route.name !== 'OrgApprovalRequested'
          "
        >
          <template v-if="$route.query.pendingOrderNumber">
            <BackLink
              :to="
                getPrefixedTo(
                  '/settings/orders/' + $route.query.pendingOrderNumber
                )
              "
            >
              Back to approval request
            </BackLink>
          </template>
          <template v-else>
            <BackLink
              :to="
                !user || orgs.length === 0 ? '/cart' : getPrefixedTo('/cart')
              "
            >
              Back to cart
            </BackLink>
          </template>
        </template>
      </div>
    </div>
    <div class="w-full max-w-screen-lg m-auto p-4 md:px-8 md:pt-8 md:pb-20">
      <router-view :key="$route.path" />
    </div>
  </div>
</template>

<script>
  import {mapState} from 'vuex';
  import {getPrefixedTo} from '@console/helpers/router';
  import BackLink from '@/common/ui/components/BackLink.vue';

  export default {
    methods: {getPrefixedTo},

    components: {
      BackLink,
    },

    computed: {
      ...mapState({
        user: (state) => state.account.user,
        orgs: (state) => state.orgs.orgs,
      }),
    },
  };
</script>
