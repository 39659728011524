import {onMounted, ref} from 'vue';

export type SidebarState = 'expanded' | 'collapsed' | 'hidden';

const mql = window.matchMedia('(min-width: 768px)');

function getInitialState() {
  if (mql.matches) {
    return (localStorage.getItem('sidebarState') as SidebarState) || 'expanded';
  }

  return 'hidden';
}

const sidebarState = ref<SidebarState>(getInitialState());

export const useSidebarStore = () => {
  function set(state: SidebarState) {
    sidebarState.value = state;
    localStorage.setItem('sidebarState', state);
  }

  function toggle() {
    sidebarState.value === 'expanded' ? set('collapsed') : set('expanded');
  }

  function show() {
    sidebarState.value = 'expanded';
  }

  function hide() {
    sidebarState.value = 'hidden';
  }

  function toggleVisibility() {
    sidebarState.value === 'expanded' ? hide() : show();
  }

  onMounted(() => {
    mql.addEventListener('change', (evt) => {
      if (evt.matches) {
        show();
      } else {
        hide();
      }
    });
  });

  return {sidebarState, set, toggle, show, hide, toggleVisibility};
};
