import {h} from 'vue';
import {RouterView} from 'vue-router';

const getLicensesRoutes = (type) => {
  return [
    {
      path: `/accounts/${type === 'org' ? ':orgSlug' : 'me'}/licenses`,
      redirect: (to) => {
        return `/accounts/${
          type === 'org' ? `${to.params.orgSlug}` : 'me'
        }/licenses/cms`;
      },

      children: [
        {
          path: 'cms',
          children: [
            {
              path: '',
              name: type + '.licenses.cms',
              component: () => import('@console/pages/licenses/cms/index.vue'),
              meta: {
                cmsLicensesRenewAlert: true,
                title: 'Craft CMS Licenses',
              },
            },
            {
              path: ':id',
              name: type + '.licenses.cms.id',
              component: () => import('@console/pages/licenses/cms/_id.vue'),
            },
          ],
        },
        {
          path: 'plugins',
          component: {render: () => h(RouterView)},

          children: [
            {
              path: '',
              name: type + '.licenses.plugins',
              component: () =>
                import('@console/pages/licenses/plugins/index.vue'),
              meta: {
                pluginLicensesRenewAlert: true,
                title: 'Plugin Licenses',
              },
            },
            {
              path: ':id',
              name: type + '.licenses.plugins.id',
              component: () =>
                import('@console/pages/licenses/plugins/_id.vue'),
            },
          ],
        },
        {
          path: 'claim',
          name: type + '.licenses.claim',
          component: () => import('@console/pages/licenses/claim.vue'),
          meta: {
            title: 'Claim Licenses',
          },
        },
      ],
    },
  ];
};
const routes = [
  ...getLicensesRoutes('org'),
  ...getLicensesRoutes('user'),
  // Handle old routes
  {
    path: '/licenses:afterRoute(.*)',
    redirect: (to) => `/accounts/me/licenses${to.params.afterRoute}`,
  },
];

export default routes;
