import '../css/app.pcss';

import {createApp} from 'vue';
import VueClickAway from 'vue3-click-away';
import VueAnnouncer from '@vue-a11y/announcer';
import store from './store';
import router from './router';
import Root from './App.vue';
import ui from '@/common/ui/plugin';
import axios from 'axios';
import filters from './filters';
import {VueQueryPlugin} from '@tanstack/vue-query';
import * as Sentry from '@sentry/vue';
import '@github/relative-time-element';
import escapeStringRegexp from 'escape-string-regexp';

window.axios = axios;

const app = createApp(Root);

if (SENTRY_DSN) {
  const isProduction = CRAFT_ENVIRONMENT === 'production';
  const sampleRate = isProduction ? 0.2 : 1.0;

  Sentry.init({
    app,
    dsn: SENTRY_DSN,
    environment: CRAFT_ENVIRONMENT,
    release: GIT_SHA,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    tracesSampleRate: sampleRate,
    tracePropagationTargets: [
      'localhost',
      /^\//,
      new RegExp(`^${escapeStringRegexp(CLOUD_API_ENDPOINT)}`),
      new RegExp(`^${escapeStringRegexp(CLOUD_OAUTH_ENDPOINT)}`),
      new RegExp(`^${escapeStringRegexp(VUE_APP_CRAFT_API_ENDPOINT)}`),
      new RegExp(`^${escapeStringRegexp(VUE_APP_URL_CONSOLE)}`),
      new RegExp(`^${escapeStringRegexp(VUE_APP_CRAFT_PLUGINS_URL)}`),
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: sampleRate,
  });
}

app.use(VueClickAway);
app.use(VueAnnouncer, {router});
app.use(router);
app.use(store);
app.use(ui);
app.use(VueQueryPlugin);

app.config.globalProperties.$filters = filters;

app.mount('#app');

window.craftIdApp = app;

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then(function (registrations) {
    for (let registration of registrations) {
      registration.unregister();
    }
  });
}
