import axios from './axios';
import qs from 'qs';

export default {
  claimPluginLicense({orgId, key}) {
    return axios().post(
      Craft.actionUrl + '/craftnet/console/plugin-licenses/claim',
      {
        orgId,
        key,
      }
    );
  },

  getPluginLicense(id) {
    return axios().get(
      Craft.actionUrl + '/craftnet/console/plugin-licenses/get-license-by-id',
      {params: {id}}
    );
  },

  getPluginLicenses(orgId) {
    return axios().get(
      Craft.actionUrl + '/craftnet/console/plugin-licenses/get-licenses',
      {params: {orgId}}
    );
  },

  getExpiringPluginLicensesTotal() {
    return axios().get(
      Craft.actionUrl +
        '/craftnet/console/plugin-licenses/get-expiring-licenses-total'
    );
  },

  releasePluginLicense({pluginHandle, licenseKey}) {
    const data = {
      handle: pluginHandle,
      key: licenseKey,
    };

    return axios().post(
      Craft.actionUrl + '/craftnet/console/plugin-licenses/release',
      qs.stringify(data)
    );
  },

  savePluginLicense(license) {
    const data = {};

    for (const attribute in license) {
      if (attribute === 'cmsLicense') {
        continue;
      }

      data[attribute] = license[attribute];
    }

    return axios().post(
      Craft.actionUrl + '/craftnet/console/plugin-licenses/save',
      qs.stringify(data)
    );
  },

  transferPluginLicense({id, newOwnerId}) {
    return axios().post(
      Craft.actionUrl + '/craftnet/console/plugin-licenses/transfer',
      {
        id,
        newOwnerId,
      }
    );
  },
};
