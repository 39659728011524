<template>
  <component
    :is="to ? 'router-link' : 'button'"
    :to="to"
    :class="[
      {
        'w-full cursor-pointer rounded my-1 px-3 py-2 leading-5 flex items-center justify-between text-left': true,
        'text-sm text-gray-800 dark:text-gray-200 hover:text-gray-800 dark:hover:text-gray-200 no-underline hover:no-underline': true,
        'hover:bg-gray-200 dark:hover:bg-gray-600': true,
        'border border-transparent hover:border-interactive-nav-active-background': true,
      },
    ]"
  >
    <div class="flex-1 min-w-0">
      <slot />
    </div>

    <div>
      <icon
        v-if="checked"
        label="Selected"
        class="w-4 h-4 text-light dark:text-gray-300"
        icon="check"
      />
    </div>
  </component>
</template>

<script>
  export default {
    props: {
      checked: {
        type: Boolean,
        default: false,
      },
      to: {
        type: String,
        default: null,
      },
    },
  };
</script>
