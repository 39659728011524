export default {
  /**
   * Save trigger after login.
   */
  saveTriggerAfterLogin(triggerAfterLogin) {
    localStorage.setItem(
      'triggerAfterLogin',
      JSON.stringify(triggerAfterLogin)
    );
  },

  /**
   * Get trigger after login.
   */
  getTriggerAfterLogin() {
    return JSON.parse(localStorage.getItem('triggerAfterLogin'));
  },

  /**
   * Save last org slug.
   */
  saveLastOrgSlug(orgSlug) {
    localStorage.setItem('lastOrgSlug', JSON.stringify(orgSlug));
  },

  /**
   * Get last org slug.
   */
  getLastOrgSlug() {
    return JSON.parse(localStorage.getItem('lastOrgSlug'));
  },
};
