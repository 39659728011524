import cmsLicensesApi from '@console/api/cms-licenses';

/**
 * State
 */
const state = {
  expiringCmsLicensesTotal: 0,
};

/**
 * Getters
 */
const getters = {};

/**
 * Actions
 */
const actions = {
  getExpiringCmsLicensesTotal({commit}) {
    return new Promise((resolve, reject) => {
      cmsLicensesApi
        .getExpiringCmsLicensesTotal()
        .then((response) => {
          if (typeof response.data !== 'undefined' && !response.data.error) {
            commit('updateExpiringCmsLicensesTotal', response.data);
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((response) => {
          reject(response);
        });
    });
  },

  transferCmsLicense(context, {id, newOwnerId, includePlugins}) {
    return cmsLicensesApi.transferCmsLicense({
      id,
      newOwnerId,
      includePlugins,
    });
  },
};

/**
 * Mutations
 */
const mutations = {
  updateExpiringCmsLicensesTotal(state, total) {
    state.expiringCmsLicensesTotal = parseInt(total);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
