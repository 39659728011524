import axios from './axios';
import {getCraftApiEndpointUrl} from '@console/helpers/url.js';

export default {
  getCoreData() {
    return axios().get(getCraftApiEndpointUrl() + '/plugin-store/core-data', {
      withCredentials: false,
    });
  },

  getPlugins(pluginIds) {
    return axios().get(getCraftApiEndpointUrl() + '/plugins', {
      params: {
        ids: pluginIds.join(','),
      },
      withCredentials: false,
    });
  },
};
