import store from '../store/index.js';
import type {RouteLocationRaw} from 'vue-router';

const stripTrailingSlash = (string: string) => {
  return string.endsWith('/') ? string.slice(0, -1) : string;
};

const stripLeadingSlash = (string: string) => {
  return string.startsWith('/') ? string.slice(1) : string;
};

export const deslash = (string: string) => {
  return stripLeadingSlash(stripTrailingSlash(string));
};

export function getPrefixedTo(to: RouteLocationRaw, prefix = '') {
  if (typeof to === 'string') {
    if (prefix) {
      return `/${deslash(prefix)}/${deslash(to)}`;
    }

    const currentOrg = store.getters['orgs/currentOrg'];
    if (currentOrg) {
      // If we have an org and the URL already has an org
      if (to.includes(`orgs/${currentOrg.slug}`)) {
        return to;
      }

      return `/accounts/${currentOrg.slug}/${deslash(to)}`;
    }
  }

  return '/accounts/me' + to;
}
