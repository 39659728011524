export function getCraftApiEndpointUrl(version = 'v1') {
  if (!VUE_APP_CRAFT_API_ENDPOINT) {
    return `https://api.craftcms.com/${version}`;
  }

  return `${VUE_APP_CRAFT_API_ENDPOINT}/${version}`;
}

export function getAppUrl() {
  if (!VUE_APP_URL_CONSOLE) {
    return 'https://console.craftcms.com';
  }

  return VUE_APP_URL_CONSOLE;
}

export function getComUrl(path) {
  return `https://craftcms.com/${path}`;
}

export function getKbUrl(slug) {
  return getComUrl(`knowledge-base/${slug}`);
}

export function getDocsUrl(path) {
  return getComUrl(`docs/${path}`);
}
