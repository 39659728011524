import {useRoute} from 'vue-router';
import {
  urlToQueryKey,
  useDelete,
  useGet,
  usePaginatedQuery,
  usePost,
  usePut,
} from '@console/helpers/query';
import type {
  ApiResponse,
  Environment,
  Invoice,
  Project,
  Repository,
} from '@/console';
import {useQueryClient} from '@tanstack/vue-query';
import {computed, ref} from 'vue';
import get from 'lodash/get';
import type {AxiosResponse} from 'axios';

interface ProjectParams {
  sort?: string;
  include: string;
}

const responseInclude = ['domains', 'environments', 'cluster'].join(',');

export const useGetProjectList = (initialParams: ProjectParams | {} = {}) => {
  const params = ref({
    include: responseInclude,
    ...initialParams,
  });

  const response = usePaginatedQuery<ApiResponse<Project>, ProjectParams>(
    `/projects`,
    params
  );
  const projects = computed(() => get(response, 'data.value.data', []));

  return {projects, ...response};
};

export const useGetProject = (params = {}, config = {}) => {
  const route = useRoute();
  const {projectKey} = route.params;
  const defaultConfig = {keepPreviousData: true, enabled: Boolean(projectKey)};
  const useGetConfig = {...defaultConfig, ...config};
  const defaultParams = {include: responseInclude};
  const useGetParams = {...defaultParams, ...params};

  const response = useGet<ApiResponse<Project>>(
    `/projects/${projectKey}`,
    useGetParams,
    useGetConfig
  );
  const project = computed(() => response.data.value?.data);
  const productionEnvironment = computed(() =>
    project.value?.environments?.find((env) => env.is_production)
  );

  return {project, productionEnvironment, ...response};
};

export const useGetProjectNextInvoice = () => {
  const route = useRoute();
  const {projectKey} = route.params;

  const response = useGet<ApiResponse<Invoice>>(
    `/projects/${projectKey}/invoices/next`
  );

  const invoice = computed(() => get(response, 'data.value.data', null));

  return {invoice, ...response};
};

export const useSwitchProjectPlan = () => {
  const route = useRoute();
  const {projectKey} = route.params;

  const response = usePost(`/projects/${projectKey}/switch-plan`);

  const project = computed(() => {
    return get(response, 'data.value.data', null);
  });
  return {project, ...response};
};

export const useDeleteProject = () => {
  return useDelete(`/projects`);
};

export const useCancelProject = () => {
  const route = useRoute();
  const {projectKey} = route.params;

  const queryClient = useQueryClient();
  const queryKey = urlToQueryKey(`/projects/${projectKey}`, {
    include: responseInclude,
  });

  const response = usePost(
    `/projects/${projectKey}/cancel`,
    {
      include: responseInclude,
    },
    undefined,
    undefined,
    {
      onSuccess: ({data}) => {
        queryClient.setQueryData(queryKey, () => {
          return data;
        });
      },
    }
  );

  const project = computed(() => {
    return get(response, 'data.value.data', null);
  });

  return {project, ...response};
};

export const useResumeProject = () => {
  const route = useRoute();
  const {projectKey} = route.params;

  const queryClient = useQueryClient();
  const queryKey = urlToQueryKey(`/projects/${projectKey}`, {
    include: responseInclude,
  });

  const response = usePost(
    `/projects/${projectKey}/resume`,
    {
      include: responseInclude,
    },
    undefined,
    undefined,
    {
      onSuccess: ({data}) => {
        queryClient.setQueryData(queryKey, () => {
          return data;
        });
      },
    }
  );

  const project = computed(() => {
    return get(response, 'data.value.data', null);
  });
  return {project, ...response};
};

export const useUpdateProject = () => {
  const route = useRoute();
  const {projectKey} = route.params;

  const queryClient = useQueryClient();
  const queryKey = urlToQueryKey(`/projects/${projectKey}`, {
    include: responseInclude,
  });

  return usePut<ApiResponse<Project>, Partial<Project>>(
    `/projects/${projectKey}`,
    {
      include: responseInclude,
    },
    (oldData, newData) => {
      return oldData
        ? {
            data: {
              ...oldData.data,
              ...newData,
            },
          }
        : oldData;
    },
    undefined,
    {
      onSuccess: ({data}) => {
        queryClient.setQueryData(queryKey, () => {
          return data;
        });
      },
    }
  );
};

export const useCreateProject = () => {
  return usePost<
    ApiResponse<Project>,
    {
      name: string;
      repository: Repository;
    }
  >(`/projects`);
};

export const useUpdateProjectSecurityKey = () => {
  const route = useRoute();
  const {projectKey} = route.params;
  const queryClient = useQueryClient();

  const projectQueryKey = urlToQueryKey(`/projects/${projectKey}`, {
    include: responseInclude,
  });

  return usePut(
    `/projects/${projectKey}/security-key`,
    undefined,
    undefined,
    undefined,
    {
      onSuccess: ({data}) => {
        queryClient.setQueryData<ApiResponse<Project>>(
          projectQueryKey,
          (oldData) => {
            if (oldData) {
              oldData.data.security_key = data.security_key;
              return oldData;
            }
          }
        );
      },
    }
  );
};

function updateProductionEnvironment(
  oldData: ApiResponse<Project> | undefined,
  response: AxiosResponse<{data: Environment}>
): undefined | ApiResponse<Project> {
  const updatedEnvironment = response.data.data;
  return oldData
    ? {
        ...oldData,
        data: {
          ...oldData.data,
          environments: oldData.data.environments.map((environment) => {
            if (environment.id === updatedEnvironment.id) {
              return updatedEnvironment;
            }

            // Manually override is_production for everyone lese
            return {
              ...environment,
              is_production: false,
            };
          }),
        },
      }
    : oldData;
}

export const useUpdateProjectProduction = () => {
  const route = useRoute();
  const {projectKey} = route.params;
  const queryClient = useQueryClient();

  const projectQueryKey = urlToQueryKey(`/projects/${projectKey}`, {
    include: responseInclude,
  });

  return usePut(`/production/${projectKey}`, undefined, undefined, undefined, {
    onSuccess: (response: AxiosResponse<{data: Environment}>) => {
      queryClient.setQueryData<ApiResponse<Project>>(
        projectQueryKey,
        (oldData) => updateProductionEnvironment(oldData, response)
      );
    },
  });
};

export const useCreateProductionEnvironment = () => {
  const route = useRoute();
  const {projectKey} = route.params;
  const queryClient = useQueryClient();

  const projectQueryKey = urlToQueryKey(`/projects/${projectKey}`, {
    include: responseInclude,
  });

  return usePost<ApiResponse<Environment>, {environment_id: string}>(
    `/projects/${projectKey}/production`,
    undefined,
    undefined,
    undefined,
    {
      onSuccess: (response: AxiosResponse<{data: Environment}>) => {
        queryClient.setQueryData<ApiResponse<Project>>(
          projectQueryKey,
          (oldData) => updateProductionEnvironment(oldData, response)
        );
      },
    }
  );
};

export const useGetProjectBranchList = () => {
  const route = useRoute();
  const {projectKey} = route.params;

  const response = useGet<AxiosResponse<string[]>>(
    `/projects/${projectKey}/branches`
  );

  const branchOptions = computed(() => {
    const branches = response.data.value?.data ?? [];
    return branches.map((option: string) => ({
      value: option,
      label: option,
    }));
  });

  return {branchOptions, ...response};
};
