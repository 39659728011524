import Alert from './Alert.vue';
import Badge from './Badge.vue';
import Btn from './Btn.vue';
import Checkbox from './Checkbox.vue';
import Dropdown from './Dropdown.vue';
import Field from './Field.vue';
import Icon from './Icon.vue';
import Lightswitch from './Lightswitch.vue';
import Pane from './Pane.vue';
import Radio from './Radio.vue';
import RadioInput from './RadioInput.vue';
import Spinner from './Spinner.vue';
import Textbox from './Textbox.vue';

const CraftComponents = {
  Alert,
  Badge,
  Btn,
  Checkbox,
  Dropdown,
  Field,
  Icon,
  Lightswitch,
  Pane,
  Radio,
  RadioInput,
  Spinner,
  Textbox,
};

export default CraftComponents;

export {
  Alert,
  Badge,
  Btn,
  Checkbox,
  Dropdown,
  Field,
  Icon,
  Lightswitch,
  Pane,
  Radio,
  RadioInput,
  Spinner,
  Textbox,
};
