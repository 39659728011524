<script setup lang="ts"></script>

<template>
  <div class="w-7 h-7">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 100 100"
    >
      <title>Craft Console</title>
      <g fill="none">
        <rect
          width="40.909"
          height="40.909"
          x="29.545"
          y="29.545"
          fill="#FFF"
        />
        <path
          fill="#E5422B"
          d="M89.4736842,0 L10.5263158,0 C4.67836257,0 0,4.67836257 0,10.5263158 L0,89.4736842 C0,95.3216374 4.67836257,100 10.5263158,100 L89.4736842,100 C95.2046784,100 100,95.3216374 100,89.4736842 L100,10.5263158 C100,4.67836257 95.3216374,0 89.4736842,0 M60,56.6081871 L64.6783626,61.9883041 C59.7660819,65.9649123 54.1520468,68.1871345 48.5380117,68.1871345 C37.4269006,68.1871345 30.4093567,60.8187135 32.0467836,50.4093567 C33.6842105,40 43.1578947,32.6315789 54.2690058,32.6315789 C59.6491228,32.6315789 64.6783626,34.7368421 68.1871345,38.5964912 L61.6374269,43.9766082 C59.7660819,41.5204678 56.6081871,39.8830409 53.0994152,39.8830409 C46.4327485,39.8830409 41.2865497,44.2105263 40.2339181,50.4093567 C39.2982456,56.6081871 43.0409357,60.9356725 49.8245614,60.9356725 C53.0994152,60.9356725 56.6081871,59.6491228 60,56.6081871 Z"
        />
      </g>
    </svg>
  </div>
</template>

<style scoped></style>
