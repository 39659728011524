import {formatCmsLicense, formatPluginLicense} from '@console/helpers/licenses';
import {
  dateToFormat,
  dateToLocaleString,
  getDateTime,
  parseDate,
} from '@console/helpers/date';
import {currency, formatNumber} from '@console/helpers/number';
import {capitalize} from '@console/helpers/text';

export function escapeHtml(html) {
  const el = document.createElement('textarea');
  el.textContent = html;
  return el.innerHTML;
}

export default {
  capitalize,
  escapeHtml,
  currency,
  formatCmsLicense,
  formatNumber,
  formatPluginLicense,
  dateToFormat,
  dateToLocaleString,
  getDateTime,
  parseDate,
};
