<script setup lang="ts">
  import {useClipboard} from '@vueuse/core';
  import Icon from '@/common/ui/components/Icon.vue';
  import {ref} from 'vue';

  const {isSupported, copied, copy} = useClipboard();

  const props = withDefaults(
    defineProps<{
      value?: string;
    }>(),
    {value: ''}
  );

  const slotWrap = ref<HTMLElement | null>(null);

  function handleCopy() {
    if (props.value) {
      copy(props.value);
      return;
    }

    copy(slotWrap.value?.textContent ?? '');
    return;
  }
</script>

<template>
  <div
    class="inline-flex px-1 leading-relaxed items-center gap-2 bg-gray-100 rounded border border-separator"
  >
    <div ref="slotWrap">
      <slot />
    </div>
    <button
      type="button"
      @click="handleCopy"
      class="w-4 h-4 inline-flex flex-col justify-center"
      v-if="isSupported"
    >
      <icon v-if="copied" icon="check" />
      <Icon v-else icon="copy" />
    </button>
  </div>
</template>

<style scoped></style>
