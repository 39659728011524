import {apiClient} from '@console/api/axios';
import axios from 'axios';
import store from '@console/store/index';
import jwtDecode from 'jwt-decode';

export const baseUrlCloud = CLOUD_API_ENDPOINT || 'https://api.craft.cloud';
export const baseUrlAuth = CLOUD_OAUTH_ENDPOINT || 'https://oauth.craft.cloud';

let queue = [];
let isRefreshing = false;

export const cloudClient = axios.create({
  baseURL: baseUrlCloud,
});

export const authClient = axios.create({
  baseURL: baseUrlAuth,
});

function isTokenExpired(token) {
  if (!token) {
    return true;
  }

  const {exp} = jwtDecode(token);
  if (!exp) {
    return true;
  }

  return Date.now() / 1000 > exp;
}

function updateHeaders(config, token = false) {
  const {headers} = config;
  headers['Accept'] = 'application/json';

  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }

  return {
    ...config,
    headers,
  };
}

async function fetchToken() {
  const contextId = store.state.context.activeId;
  const {data} = await apiClient.post('/cloud/token', {
    contextId,
  });
  const {token} = data;

  if (token) {
    localStorage.setItem(`cloudToken:${contextId}`, token);
    return token;
  }
}

async function refreshToken() {
  isRefreshing = true;

  try {
    return await fetchToken();
  } catch (error) {
    throw new Error(error.message);
  } finally {
    isRefreshing = false;
  }
}

export async function refreshTokenIfNeeded() {
  const contextId = store.state.context.activeId;
  let token = localStorage.getItem(`cloudToken:${contextId}`);

  if (!token || isTokenExpired(token)) {
    token = await refreshToken();
  }

  return token;
}

const resolveQueue = (token) => {
  queue.forEach((promise) => {
    promise.resolve(token);
  });

  queue = [];
};

const declineQueue = (error) => {
  queue.forEach((promise) => {
    promise.reject(error);
  });

  queue = [];
};

async function requestInterceptor(config) {
  if (isRefreshing) {
    return new Promise((resolve, reject) => {
      queue.push({resolve, reject});
    })
      .then((token) => {
        return updateHeaders(config, token);
      })
      .catch(Promise.reject);
  }

  let token;
  try {
    token = await refreshTokenIfNeeded();
    resolveQueue(token);
  } catch (error) {
    console.error(error);
    // TODO: Handle error better.
    // This would happen if we get a bad response for /cloud/token
    declineQueue(error);
  }

  return updateHeaders(config, token);
}

// Add our interceptors
cloudClient.interceptors.request.use(requestInterceptor);
authClient.interceptors.request.use(requestInterceptor);
