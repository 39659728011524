import {DateTime} from 'luxon';
import humanizeDuration from 'humanize-duration';

export function dateToFormat(date, format) {
  const parsedDate = parseDate(date);

  if (typeof parsedDate === 'string') {
    return parsedDate;
  } else if (typeof parsedDate !== 'object') {
    return date;
  }

  if (!format) {
    format = 'yyyy-MM-dd';
  }

  return parsedDate.setLocale('en-us').toFormat(format);
}

export function dateToLocaleString(date, format) {
  const parsedDate = parseDate(date);

  if (typeof parsedDate !== 'object') {
    return date;
  }

  if (!format) {
    format = 'DATETIME_MED';
  }

  return parsedDate.setLocale('en-us').toLocaleString(DateTime[format]);
}

export function getDateTime() {
  return DateTime;
}

export function parseDate(date) {
  const jsDate = new Date(date);

  if (!isDateValid(jsDate)) {
    return date;
  }

  return getDateTime().fromJSDate(jsDate);
}

export function isDateValid(d) {
  if (Object.prototype.toString.call(d) !== '[object Date]') {
    return false;
  }

  if (isNaN(d)) {
    return false;
  }

  return true;
}

export const shortDurationOptions = {
  language: 'shortEn',
  spacer: '',
  languages: {
    shortEn: {
      y: () => 'y',
      mo: () => 'mo',
      w: () => 'w',
      d: () => 'd',
      h: () => 'h',
      m: () => 'm',
      s: () => 's',
      ms: () => 'ms',
    },
  },
};

/**
 *
 * @param {string|DateTime} start
 * @param {string|DateTime} end
 * @returns {string|null}
 */
export function humanDuration(start, end = DateTime.now()) {
  const startDate = DateTime.fromISO(start);
  const endDate = DateTime.fromISO(end);

  return shortDurationHumanizer(
    endDate.diff(startDate).toMillis(),
    shortDurationOptions
  );
}

export const shortDurationHumanizer = humanizeDuration.humanizer({
  largest: 1,
  round: true,
});
