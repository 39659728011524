<template>
  <div
    class="c-checkbox"
    :class="{
      'relative flex items-start': true,
      disabled,
    }"
  >
    <div class="flex items-center h-5">
      <input
        :id="id"
        ref="input"
        v-model="model"
        type="checkbox"
        class="form-checkbox h-4 w-4 rounded shadow-sm border-input bg-white dark:bg-gray-700 text-blue-600"
        :name="name"
        :value="value"
        :disabled="disabled"
        :aria-required="required"
        @change="onChange"
      />
    </div>

    <div
      :class="{
        'ml-2 font-medium text-sm leading-5': true,
        'text-red-800 dark:text-red-200': invalid,
      }"
    >
      <slot name="label">
        <label class="mb-0" :for="id">
          {{ label }}<span v-if="required" aria-hidden="true">*</span>
          <span v-if="required" class="sr-only">(required)</span>
        </label>
      </slot>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      modelValue: {
        type: [Boolean, Array],
        default: null,
      },
      checked: {
        type: Boolean,
        default: null,
      },
      disabled: Boolean,
      id: {
        type: String,
        default: function () {
          return 'checkbox-' + Math.random().toString(36).substr(2, 9);
        },
      },
      invalid: Boolean,
      label: {
        type: String,
        default: '',
      },
      name: {
        type: String,
        default: '',
      },
      value: {
        type: [String, Boolean],
        default: null,
      },
      required: {
        type: Boolean,
        default: false,
      },
    },

    emits: ['update:modelValue', 'change'],

    computed: {
      model: {
        get() {
          return this.modelValue;
        },
        set(newValue) {
          this.$emit('update:modelValue', newValue);
        },
      },
    },

    methods: {
      click() {
        this.$refs.input.click();
      },

      onChange() {
        this.$emit('change', this.modelValue);
      },
    },
  };
</script>

<style>
  .c-checkbox.disabled {
    @apply opacity-50;
  }
</style>
