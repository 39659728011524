<template>
  <component
    :is="computedComponent"
    class="c-btn"
    :target="target"
    :type="computedType"
    :class="[
      {
        loading,

        // Base
        'ring-offset-2': true,
        'rounded-md': true,
        'border border-transparent': true,
        'no-underline hover:!no-underline': true,
        'disabled:opacity-50 disabled:cursor-default': true,
        'transition transition-duration-75': true,
        'opacity-50 cursor-default': disabled || loading,
        'justify-center': true,

        // Size
        'text-sm leading-3 px-3 py-2': size === 'xsmall',
        'text-sm leading-4  px-3 py-2': size === 'small',
        'text-sm leading-5 px-4 py-2': size === 'medium',
        'text-base leading-6 px-4 py-2': size === 'large',

        // Variants

        // Contained button
        [[
          // Primary
          kind === 'primary'
            ? [
                'text-white hover:text-white',
                'border-blue-600 dark:border-blue-800 ',
                'bg-blue-600 dark:bg-blue-800 ',
                'disabled:bg-blue-500 disabled:border-blue-500 dark:disabled:border-blue-600 ',
                !disabled && !loading
                  ? 'hover:bg-blue-800 dark:hover:bg-blue-600 hover:border-blue-800 dark:hover:border-blue-600'
                  : '',
              ].join(' ')
            : null,

          // Secondary
          kind === 'secondary'
            ? [
                'text-black hover:text-black dark:text-white dark:hover:text-white',
                'border-gray-200 dark:border-gray-800 ',
                'bg-gray-200 dark:bg-gray-800 ',
                'disabled:bg-gray-200 disabled:border-gray-200 dark:disabled:bg-gray-800 dark:disabled:border-gray-800',
                !disabled && !loading
                  ? 'hover:bg-gray-300 dark:hover:bg-gray-700 hover:border-gray-300 dark:hover:border-gray-700'
                  : '',
              ].join(' ')
            : null,

          // Danger
          kind === 'danger'
            ? [
                'text-white hover:text-white',
                'border-red-600 dark:border-red-800 ',
                'bg-red-600 dark:bg-red-800',
                'disabled:bg-red-500 disabled:border-red-500 dark:disabled:border-red-600 ',
                !disabled && !loading
                  ? 'hover:bg-red-700 dark:hover:bg-red-700 hover:border-red-700 dark:hover:border-red-700'
                  : '',
              ].join(' ')
            : null,

          // Success
          kind === 'success'
            ? [
                'text-white hover:text-white',
                'border-green-500 dark:border-green-600 ',
                'bg-green-500 dark:bg-green-600 ',
                'disabled:bg-green-500 disabled:border-green-500 dark:disabled:border-green-600 ',
                !disabled && !loading
                  ? 'hover:bg-green-700 dark:hover:bg-green-500 hover:border-green-700 dark:hover:border-green-500'
                  : '',
              ].join(' ')
            : null,
        ].join(' ')]: variant === 'contained',

        // Text button
        [[
          !disabled && !loading
            ? 'hover:bg-gray-500/5 dark:hover:bg-gray-300/5'
            : null,

          // Primary
          kind === 'primary' ? 'text-blue-600 dark:text-blue-400' : null,

          // Danger
          kind === 'danger' ? 'text-red-600 dark:text-red-400' : null,

          // Success
          kind === 'success' ? 'text-green-600 dark:text-green-400' : null,
        ].join(' ')]: variant === 'text',
      },
    ]"
    v-bind="additionalAttributes"
  >
    <template v-if="loading">
      <spinner />
    </template>

    <span
      class="inline-flex flex-nowrap justify-center items-center gap-1"
      :class="{
        invisible: loading,
      }"
    >
      <slot />
    </span>
  </component>
</template>

<script>
  import Spinner from '@/common/ui/components/Spinner.vue';
  import {defineComponent} from 'vue';

  export default defineComponent({
    name: 'Btn',
    components: {Spinner},

    props: {
      /**
       * 'button', 'submit', 'reset', or 'menu'
       */
      type: {
        type: String,
        default: 'button',
        validator(value) {
          return ['button', 'submit', 'reset', 'menu'].includes(value);
        },
      },
      /**
       * Kind:
       * - 'primary'
       * - 'secondary' (default)
       * - 'danger'
       * - 'success'
       */
      kind: {
        type: String,
        default: 'secondary',
      },
      /**
       * Disabled version of button if set to `true`.
       */
      disabled: {
        type: Boolean,
        default: false,
      },
      /**
       * Size of the button:
       * - 'xsmall'
       * - 'small'
       * - 'medium' (default)
       * - 'large'
       */
      size: {
        type: String,
        validator(value) {
          return ['xsmall', 'small', 'medium', 'large'].includes(value);
        },
        default: 'medium',
      },
      /**
       * Variant of the button:
       * - 'contained' (default)
       * - 'text'
       */
      variant: {
        type: String,
        default: 'contained',
        validator(value) {
          return ['contained', 'text'].includes(value);
        },
      },
      /**
       * Whether the button is loading or not.
       */
      loading: {
        type: Boolean,
        default: false,
      },
      /**
       * To: for router-link
       */
      to: {
        type: [String, Object],
        default: null,
      },
      /**
       * Href: for a
       */
      href: {
        type: String,
        default: null,
      },
      /**
       * Target
       */
      target: {
        type: String,
        default: null,
      },
      /**
       * Component
       */
      component: {
        type: [String],
        default: null,
      },
    },

    computed: {
      additionalAttributes() {
        const attrs = {};

        if (this.disabled) {
          attrs.disabled = true;
        }

        if (this.href) {
          attrs.href = this.href;
        }

        if (this.to) {
          attrs.to = this.to;
        }

        return attrs;
      },

      computedComponent() {
        if (this.component) {
          return this.component;
        }

        if (this.disabled && (this.to || this.href)) {
          return 'span';
        }

        if (this.to !== null && this.to !== '') {
          return 'router-link';
        }

        if (this.href !== null && this.href !== '') {
          return 'a';
        }

        return 'button';
      },

      computedType() {
        if (this.to !== null || this.href !== null) {
          return null;
        }

        return this.type;
      },
    },
    methods: {
      focus() {
        this.$el.focus();
      },
    },
  });
</script>

<style lang="pcss">
  .c-btn,
  a.c-btn,
  button.c-btn {
    @apply inline-flex items-center;

    &.loading {
      @apply relative;

      .c-spinner {
        @apply absolute inset-0 flex justify-center items-center;
        color: currentColor;
      }
    }
  }
</style>
