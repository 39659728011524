<template>
  <a :href="url" rel="noreferrer noopener" target="_blank">
    <slot />
  </a>
</template>

<script>
  import {getComUrl} from '@console/helpers/url';

  export default {
    props: {
      path: {
        type: String,
        default: '',
      },
    },
    computed: {
      url() {
        return getComUrl(this.path);
      },
    },
  };
</script>
