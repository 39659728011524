<script setup lang="ts">
  import type {ActionListItemLink} from '@console/composables/useNavigation';
  import {RouterLink} from 'vue-router';
  import ActionItem from '@/common/ui/components/ActionItem.vue';
  import Icon from '@/common/ui/components/Icon.vue';
  import type {SidebarState} from '@console/composables/useSidebarStore';
  import {ref} from 'vue';

  withDefaults(
    defineProps<{
      item: ActionListItemLink;
      state?: SidebarState;
      type?: '' | 'subnav';
    }>(),
    {
      state: 'collapsed',
      type: '',
    }
  );

  const showTooltip = ref(false);
</script>

<template>
  <router-link
    :to="item.to"
    :exact="item.exact || false"
    :class="{
      '-ml-1 block': state === 'expanded',
      'inline-block': state === 'collapsed',
      'router-link': true,
      'router-link--collapsed': state === 'collapsed',
      'router-link--subnav': type === 'subnav',
    }"
    @focus="showTooltip = true"
    @blur="showTooltip = false"
    v-slot="{isExactActive, isActive}"
  >
    <ActionItem
      :state="state"
      :is-exact-active="isExactActive"
      :is-active="isActive"
      :show-tooltip="showTooltip"
    >
      <template #prefix>
        <div v-if="item.prefix">
          <component
            :is="item.prefix.component"
            v-bind="item.prefix.props"
          ></component>
        </div>
        <div
          v-if="item.icon"
          class="flex flex-col justify-center items-center rounded-md w-7 aspect-square bg-white dark:bg-gray-700"
          :class="{
            border: type !== 'subnav',
          }"
        >
          <Icon
            class="text-blue-500 w-4 h-4"
            :icon="item.icon"
            v-bind="item.iconProps || {}"
          />
        </div>
      </template>

      {{ item.label }}

      <template #suffix>
        <component
          v-if="item.suffix"
          :is="item.suffix.component"
          v-bind="item.suffix.props"
        ></component>
      </template>
    </ActionItem>
  </router-link>
</template>

<style scoped>
  .router-link {
    color: inherit;
    text-decoration: none;
    font-weight: theme('fontWeight.medium');
    border-radius: theme('borderRadius.md');

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 5%;
      width: 4px;
      height: 90%;
    }

    &.active {
      font-weight: bolder;
    }

    &.active:before {
      background-color: theme('colors.gray.400');
    }

    &.active-exact:before {
      background-color: theme('colors.blue.600');
    }
  }

  .router-link--subnav {
    font-size: calc(13rem / 16);
    font-weight: theme('fontWeight.medium');
    padding-block: theme('spacing[0.5]');
  }
</style>
