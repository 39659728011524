<template>
  <component
    :is="computedIcon"
    class="inline-block"
    :aria-hidden="computedDecorative"
    :aria-label="label"
    :role="computedRole"
  />
</template>

<script>
  import outlineIcons from '@/common/ui/icons/outline/index';
  import solidIcons from '@/common/ui/icons/solid/index';

  export default {
    props: {
      icon: {
        type: String,
        required: true,
      },
      set: {
        type: String,
        default: 'outline',
      },
      label: {
        type: String,
        default: null,
      },
    },

    computed: {
      computedRole() {
        if (this.label) {
          return 'img';
        }

        return 'none';
      },
      computedDecorative() {
        if (this.label !== null) {
          return false;
        }
        return true;
      },
      computedIcon() {
        if (this.set === 'outline' && outlineIcons[this.icon]) {
          return outlineIcons[this.icon];
        }

        if (this.set === 'solid' && solidIcons[this.icon]) {
          return solidIcons[this.icon];
        }

        if (outlineIcons[this.icon]) {
          return outlineIcons[this.icon];
        }
        if (solidIcons[this.icon]) {
          return solidIcons[this.icon];
        }

        return null;
      },
    },
  };
</script>
