<template>
  <TransitionRoot
    appear
    as="template"
    :show="isOpen"
    @after-leave="onAfterLeave"
  >
    <Dialog as="div">
      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="min-h-screen px-4 text-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay class="fixed inset-0" />
          </TransitionChild>

          <span class="inline-block h-screen align-middle" aria-hidden="true"
            >&#8203;</span
          >

          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
            @after-enter="onAfterEnter"
          >
            <div
              class="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-primary shadow-xl rounded-2xl"
            >
              <div class="flex space-x-4">
                <icon icon="exclamation" class="w-12 h-12 text-gray-400" />
                <form class="flex-1" @submit.prevent="login">
                  <h6 class="font-bold">Your session has ended</h6>
                  <p class="text-sm">Enter your password to sign back in.</p>

                  <div class="mt-4">
                    <div class="flex items-center space-x-3">
                      <div class="flex-1">
                        <textbox
                          ref="passwordInput"
                          v-model="password"
                          type="password"
                          placeholder="Password"
                          :is-invalid="!!loginError"
                        />
                      </div>
                      <btn
                        kind="primary"
                        type="submit"
                        :disabled="!validatePassword()"
                      >
                        Sign in
                      </btn>
                      <spinner :class="{invisible: !passwordSpinner}" />
                    </div>
                  </div>

                  <div v-if="loginError" class="text-red-600">
                    {{ loginError }}
                  </div>
                </form>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
  import FormDataHelper from '@console/helpers/form-data.js';
  import usersApi from '@console/api/users.js';
  import IsMobileBrowser from '@console/components/IsMobileBrowser.js';
  import {
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
  } from '@headlessui/vue';
  import {mapState} from 'vuex';

  export default {
    components: {
      TransitionRoot,
      TransitionChild,
      Dialog,
      DialogOverlay,
    },
    mixins: [IsMobileBrowser],

    props: {
      isOpen: {
        type: Boolean,
        default: false,
      },
    },

    emits: ['after-leave', 'checkAuthStatus', 'submitLoginSuccess'],

    data() {
      return {
        loginError: null,
        password: null,
        passwordValidates: false,
        passwordSpinner: false,
      };
    },

    computed: {
      ...mapState({
        user: (state) => state.account.user,
      }),
    },

    watch: {
      /**
       * Validate password when the password value changes.
       */
      password(newVal) {
        this.validatePassword();

        return newVal;
      },
    },

    methods: {
      /**
       * On after enter login modal.
       */
      onAfterEnter() {
        if (!this.isMobileBrowser(true)) {
          this.$refs.passwordInput.focus();
        }
      },

      /**
       * On after leave login modal.
       */
      onAfterLeave() {
        this.password = '';
        this.$emit('after-leave');
      },

      /**
       * Login.
       */
      login() {
        if (!this.validatePassword()) {
          return null;
        }

        this.passwordSpinner = true;

        this.clearLoginError();

        if (typeof Craft.csrfTokenValue !== 'undefined') {
          // Check the auth status one last time before sending this off,
          // in case the user has already logged back in from another window/tab
          this.$emit('checkAuthStatus');
        } else {
          this.submitLogin();
        }
      },

      /**
       * Submit login.
       */
      submitLogin() {
        let formData = new FormData();

        FormDataHelper.append(formData, 'loginName', this.user.username);
        FormDataHelper.append(formData, 'password', this.password);

        usersApi
          .login(formData)
          .then(() => {
            this.passwordSpinner = false;
            this.$emit('submitLoginSuccess');
          })
          .catch((error) => {
            this.showLoginError(error.response.data.message);

            if (!this.isMobileBrowser(true)) {
              this.$refs.passwordInput.focus();
            }
          })
          .finally(() => {
            this.passwordSpinner = false;
          });
      },

      /**
       * Show login error.
       */
      showLoginError(error) {
        if (error === null || typeof error === 'undefined') {
          error = 'An unknown error occurred.';
        }

        this.loginError = error;
      },

      /**
       * Clear login error.
       */
      clearLoginError() {
        this.showLoginError('');
      },

      /**
       * Validate password.
       */
      validatePassword() {
        if (this.password && this.password.length >= 6) {
          this.passwordValidates = true;
          return true;
        } else {
          this.passwordValidates = false;
          return false;
        }
      },
    },
  };
</script>
