const routes = [
  // Register
  {
    path: '/register',
    name: 'register',
    component: () => import('@console/pages/register/index.vue'),
    meta: {
      layout: 'site',
      centerContent: true,
      allowAnonymous: true,
      title: 'Register',
    },
  },
  {
    path: '/register/success',
    name: 'registerSuccess',
    component: () => import('@console/pages/register/success.vue'),
    meta: {
      layout: 'site',
      centerContent: true,
      allowAnonymous: true,
      title: 'Registration Success',
    },
  },

  // Login
  {
    path: '/login',
    name: 'login',
    component: () => import('@console/pages/login.vue'),
    meta: {
      layout: 'site',
      centerContent: true,
      mainFull: true,
      allowAnonymous: true,
      title: 'Sign in',
    },
  },

  // Forgot Password
  {
    path: '/forgot-password',
    name: 'forgotPassword',
    component: () => import('@console/pages/forgot-password.vue'),
    meta: {
      layout: 'site',
      centerContent: true,
      allowAnonymous: true,
      title: 'Forgot Password',
    },
  },

  {
    path: '/mfa/setup',
    name: 'mfa.setup',
    component: () => import('@console/pages/mfa-setup.vue'),
    meta: {
      layout: 'site',
      centerContent: true,
      title: 'Two-Step Verification Setup',
    },
  },
];
export default routes;
