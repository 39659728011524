<template>
<svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 448 512"
        fill="currentColor"
>
    <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
    <path d="M342.4 32C367.7 32 390.6 46.9 400.9 70.01L442.5 163.6C446.1 171.8 448 180.6 448 189.6V416C448 451.3 419.3 480 384 480H64C28.65 480 0 451.3 0 416V189.6C0 180.6 1.879 171.8 5.516 163.6L47.11 70.01C57.38 46.89 80.3 32 105.6 32H342.4zM342.4 80H248V160H388.4L357 89.5C354.5 83.72 348.7 80 342.4 80V80zM400 208H48V416C48 424.8 55.16 432 64 432H384C392.8 432 400 424.8 400 416V208zM59.64 160H200V80H105.6C99.27 80 93.54 83.72 90.97 89.5L59.64 160z" />
</svg>
</template>
      