<template>
  <Btn
    id="sidebar-toggle"
    size="xsmall"
    variant="text"
    aria-label="Menu"
    :aria-expanded="sidebarState === 'expanded'"
    @click="() => toggleVisibility()"
  >
    <template v-if="sidebarState === 'expanded'">
      <Icon icon="x" class="w-5 h-5" />
    </template>
    <template v-else>
      <Icon icon="menu" :size="null" class="w-5 h-5" />
    </template>
  </Btn>
</template>

<script setup lang="ts">
  import {useSidebarStore} from '@console/composables/useSidebarStore';
  import Icon from '@/common/ui/components/Icon.vue';
  import Btn from '@/common/ui/components/Btn.vue';

  const {sidebarState, toggleVisibility} = useSidebarStore();
</script>
