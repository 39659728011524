<script setup lang="ts">
  import Icon from '@/common/ui/components/Icon.vue';
  import {useClipboard} from '@vueuse/core';
  import {useAnnouncer} from '@vue-a11y/announcer';

  const {polite} = useAnnouncer();
  const {copy, copied, isSupported} = useClipboard();

  const handleClick = (value: string) => {
    copy(value);
    polite('Copied');
  };

  withDefaults(
    defineProps<{
      value?: string;
      inline?: boolean;
      size?: 'small' | 'default';
    }>(),
    {value: '', inline: false, size: 'default'}
  );
</script>

<template>
  <div class="inline-block">
    <button
      v-if="isSupported"
      type="button"
      :class="{
        'rounded flex flex-col justify-center items-center': true,
        'h-full w-full p-1': true,
        'border border-gray-300 dark:text-gray-300': !inline,
        'bg-white dark:bg-gray-700': !inline,
      }"
      @click="handleClick(value)"
      aria-label="Copy"
    >
      <span
        :class="{
          'inline-flex': true,
          'w-4 h-4': size === 'default',
          'w-3 h-3': size === 'small',
        }"
      >
        <icon v-if="copied" icon="check" class="w-full h-auto" />
        <Icon v-else icon="copy" class="w-full h-auto" />
      </span>
    </button>
  </div>
</template>

<style scoped></style>
