const CloudIndex = import('@console/pages/cloud/projects/index.vue');

const getCloudRoutes = (type) => {
  return [
    {
      path: `/accounts/${type === 'org' ? ':orgSlug' : 'me'}/cloud`,
      name: type + '.cloud',
      redirect() {
        return {name: type + '.cloud.projects'};
      },
      children: [
        {
          path: 'projects',
          children: [
            {
              path: '',
              name: type + '.cloud.projects',
              meta: {
                title: 'Craft Cloud Projects',
              },
              component: () => CloudIndex,
            },
            {
              path: 'new',
              name: type + '.cloud.projects.new',
              component: () =>
                import('@console/pages/cloud/projects/new/index.vue'),
            },
            {
              path: ':projectKey',
              component: () =>
                import('@console/pages/cloud/projects/_project/_layout.vue'),
              meta: {
                sidebarState: 'collapsed',
              },
              children: [
                {
                  path: '',
                  name: type + '.cloud.projects.project',
                  component: () =>
                    import('@console/pages/cloud/projects/_project/index.vue'),
                },
                {
                  path: 'success',
                  name: type + '.cloud.projects.project.success',
                  component: () =>
                    import(
                      '@console/pages/cloud/projects/_project/success.vue'
                    ),
                },
                {
                  path: 'environments',
                  children: [
                    {
                      path: '',
                      name: type + '.cloud.projects.project.environments',
                      meta: {title: 'Environments'},
                      component: () =>
                        import(
                          '@console/pages/cloud/projects/_project/environments/index.vue'
                        ),
                    },
                    {
                      path: 'new',
                      name: type + '.cloud.projects.project.environments.new',
                      meta: {title: 'Create an Environment'},
                      component: () =>
                        import(
                          '@console/pages/cloud/projects/_project/environments/new.vue'
                        ),
                    },
                    {
                      path: ':environmentId',
                      component: () =>
                        import(
                          '@console/pages/cloud/projects/_project/environments/_environment/_layout.vue'
                        ),
                      children: [
                        {
                          path: '',
                          name: type + '.cloud.projects.project.environment',
                          component: () =>
                            import(
                              '@console/pages/cloud/projects/_project/environments/_environment/index.vue'
                            ),
                        },
                        {
                          path: 'deployments',
                          exact: true,
                          children: [
                            {
                              path: '',
                              name:
                                type +
                                '.cloud.projects.project.environment.deployments',
                              meta: {title: 'Deployments'},
                              component: () =>
                                import(
                                  '@console/pages/cloud/projects/_project/environments/_environment/deployments/index.vue'
                                ),
                            },
                            {
                              path: ':deploymentId',
                              name:
                                type +
                                '.cloud.projects.project.environment.deployment',
                              component: () =>
                                import(
                                  '@console/pages/cloud/projects/_project/environments/_environment/deployments/_deployment.vue'
                                ),
                            },
                          ],
                        },
                        {
                          path: 'logs',
                          name:
                            type + '.cloud.projects.project.environment.logs',
                          meta: {title: 'Logs', flexContent: true},
                          component: () =>
                            import(
                              '@console/pages/cloud/projects/_project/environments/_environment/logs.vue'
                            ),
                        },
                        {
                          path: 'backups',
                          meta: {title: 'Backups'},
                          component: () =>
                            import(
                              '@console/pages/cloud/projects/_project/environments/_environment/backups.vue'
                            ),
                        },
                        {
                          path: 'variables',
                          meta: {title: 'Variables'},
                          component: () =>
                            import(
                              '@console/pages/cloud/projects/_project/environments/_environment/variables.vue'
                            ),
                        },
                        {
                          path: 'commands',
                          meta: {title: 'Commands'},
                          component: () =>
                            import(
                              '@console/pages/cloud/projects/_project/environments/_environment/commands.vue'
                            ),
                        },
                        {
                          path: 'access',
                          meta: {title: 'Access'},
                          component: () =>
                            import(
                              '@console/pages/cloud/projects/_project/environments/_environment/access.vue'
                            ),
                        },
                        {
                          path: 'settings',
                          meta: {title: 'Settings'},
                          component: () =>
                            import(
                              '@console/pages/cloud/projects/_project/environments/_environment/settings.vue'
                            ),
                        },
                      ],
                    },
                  ],
                },
                {
                  path: 'domains',
                  children: [
                    {
                      path: '',
                      meta: {title: 'Domains'},
                      component: () =>
                        import(
                          '@console/pages/cloud/projects/_project/domains/index.vue'
                        ),
                    },
                    {
                      path: 'new',
                      meta: {title: 'New Domain'},
                      component: () =>
                        import(
                          '@console/pages/cloud/projects/_project/domains/new.vue'
                        ),
                    },
                    {
                      path: ':domainId',
                      component: () =>
                        import(
                          '@console/pages/cloud/projects/_project/domains/id.vue'
                        ),
                    },
                  ],
                },
                {
                  path: 'settings',
                  meta: {title: 'Settings'},
                  component: () =>
                    import(
                      '@console/pages/cloud/projects/_project/settings.vue'
                    ),
                },
                {
                  path: 'billing',
                  meta: {title: 'Billing'},
                  component: () =>
                    import(
                      '@console/pages/cloud/projects/_project/billing/index.vue'
                    ),
                },
              ],
            },
          ],
        },
      ],
    },
  ];
};

const routes = [...getCloudRoutes('user'), ...getCloudRoutes('org')];

export default routes;
