import axios from './axios';
import qs from 'qs';
import {getAppUrl} from '@console/helpers/url.js';

export default {
  cancelSubscription(subscription) {
    return axios().delete(getAppUrl() + '/developer-support/' + subscription);
  },

  getSubscriptionInfo() {
    return axios().get(getAppUrl() + '/developer-support');
  },

  reactivateSubscription(subscription) {
    return axios().post(
      getAppUrl() + '/developer-support/' + subscription + '/reactivate'
    );
  },

  subscribe(plan) {
    const data = {
      plan,
    };

    return axios().post(getAppUrl() + '/developer-support', qs.stringify(data));
  },

  switchPlan(plan) {
    const data = {
      plan,
    };

    return axios().post(
      getAppUrl() + '/developer-support/switch-plan',
      qs.stringify(data)
    );
  },
};
