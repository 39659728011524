import type {ActionTree, GetterTree, MutationTree} from 'vuex';
import type {Org, Photo} from '@/console';

interface ContextState {
  activeId: number | null;
  byId: {
    [id: number]: Context;
  };
  allIds: number[];
}

export interface Context {
  color: string | null;
  enableCloudSandbox: boolean;
  icon: Photo | null;
  id: number;
  slug: string | null;
  title: string;
  type: 'org' | 'user';
  stripeCustomerRef: string | null;
}

const initialContextId = localStorage.getItem('contextElementId')
  ? JSON.parse(localStorage.getItem('contextElementId') as string)
  : null;

const state: ContextState = {
  activeId: initialContextId,
  byId: {},
  allIds: [],
};

const getters = <GetterTree<ContextState, any>>{
  find: (state) => (id: number) => {
    return state.byId[id];
  },

  list(state, getters): Context[] {
    return state.allIds.map((id) => getters.find(id));
  },

  users(state, getters) {
    return getters.list.filter((context: Context) => context.type === 'user');
  },

  orgs(state, getters) {
    return getters.list.filter((context: Context) => context.type === 'org');
  },

  active(state) {
    if (state.activeId) {
      return state.byId[state.activeId];
    }

    return null;
  },
};

const actions = <ActionTree<ContextState, any>>{
  /**
   * Initialize our contexts
   */
  async init({commit, dispatch, rootState, rootGetters}) {
    const currentOrgId = rootGetters['orgs/currentOrg']
      ? rootGetters['orgs/currentOrg'].id
      : false;

    const currrentUserId = rootState.account.user
      ? rootState.account.user.id
      : false;

    const contextId = currentOrgId ? currentOrgId : currrentUserId;
    await dispatch('set', contextId);

    const user = rootState.account.user;
    if (user) {
      commit('add', <Context>{
        color: null,
        enableCloudSandbox: false,
        icon: user.photo,
        id: user.id,
        slug: null,
        title: user.name,
        type: 'user',
        stripeCustomerRef: user.stripeCustomerRef,
      });
    }

    const orgs = rootState.orgs.orgs;
    orgs.forEach((org: Org) => {
      commit('add', <Context>{
        color: org.orgColor,
        enableCloudSandbox: org.enableCloudSandbox,
        icon: org.orgLogo,
        id: org.id,
        slug: org.slug,
        title: org.title,
        type: 'org',
        stripeCustomerRef: org.stripeCustomerRef,
      });
    });
  },

  async set({commit}, newContextId: string) {
    commit('updateContext', newContextId);
  },
};

const mutations = <MutationTree<ContextState>>{
  updateContext(state, elementId: string) {
    state.activeId = parseInt(elementId, 10);
    localStorage.removeItem('cloudToken');
    localStorage.setItem('contextElementId', JSON.stringify(elementId));
  },

  add(state, item: Context) {
    state.byId[item.id] = item;

    if (!state.allIds.includes(item.id)) {
      state.allIds.push(item.id);
    }
  },

  remove(state, id: number) {
    delete state.byId[id];
    const index = state.allIds.findIndex((itemId) => itemId === id);
    if (index) {
      state.allIds.splice(index, 1);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
