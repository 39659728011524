import {escapeHtml} from '@console/filters';

/**
 * State
 */
const state = {
  loading: true,
  notification: null,
  renewLicense: null,
  pageTitle: 'Craft Console',
  enableWireframes: false,
  breadcrumbs: [],
  showingSidebar: false,
};

/**
 * Getters
 */
const getters = {};

/**
 * Actions
 */
const actions = {
  /**
   *  Displays an error.
   *
   * @param {string} message
   */
  displayNotice({dispatch}, message) {
    dispatch('displayNotification', {type: 'notice', message});
  },

  /**
   *  Displays an error.
   *
   * @param {string} message
   */
  displayError({dispatch}, message) {
    dispatch('displayNotification', {type: 'error', message});
  },

  /**
   *  Displays a notification.
   *
   * @param {string} type
   * @param {string} message
   */
  displayNotification({commit}, {type, message}) {
    commit('updateNotification', {
      type: type,
      message: message,
    });

    let notificationDuration = 2000;

    if (type === 'error') {
      notificationDuration = notificationDuration * 4;
    }

    setTimeout(
      function () {
        this.notification = null;
        commit('updateNotification', null);
      }.bind(this),
      notificationDuration
    );
  },
};

/**
 * Mutations
 */
const mutations = {
  updateLoading(state, loading) {
    state.loading = loading;
  },

  updateRenewLicense(state, license) {
    state.renewLicense = license;
  },

  updateNotification(state, notification) {
    state.notification = notification;
  },

  updatePageTitle(state, pageTitle) {
    const safeTitle = escapeHtml(pageTitle);
    state.pageTitle =
      pageTitle === 'Craft Console'
        ? safeTitle
        : `${safeTitle} | Craft Console`;
  },

  updateBreadcrumbs(state, breadcrumbs) {
    state.breadcrumbs = breadcrumbs;
  },

  updateShowingSidebar(state, showingSidebar) {
    state.showingSidebar = showingSidebar;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
