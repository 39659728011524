<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" @close="$emit('close')">
      <div class="fixed inset-0 z-10 flex md:py-16 md:justify-center">
        <headless-overlay />

        <span class="inline-block h-screen align-middle" aria-hidden="true">
          &#8203;
        </span>

        <headless-pane @submit-form="$emit('submitForm')">
          <div
            class="fixed z-10 w-full backdrop-filter backdrop-blur bg-primary bg-opacity-80"
          >
            <div class="relative p-4">
              <button type="button" class="rounded" @click="$emit('close')">
                <icon icon="x" class="w-6 h-6" label="Close" />
              </button>
            </div>
          </div>

          <div class="flex-1 overflow-auto p-8">
            <div class="mt-8">
              <slot />
            </div>
          </div>

          <template v-if="$slots.footer">
            <div
              class="border-t px-8 py-6 space-x-2 flex justify-end items-center"
            >
              <slot name="footer" />
            </div>
          </template>
        </headless-pane>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
  import {Dialog, TransitionRoot} from '@headlessui/vue';
  import HeadlessOverlay from '@console/components/app/modals/HeadlessOverlay.vue';
  import HeadlessPane from '@console/components/app/modals/HeadlessPane.vue';

  export default {
    components: {
      HeadlessPane,
      HeadlessOverlay,
      TransitionRoot,
      Dialog,
    },
    props: {
      isOpen: Boolean,
    },
    emits: ['close', 'submitForm'],
  };
</script>
