import api from '@console/api/cart';
import {getCartItemsData} from '@console/helpers/cart.js';
import normalizeError from '@console/helpers/normalizeError';

/**
 * State
 */
const state = {
  cart: null,
  itemsAutoRenew: {},
  selectedExpiryDates: {},
  loadingItems: {},
  carts: {},
  guestOrderNumber: null,
};

/**
 * Getters
 */
const getters = {
  /**
   * Cart items.
   *
   * @param state
   * @param getters
   * @param rootState
   * @returns {*[]}
   */
  cartItems(state, getters, rootState) {
    const cart = state.cart;

    const cartItems = [];

    if (cart) {
      const lineItems = cart.lineItems;

      lineItems.forEach((lineItem) => {
        const cartItem = {};

        cartItem.id = lineItem.id;
        cartItem.lineItem = lineItem;

        if (lineItem.purchasable.type === 'plugin-edition') {
          cartItem.plugin = rootState.pluginStore.plugins.find(
            (p) => p.handle === lineItem.purchasable.plugin.handle
          );
        }

        cartItems.push(cartItem);
      });
    }

    return cartItems;
  },

  /**
   * Cart items data.
   * @param state
   * @returns {[]}
   */
  cartItemsData(state) {
    return () => {
      const cart = state.cart;

      return getCartItemsData(cart);
    };
  },

  /**
   * Item loading.
   * @param state
   * @returns {(function(*): (boolean))|*}
   */
  itemLoading(state) {
    return ({itemKey}) => {
      return state.loadingItems[itemKey];
    };
  },

  totalLoadingItems(state) {
    return Object.keys(state.loadingItems).length;
  },

  getCartOrderNumber(state, getters, rootState) {
    return (orgId) => {
      let orderNumber = null;

      if (rootState.account.user) {
        // Retrieve the order number from the user’s carts.
        orderNumber = rootState.account.user.carts.find(
          (cart) => cart.orgId === orgId
        )?.number;
      } else {
        // Retrieve the order number from local storage if not logged in.
        orderNumber = api.getLocalStorageOrderNumber();
      }

      return orderNumber;
    };
  },
};

/**
 * Actions
 */
const actions = {
  async clearCart({commit, dispatch}, orgId) {
    try {
      const {data} = await api.clearCart({orgId});
      dispatch('app/displayNotice', data.message || 'Cart cleared.', {
        root: true,
      });
      commit('resetCart');
    } catch (error) {
      const {message} = normalizeError(error);
      dispatch('app/displayError', message, {root: true});
    }
  },
  getPendingCart({commit, dispatch}, pendingOrderNumber) {
    return new Promise((resolve, reject) => {
      api.getCart(pendingOrderNumber).then((response) => {
        // Update the cart
        commit('updateCart', {
          cartResponse: response.data,
        });

        // Request missing plugins
        dispatch('requestMissingPlugins')
          .then(() => {
            resolve();
          })
          .catch((error) => {
            reject('Couldn’t get missing plugins');
            throw error;
          });
      });
    });
  },

  /**
   * Get cart.
   *
   * @param dispatch
   * @param commit
   * @param orderNumber
   * @param state
   * @param orgId
   * @returns {Promise<unknown>}
   */
  getCart(
    {dispatch, commit, rootState},
    {orderNumber, orgId} = {orderNumber: null, orgId: null}
  ) {
    return new Promise((resolve, reject) => {
      if (!orderNumber) {
        if (rootState.account.user) {
          // Retrieve the order number from the user’s carts.
          orderNumber = rootState.account.user.carts.find(
            (cart) => cart.orgId === orgId
          )?.number;
        } else {
          // Retrieve the order number from local storage if not logged in.
          orderNumber = api.getLocalStorageOrderNumber();
        }
      }

      // Create a new cart if we don't have an order number
      if (!orderNumber) {
        reject('Couldn’t get cart: No order number');
      }

      // We have an order number, retrieve the cart.
      api
        .getCart(orderNumber)
        .then((response) => {
          if (response.error) {
            // Couldn’t get cart for this order number
            reject('Couldn’t get cart: ' + response.error);
          }

          // Update the cart
          commit('updateCart', {
            cartResponse: response.data,
          });

          // Request missing plugins
          dispatch('requestMissingPlugins')
            .then(() => {
              resolve();
            })
            .catch((error) => {
              reject('Couldn’t get missing plugins');
              throw error;
            });
        })
        .catch((error) => {
          const errorMsg =
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
              ? error.response.data.message
              : error && error.message
              ? error.message
              : 'Couldn’t get cart';

          reject(errorMsg);
        });
    });
  },

  /**
   * Create a new cart.
   *
   * @param commit
   * @param dispatch
   * @param rootState
   * @param orgId
   */
  async createCart({commit, rootState, dispatch}, orgId) {
    const data = {
      orgId,
    };

    if (rootState.account.user) {
      data.email = rootState.account.user.email;
    }

    try {
      const createCartResponse = await api.createCart(data);
      commit('updateCart', {orgId, cartResponse: createCartResponse.data});

      if (!rootState.account.user) {
        api.setLocalStorageOrderNumber(createCartResponse.data.cart.number);
      }
    } catch (error) {
      dispatch('app/displayError', 'Couldn’t create cart.', {root: true});
    }
  },

  /**
   * Request missing plugins.
   *
   * @param state
   * @param dispatch
   * @param orgId
   * @returns {Promise<unknown>}
   */
  requestMissingPlugins({dispatch, state}) {
    return new Promise((resolve, reject) => {
      // request plugins we don’t have yet
      if (!state.cart) {
        return resolve();
      }

      const cart = state.cart;
      const pluginIds = [];

      cart.lineItems.forEach((lineItem) => {
        if (lineItem.purchasable.plugin) {
          if (pluginIds.indexOf(lineItem.purchasable.plugin.id) < 0) {
            pluginIds.push(lineItem.purchasable.plugin.id);
          }
        }
      });

      if (pluginIds.length === 0) {
        return resolve();
      }

      dispatch('pluginStore/getPlugins', pluginIds, {root: true})
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject('Couldn’t get cart');
        });
    });
  },

  /**
   * Save cart.
   * @param commit
   * @param state
   * @param orgId
   * @param data
   * @returns {Promise<unknown>}
   */
  saveCart({commit}, {orgId, data}) {
    return new Promise((resolve, reject) => {
      const cart = state.cart;

      api
        .updateCart(cart.number, data)
        .then((response) => {
          commit('updateCart', {orgId, cartResponse: response.data});
          resolve(response);
        })
        .catch((response) => {
          reject(response);
        });
    });
  },

  /**
   * Reset cart.
   *
   * @param commit
   * @param dispatch
   * @param rootState
   * @returns {Promise<unknown>}
   */
  resetCart({commit, dispatch, rootState}) {
    return new Promise((resolve, reject) => {
      // Reset the cart’s state
      commit('resetCart');

      // Reset the local storage order number if we’re dealing with a guest
      if (!rootState.account.user) {
        api.deleteLocalStorageOrderNumber();
      }

      // Get the account to refresh `user.carts` cart snippets
      dispatch('account/getAccount', null, {root: true})
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   * Checkout.
   *
   * @param data
   * @returns {Promise<unknown>}
   */
  // eslint-disable-next-line
  checkout({}, data) {
    return new Promise((resolve, reject) => {
      api
        .checkout(data)
        .then((response) => {
          resolve(response);
        })
        .catch((response) => {
          reject(response);
        });
    });
  },

  /**
   * Set the order number.
   *
   * @param rootState
   * @param orgId
   * @param orderNumber
   */
  setOrderNumber({rootState}, {orgId, orderNumber}) {
    return new Promise((resolve, reject) => {
      if (rootState.account.user) {
        // Logged-in user
        api
          .setOrderNumber({orgId, orderNumber})
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        // Guest
        api.setLocalStorageOrderNumber(orderNumber);
        resolve();
      }
    });
  },

  /**
   * Add to cart.
   *
   * @param commit
   * @param state
   * @param dispatch
   * @param rootState
   * @param orgId
   * @param newItems
   * @returns {Promise<unknown>}
   */
  addToCart({commit, dispatch, state, rootState}, {orgId, newItems}) {
    return new Promise((resolve, reject) => {
      // Retrieve the cart
      const addItemsToCart = () => {
        const cart = state.cart;
        const items = getCartItemsData(cart);

        newItems.forEach((newItem) => {
          const item = {...newItem};

          if (!item.expiryDate) {
            item.expiryDate = '1y';
          }

          // Is item already in cart? Remove it before adding it again.
          items.forEach((cartItem, cartItemKey) => {
            if (
              (cartItem.licenseKey &&
                cartItem.licenseKey === item.licenseKey) ||
              (item.type === 'plugin-edition' &&
                cartItem.type === 'plugin-edition' &&
                cartItem.plugin === item.plugin) ||
              (item.type === 'cms-edition' &&
                cartItem.type === 'cms-edition' &&
                cartItem.edition === item.edition)
            ) {
              items.splice(cartItemKey, 1);
            }
          });

          items.push(item);
        });

        const data = {
          orgId,
          items,
        };

        api
          .updateCart(cart.number, data)
          .then((updateCartResponse) => {
            commit('updateCart', {
              orgId,
              cartResponse: updateCartResponse.data,
            });

            dispatch('requestMissingPlugins', orgId)
              .then(() => {
                if (rootState.account.user) {
                  dispatch('account/getAccount', null, {root: true})
                    .then(() => {
                      resolve(updateCartResponse);
                    })
                    .catch(() => {
                      reject('Couldn’t get account');
                    });
                } else {
                  resolve(updateCartResponse);
                }
              })
              .catch(() => {
                reject('Couldn’t get missing plugins');
              });
          })
          .catch((error) => {
            if (error?.response?.data?.error) {
              reject(error.response.data.error);
              return;
            }

            if (error?.message) {
              reject(error.message);
              return;
            }

            reject('Couldn’t update cart.');
          });
      };

      // Check that we have an order number to work with
      let orderNumber = null;

      if (rootState.account.user) {
        // Retrieve the order number from the user’s carts.
        orderNumber = rootState.account.user.carts.find(
          (cart) => cart.orgId === orgId
        )?.number;
      } else {
        // Retrieve the order number from local storage if not logged in.
        orderNumber = api.getLocalStorageOrderNumber();
      }

      // Otherwise create a new cart
      if (!orderNumber) {
        dispatch('createCart', orgId)
          .then(() => {
            if (rootState.account.user) {
              // Get the account to refresh cart snippets
              dispatch('account/getAccount', null, {root: true}).then(() => {
                // Retrieve the cart
                dispatch('getCart', {orgId})
                  .then(addItemsToCart)
                  .catch((error) => {
                    reject(error);
                  });
              });
            } else {
              // Retrieve the cart
              dispatch('getCart', {orgId})
                .then(addItemsToCart)
                .catch((error) => {
                  reject(error);
                });
            }
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        // Retrieve the cart
        dispatch('getCart', {orgId})
          .then(addItemsToCart)
          .catch((error) => {
            reject(error);
          });
      }
    });
  },

  /**
   * Remove from cart.
   *
   * @param commit
   * @param dispatch
   * @param getters
   * @param orgId
   * @param lineItemKey
   * @returns {Promise<unknown>}
   */
  removeFromCart({commit, dispatch, state}, {orgId, lineItemKey}) {
    return new Promise((resolve, reject) => {
      dispatch('getCart', {orgId})
        .then(() => {
          const cart = state.cart;

          const items = getCartItemsData(cart);
          items.splice(lineItemKey, 1);

          const data = {
            items,
          };

          api
            .updateCart(cart.number, data)
            .then((response) => {
              commit('updateCart', {
                orgId,
                cartResponse: response.data,
              });
              resolve(response);
            })
            .catch((response) => {
              reject(response);
            });
        })
        .catch(reject);
    });
  },

  /**
   * Update item.
   *
   * @param commit
   * @param state
   * @param orgId
   * @param itemKey
   * @param item
   * @returns {Promise<unknown>}
   */
  async updateItem({commit, dispatch}, {orgId, itemKey, item}) {
    const cart = state.cart;
    const items = getCartItemsData(cart);

    items[itemKey] = item;

    try {
      commit('updateLoadingItem', {itemKey, value: true});
      const {data} = await api.updateCart(cart.number, {
        items,
      });
      commit('updateCart', {orgId, cartResponse: data});
    } catch (error) {
      // Show the user an error
      dispatch('app/displayError', 'Could not update cart.', {root: true});

      // Reset the cart to the original value
      commit('updateCart', {
        orgId,
        cartResponse: {
          cart,
        },
      });
    } finally {
      // Always stop loading
      commit('deleteLoadingItem', {itemKey});
    }
  },
};

/**
 * Mutations
 */
const mutations = {
  updateCart(state, {cartResponse}) {
    state.stripePublicKey = cartResponse.stripePublicKey;

    const cart = cartResponse.cart;

    const selectedExpiryDates = {};
    const itemsAutoRenew = {};

    cart.lineItems.forEach((lineItem) => {
      selectedExpiryDates[lineItem.id] = lineItem.options.expiryDate;
      itemsAutoRenew[lineItem.id] = lineItem.options.autoRenew;
    });

    state.cart = cart;
    state.selectedExpiryDates = selectedExpiryDates;
    state.itemsAutoRenew = itemsAutoRenew;
  },

  resetCart(state) {
    state.cart = null;
  },

  updateSelectedExpiryDates(state, {selectedExpiryDates}) {
    state.selectedExpiryDates = selectedExpiryDates;
  },

  updateItemsAutoRenew(state, {itemsAutoRenew}) {
    state.itemsAutoRenew = itemsAutoRenew;
  },

  updateLoadingItem(state, {itemKey, value}) {
    state.loadingItems[itemKey] = value;
  },

  updateLoadingItems(state, {loadingItems}) {
    state.loadingItems = loadingItems;
  },

  deleteLoadingItem(state, {itemKey}) {
    delete state.loadingItems[itemKey];
  },

  updateGuestOrderNumber(state, orderNumber) {
    state.guestOrderNumber = orderNumber;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
