import axios from './axios';
import FormDataHelper from '@console/helpers/form-data.js';
import qs from 'qs';

export default {
  claimCmsLicense({orgId, key}) {
    return axios().post(
      Craft.actionUrl + '/craftnet/console/cms-licenses/claim',
      {
        orgId,
        key,
      }
    );
  },

  claimCmsLicenseFile({orgId, licenseFile}) {
    const formData = new FormData();

    FormDataHelper.append(formData, 'licenseFile', licenseFile);

    if (orgId) {
      FormDataHelper.append(formData, 'orgId', orgId);
    }

    return axios().post(
      Craft.actionUrl + '/craftnet/console/cms-licenses/claim',
      formData
    );
  },

  getCmsLicense(id) {
    return axios().get(
      Craft.actionUrl + '/craftnet/console/cms-licenses/get-license-by-id',
      {params: {id}}
    );
  },

  getCmsLicenses(orgId) {
    return axios().get(
      Craft.actionUrl + '/craftnet/console/cms-licenses/get-licenses',
      {params: {orgId}}
    );
  },

  getExpiringCmsLicensesTotal() {
    return axios().get(
      Craft.actionUrl +
        '/craftnet/console/cms-licenses/get-expiring-licenses-total'
    );
  },

  releaseCmsLicense(licenseKey) {
    const data = {
      key: licenseKey,
    };

    return axios().post(
      Craft.actionUrl + '/craftnet/console/cms-licenses/release',
      qs.stringify(data)
    );
  },

  saveCmsLicense(license) {
    const data = license;

    return axios().post(
      Craft.actionUrl + '/craftnet/console/cms-licenses/save',
      qs.stringify(data)
    );
  },

  transferCmsLicense({id, newOwnerId, includePlugins}) {
    return axios().post(
      Craft.actionUrl + '/craftnet/console/cms-licenses/transfer',
      {
        id,
        newOwnerId,
        includePlugins,
      }
    );
  },
};
