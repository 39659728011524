import {createStore} from 'vuex';
import account from './modules/account';
import addresses from './modules/addresses';
import app from './modules/app';
import apps from './modules/apps';
import cart from './modules/cart';
import cloud from './modules/cloud.ts';
import cmsLicenses from './modules/licenses/cms-licenses';
import context from '@console/store/modules/context';
import developerSupport from './modules/developer-support';
import integrations from './modules/integrations';
import orders from './modules/orders';
import orgs from './modules/orgs';
import paymentMethods from './modules/payment-methods';
import pluginLicenses from './modules/licenses/plugin-licenses';
import pluginStore from './modules/plugin-store';
import plugins from './modules/plugin-store/plugins';
import stripe from './modules/stripe';

const store = createStore({
  strict: true,
  modules: {
    account,
    addresses,
    app,
    apps,
    cart,
    cloud,
    cmsLicenses,
    context,
    developerSupport,
    integrations,
    orders,
    orgs,
    paymentMethods,
    pluginLicenses,
    pluginStore,
    plugins,
    stripe,
  },
});

export default store;
