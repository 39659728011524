import {type Ref, ref, type UnwrapRef} from 'vue';

const isWarm = ref(false);
const warmupTimer = ref<number | null>(null);
const cooldownTimer = ref<number | null>(null);

interface UseWarmupOptions {
  warmupDuration?: number;
  cooldownDuration?: number;
}

type UseWarmup = (options?: UseWarmupOptions) => {
  withWarmup: (fn: () => any) => void;
  isWarm: Ref<UnwrapRef<boolean>>;
  resetTimeouts: () => void;
  clearWarmupTimeout: () => void;
  clearCooldownTimeout: () => void;
};

export const useWarmup: UseWarmup = ({
  warmupDuration = 500,
  cooldownDuration = 1000,
} = {}) => {
  function withWarmup(fn: () => any) {
    resetTimeouts();

    warmupTimer.value = window.setTimeout(
      () => {
        fn();
        isWarm.value = true;

        cooldownTimer.value = window.setTimeout(() => {
          isWarm.value = false;
        }, cooldownDuration);
      },
      isWarm.value ? 0 : warmupDuration
    );
  }

  function resetTimeouts() {
    clearWarmupTimeout();
    clearCooldownTimeout();
  }

  function clearWarmupTimeout() {
    if (warmupTimer.value) {
      window.clearTimeout(warmupTimer.value);
    }
  }

  function clearCooldownTimeout() {
    if (cooldownTimer.value) {
      window.clearTimeout(cooldownTimer.value);
    }
  }

  return {
    withWarmup,
    clearWarmupTimeout,
    clearCooldownTimeout,
    isWarm,
    resetTimeouts,
  };
};
