import axios from './axios';
import qs from 'qs';
import {getAppUrl} from '@console/helpers/url.js';

export default {
  savePaymentMethod(data) {
    const id = data.id;
    const isNew = !id;

    return axios().post(
      getAppUrl() + '/payment-methods' + (!isNew ? '/' + id : ''),
      qs.stringify(data)
    );
  },

  removePaymentMethod(paymentMethodId) {
    return axios().delete(getAppUrl() + '/payment-methods/' + paymentMethodId);
  },

  getPaymentMethodById(paymentMethodId) {
    return axios().get(getAppUrl() + '/payment-methods/' + paymentMethodId);
  },

  getPaymentMethods({orgId} = {orgId: null}) {
    return axios().get(getAppUrl() + '/payment-methods', {
      params: {
        orgId,
      },
    });
  },

  getPaymentMethodsCheckout() {
    return axios().get(getAppUrl() + '/payment-methods/checkout');
  },
};
