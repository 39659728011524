import {getAppUrl} from '@console/helpers/url.js';

import axios from './axios';

export default {
  getApps(orgId) {
    return axios().get(getAppUrl() + '/apps', {
      params: {
        orgId,
      },
    });
  },

  disconnect({orgId, appHandle}) {
    return axios().delete(getAppUrl() + '/apps/' + appHandle, {
      params: {orgId},
    });
  },
};
