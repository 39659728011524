<template>
  <div
    class="c-radio"
    :class="{
      'flex items-center': true,
    }"
  >
    <radio-input
      :id="id"
      :name="name"
      :value="value"
      :disabled="disabled"
      :model-value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
    />

    <label
      :for="id"
      class="mb-0 pl-2 ml-0 font-medium"
      :class="{'opacity-50': disabled}"
    >
      <span class="block text-sm leading-5 text-black dark:text-white">{{
        label
      }}</span>
    </label>
  </div>
</template>

<script>
  import RadioInput from '@/common/ui/components/RadioInput.vue';

  export default {
    components: {RadioInput},
    props: {
      disabled: {
        type: Boolean,
        default: false,
      },
      id: {
        type: String,
        default: function () {
          return 'radio-' + Math.random().toString(36).substr(2, 9);
        },
      },
      label: {
        type: String,
        default: '',
      },
      modelValue: {
        type: String,
        default: '',
      },
      value: {
        type: String,
        default: '',
      },
      name: {
        type: String,
        default: '',
      },
    },

    emits: ['update:modelValue'],
  };
</script>
