import {AnsiUp} from 'ansi_up/ansi_up';

const ansiUp = new AnsiUp();
ansiUp.use_classes = true;

export function capitalize(value) {
  if (!value) {
    return '';
  }
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
}

/**
 * Convert number to hex value (00 - ff)
 *
 * @param {number} dec
 * @returns {string}
 */
function dec2hex(dec) {
  return dec.toString(16).padStart(2, '0');
}

export function randomString(length = 32) {
  const array = new Uint8Array(length / 2);
  window.crypto.getRandomValues(array);
  return Array.from(array, dec2hex).join('');
}

/**
 * Find URLS in some text and link them
 * https://stackoverflow.com/a/1500501
 * @param text | null
 * @returns string
 */
export function urlify(text) {
  if (!text) {
    return '';
  }

  const urlRegex = /(https?:\/\/\S+)/g;
  return text.replace(
    urlRegex,
    (url) =>
      `<a class="text-current hover:text-current underline" rel="nofollow noopener" target="_blank" href="${url}">${url}</a>`
  );
}

export function convertAnsi(text) {
  return ansiUp.ansi_to_html(text);
}
