import {isAxiosError} from 'axios';

const normalizeError = (error: any) => {
  if (isAxiosError(error)) {
    // Error code outside 2XX
    if (error.response) {
      const {data = {}, status} = error.response;
      return {
        status,
        message: '',
        ...data,
      };
    }
  }

  return {
    status: null,
    message: error?.message || 'An error occurred',
  };
};

export default normalizeError;
