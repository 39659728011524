<script setup lang="ts"></script>

<template>
  <span class="relative flex h-2 w-2">
    <span
      class="animate-ping motion-reduce:animate-a11y-ping absolute inline-flex h-full w-full rounded-full bg-blue-700 dark:bg-white opacity-75"
    ></span>
    <span
      class="relative inline-flex rounded-full h-2 w-2 bg-blue-600/80 dark:bg-white/50"
    ></span>
  </span>
</template>

<style scoped></style>
